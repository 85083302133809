import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import moment from 'moment';

import { loadAll, save } from './actions';
import { openConfirmationModal } from '../../actions/UIActions';
import { dateColumn } from '../../services/DateService';
import DocumentsGrid from '../plans/documents/DocumentsGrid';
import TermsEditor from './TermsEditor';
import { Storages } from '../../services/LocalStorageService';
import Disclaimer from '../Disclaimer';
import ConfirmationModal from '../ConfirmationModal';

const isDraft = (latest, current) => {
  if (latest && current) {
    if (latest.activeDate && current.activeDate) {
      const currentActive = moment.utc(current.activeDate, 'YYYY-MM-DD');
      const latestActive = moment.utc(latest.activeDate, 'YYYY-MM-DD');
      if (currentActive.isSameOrBefore(latestActive)) {
        return false;
      }
    }
  }
  return true;
};

const StatusRenderer = ({ data, context }) => {
  const onClick = () => context.editTerms(data);
  const onDelete = () => context.deleteTerms(data);
  let icon = 'edit';
  let title = 'Edit';
  let canDelete = false;
  const latest = context.getLatest();
  if (latest) {
    if (data.id === latest.id) {
      icon = 'lock';
      title = 'Active';
    } else if (!isDraft(latest, data)) {
      icon = 'archive';
      title = 'Archieved';
    }

    if (isDraft(latest, data)) {
      canDelete = true;
    }
  }
  return (
    <div>
      <span className="fa-lg" onClick={onClick}>
        <i className={`fa fa-${icon}`} title={title}>
          &nbsp;
        </i>
      </span>
      {canDelete ? (
        <span className="fa-lg" onClick={onDelete}>
          <i className="fas fa-trash-alt" title="Delete">
            &nbsp;
          </i>
        </span>
      ) : null}
    </div>
  );
};
StatusRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export class TermsAdmin extends Component {
  static columnDefs = [
    {
      field: 'activeDate',
      headerName: 'Activated',
      ...dateColumn,
      sort: 'desc',
      suppressSizeToFit: false,
    },
    {
      field: 'createdOn',
      headerName: 'Created',
      ...dateColumn,
      suppressSizeToFit: false,
    },
    {
      field: 'id',
      headerName: 'Status',
      cellRenderer: StatusRenderer,
      suppressSizeToFit: false,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      editing: undefined,
      dataToDelete: undefined,
    };
  }

  getLatest = () => {
    return this.props.latest;
  };

  componentDidMount() {
    this.props.loadAll();
  }

  onNewTerms = () => {
    this.editTerms({ id: null, terms: '', activeDate: undefined });
  };

  editTerms = editing => {
    this.setState({ editing });
  };

  deleteTerms = data => {
    this.props.openConfirmationModal(true);
    this.setState({
      dataToDelete: { ...data, active: false },
    });
  };

  onDeleteConfirm = () => {
    this.props.save(this.state.dataToDelete);
    this.props.openConfirmationModal(false);
  };

  renderContent = () => {
    if (this.state.editing) {
      const draft = isDraft(this.props.latest, this.state.editing);
      const latest = this.props.latest;
      return (
        <TermsEditor
          terms={this.state.editing}
          save={this.props.save}
          edit={this.editTerms}
          draft={draft}
          latest={latest}
        />
      );
    }
    const context = {
      editTerms: this.editTerms,
      getLatest: this.getLatest,
      deleteTerms: this.deleteTerms,
    };
    return (
      <div>
        <div className="messagesGrid mt-3 mb-3 ag-theme-balham">
          <DocumentsGrid
            context={context}
            columnDefs={TermsAdmin.columnDefs}
            rowData={this.props.all.data}
            viewParams={this.props.viewParams}
            noRowsMessage="No rows"
            hasLoaded={this.props.all.isLoaded}
          />
        </div>
        <Button color="secondary" className="ml-1" onClick={this.onNewTerms}>
          New terms
        </Button>
        <ConfirmationModal
          title="Terms and Conditions"
          message="Would you like to delete this Term?"
          confirm={this.onDeleteConfirm}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="admin">
        <Disclaimer name={Storages.SHOW_TERMS_DISCLAIMER_SETTING}>
          <p>
            The current Terms and Conditions is defined as the newest version
            where the active date is before today.
          </p>
          <p>All users will be required to accept new Terms and Conditions.</p>
        </Disclaimer>
        {this.renderContent()}
      </div>
    );
  }
}

TermsAdmin.propTypes = {
  loadAll: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,

  all: PropTypes.object.isRequired,
  latest: PropTypes.object,
  openConfirmationModal: PropTypes.func,
  viewParams: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    all: state.terms.all || {},
    latest: state.terms.latest.data,
  };
};

export default connect(mapStateToProps, {
  loadAll,
  save,
  openConfirmationModal,
})(TermsAdmin);
