import axios from 'axios';

import Constants from './Constants';

const DenodoService = () => {
  const DENODO_URL = Constants.DENODO.URL;

  const getSkyanURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://skyan-qa.nepc.com/denodo/api/v1';
      case 'QA':
        return 'https://skyan-qa.nepc.com/denodo/api/v1';
      case 'UAT':
        return 'https://skyan-uat.nepc.com/denodo/api/v1';
      case 'PROD':
        return 'https://skyan.nepc.com/denodo/api/v1';
      default:
        return 'https://skyan.nepc.com/denodo/api/v1';
    }
  };

  const authorizationUrl = getSkyanURL();

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getClientPlans = (accessToken, email) =>
    axios.get(
      `${DENODO_URL}/planListView?planType=clientplan&email=${email}`,
      _headers(accessToken)
    );

  const getEmployeePlans = (accessToken, email) =>
    axios.get(
      `${DENODO_URL}/planListView?planType=employeeplan&email=${email}`,
      _headers(accessToken)
    );

  const getEmployeeLetterPlans = (accessToken, filter) =>
    axios.get(
      `${DENODO_URL}/planListView?planType=employeeallplan&letter=${filter}`,
      _headers(accessToken)
    );

  const getPlanTeam = (accessToken, planId) =>
    axios.get(
      `${DENODO_URL}/planTeamView?planId=${planId}`,
      _headers(accessToken)
    );

  const getPlanUserList = (accessToken, planId) =>
    axios.get(
      `${DENODO_URL}/userListView?planId=${planId}`,
      _headers(accessToken)
    );

  const getPlanMemberPicture = (accessToken, email) =>
    axios.get(`${DENODO_URL}/imageView?email=${email}`, _headers(accessToken));

  const getPlanMeetingNotes = (
    accessToken,
    planId,
    offset = 0,
    limit = 100000
  ) =>
    axios.get(
      `${DENODO_URL}/meetingNotesListView?planId=${planId}&offset=${offset}&limit=${limit}`,
      _headers(accessToken)
    );

  const getManagerActivities = (
    accessToken,
    planId,
    offset = 0,
    limit = 100000
  ) =>
    axios.get(
      `${DENODO_URL}/managerActivitiesListView?planId=${planId}&offset=${offset}&limit=${limit}`,
      _headers(accessToken)
    );

  const getMeetingNotes = (accessToken, writeUpId, writeUpStatus) =>
    axios.get(
      `${DENODO_URL}/meetingNotesView?writeUpId=${writeUpId}&writeUpStatus=${writeUpStatus}`,
      _headers(accessToken)
    );

  const getContactDetails = accessToken =>
    axios.get(`${DENODO_URL}/contactView`, _headers(accessToken));

  const authorize = email =>
    axios.get(`${DENODO_URL}/authorizeView?email=${email}`);

  const getFocusedPlacementLists = accessToken =>
    axios.get(`${DENODO_URL}/fpl/lists`, _headers(accessToken));

  const getStrategyList = (accessToken, fplListId) =>
    axios.get(
      `${DENODO_URL}/fpl/listMembers?fplListId=${fplListId}`,
      _headers(accessToken)
    );

  const getFPLNepc = (accessToken, strategyId) =>
    axios.get(
      `${DENODO_URL}/fpl/nepcNarrative?strategyId=${strategyId}`,
      _headers(accessToken)
    );

  const getFPLResearch = (accessToken, strategyId) =>
    axios.get(
      `${DENODO_URL}/fpl/meetingNotes?strategyId=${strategyId}`,
      _headers(accessToken)
    );

  const getFPLManagerActivities = (accessToken, strategyId) =>
    axios.get(
      `${DENODO_URL}/fpl/managerActivities?strategyId=${strategyId}`,
      _headers(accessToken)
    );

  const getFPLEvest = (accessToken, strategyId) =>
    axios.get(
      `${DENODO_URL}/fpl/eVestNarratives?strategyId=${strategyId}`,
      _headers(accessToken)
    );

  const getPlanDDEvents = (accessToken, planId) =>
    axios.get(`${DENODO_URL}/dds?planId=${planId}`, _headers(accessToken));

  const getDDEvent = (accessToken, uniqueId) =>
    axios.get(`${DENODO_URL}/dd?uniqueId=${uniqueId}`, _headers(accessToken));

  const getSkyanAuthorization = (accessToken, email) => {
    return axios.get(
      `${authorizationUrl}/authorizeView?email=${email}`,
      _headers(accessToken)
    );
  };

  return {
    getClientPlans,
    getEmployeePlans,
    getEmployeeLetterPlans,
    getPlanTeam,
    getPlanUserList,
    getPlanMemberPicture,
    getManagerActivities,
    getPlanMeetingNotes,
    getMeetingNotes,
    getContactDetails,
    authorize,
    getFocusedPlacementLists,
    getStrategyList,
    getFPLNepc,
    getFPLResearch,
    getFPLManagerActivities,
    getFPLEvest,
    getPlanDDEvents,
    getDDEvent,
    getSkyanAuthorization,
  };
};

export default DenodoService;
