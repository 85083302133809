import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Badge,
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import _ from 'lodash';

import { showLatestSystemMessages } from './messages/actions';
import Constants from '../services/Constants';
import { slug, slugInitialLetter } from '../services/SlugService';
import viewParams from './router/ViewParams';

export class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      authenticated: undefined,
    };
  }

  toggle = () => {
    this.setState(() => ({
      isOpen: !this.state.isOpen,
    }));
  };

  showMessages = e => {
    const rect = e.target.getBoundingClientRect();
    this.props.showLatestSystemMessages(rect.left, rect.bottom);
  };

  calculateCSSTabClass = title => {
    const titleLengh = title.length;
    if (titleLengh > 60) {
      return 'xxlTab';
    } else if (titleLengh > 32) {
      return 'xlTab';
    } else if (titleLengh > 23) {
      return 'lgTab';
    } else if (titleLengh > 16) {
      return 'md2Tab';
    } else if (titleLengh > 13) {
      return 'md1Tab';
    } else if (titleLengh > 9) {
      return 'smTab';
    } else {
      return 'xsTab';
    }
  };

  renderMenuItem = (title, to, isActive = null) => {
    return (
      <NavItem
        key={title + to}
        className={this.calculateCSSTabClass(title)}
        id={`MainMenuLink_${title}`}>
        <RouterNavLink to={to} isActive={isActive}>
          {title}
        </RouterNavLink>
      </NavItem>
    );
  };

  renderLeftMenu = () => {
    const items = [];

    if (this.props.showPlans) {
      items.push(this.renderMenuItem('Portfolios', '/portfolios', null));
      if (this.props.skyanAccess || this.props.isEmployee) {
        items.push(this.renderMenuItem('SKYAN', '/skyan', null));
      }
      if (this.props.isEmployee === true) {
        items.push(
          this.renderMenuItem(
            'Consulting Resources',
            '/consultingResources',
            null
          )
        );
      }
    }

    // BLOG
    items.push(
      <NavItem className="xsTab" id="main-menu-link-blog">
        <NavLink
          target="_blank"
          href="https://www.nepc.com/institutional/insights">
          {Constants.BLOG}
        </NavLink>
      </NavItem>
    );

    const {
      viewParams: { plan },
    } = this.props;
    if (plan) {
      const { planName, clientName, accessMyManager } = plan;
      const planNameSlug = slug(planName);
      const letterSlug = slugInitialLetter(clientName);
      const page = accessMyManager ? 'manager' : 'client';
      items.push(
        this.renderMenuItem(
          plan.planName,
          `/portfolio/${letterSlug}/${planNameSlug}/${page}`,
          () => true
        )
      );
    }

    return (
      <Navbar light className="leftMenu">
        <Nav>{items}</Nav>
      </Navbar>
    );
  };

  renderRightMenu = () => {
    const { documentsView } = this.props.viewParams;
    const messagesActive = documentsView === Constants.HUB_SETTINGS_VIEW;
    const myProfile = this.props.isClient
      ? this.renderMenuItem('My Profile', '/profile')
      : null;
    const { seenMessages, latest: allMessages } = this.props;
    const unreadAnnouncements = _.filter(
      allMessages,
      message => !_.includes(seenMessages, message.id)
    ).length;
    const unreadBadge =
      unreadAnnouncements > 0 ? (
        <sup>
          <Badge color="danger" className="announcementsBadge">
            {unreadAnnouncements}
          </Badge>
        </sup>
      ) : null;
    return (
      <Navbar light expand="lg">
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar>
            {myProfile}
            <NavItem className="md1Tab" id={`MainMenuLink_Announcements`}>
              <NavLink onClick={this.showMessages} active={messagesActive}>
                Announcements {unreadBadge}
              </NavLink>
            </NavItem>
            <NavItem className="xsTab" id="sign-out-button">
              <RouterNavLink to="/logout">Sign Out</RouterNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  };

  renderUserMenu = () => {
    return (
      <Fragment>
        {this.renderLeftMenu()}
        {this.renderRightMenu()}
      </Fragment>
    );
  };

  renderLoginMenu = () => {
    return (
      <Nav navbar>
        <Navbar light>
          <NavItem>
            <NavLink href="/">Login</NavLink>
          </NavItem>
        </Navbar>
      </Nav>
    );
  };

  render() {
    if (this.state.authenticated === undefined) {
      setTimeout(async () => {
        const authenticated = await this.props.auth.isAuthenticated();
        this.setState({ authenticated });
      });
      return null;
    }
    const menu = this.state.authenticated
      ? this.renderUserMenu
      : this.renderLoginMenu;
    return <div className="mainMenu">{menu()}</div>;
  }
}

MainMenu.propTypes = {
  isClient: PropTypes.bool,
  isEmployee: PropTypes.bool,
  showLatestSystemMessages: PropTypes.func.isRequired,
  selectedView: PropTypes.string,
  showPlans: PropTypes.bool.isRequired,
  user: PropTypes.object,
  auth: PropTypes.object,
  seenMessages: PropTypes.array.isRequired,
  latest: PropTypes.array.isRequired,
  skyanAccess: PropTypes.bool,
  viewParams,
};

const checkShowPlans = state => {
  const {
    resources: {
      plans: { data: plans },
    },
  } = state;
  if (plans && plans.length === 1 && plans[0].accessEnabled) {
    return false;
  }
  return true;
};

const mapStateToProps = state => {
  return {
    showPlans: checkShowPlans(state),
    isClient: state.okta.isClient,
    user: state.okta.user,
    latest: state.messages.latest || [],
    seenMessages: state.messages.seenMessages || [],
    isEmployee: state.okta.isEmployee,
    skyanAccess: state.resources.skyan_access.data || false,
  };
};

export default connect(mapStateToProps, { showLatestSystemMessages })(
  withAuth(MainMenu)
);
