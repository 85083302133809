import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import { openDDEvent } from '../../../actions';
import { slug } from '../../../services/SlugService';

export const OpenDDEventLink = props => {
  const onClick = () => props.openDDEvent(props.data);
  return (
    <Button
      color="link"
      className="gridLink"
      onClick={onClick}
      id={`${slug(props.value)}-${props.rowIndex}`}>
      {props.value}
    </Button>
  );
};

OpenDDEventLink.propTypes = {
  openDDEvent: PropTypes.func.isRequired,
  value: PropTypes.string,
  data: PropTypes.object,
  rowIndex: PropTypes.number,
};

export default connect(null, { openDDEvent })(OpenDDEventLink);
