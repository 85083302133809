import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';

import MessagesEditor from './MessagesEditor';
import MessagesListAdmin from './MessagesListAdmin';
import * as types from './types';

export class AnnouncementsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: undefined,
    };
  }
  editAnnouncement = editing => {
    this.setState({ editing });
  };

  onNewAnnouncement = () => {
    this.editAnnouncement({ id: types.MESSAGES_NEW });
  };

  render() {
    if (!this.state.editing) {
      return (
        <Row className="admin">
          <Col md={12}>
            <MessagesListAdmin
              loadAll={this.props.loadAll}
              allLoaded={this.props.allLoaded}
              all={this.props.all}
              editSystemMessage={this.editAnnouncement}
              viewParams={this.props.viewParams}
            />
          </Col>
          <Col md={10}>
            <Button onClick={this.onNewAnnouncement}>New Announcement</Button>
          </Col>
        </Row>
      );
    }
    return (
      <MessagesEditor
        message={this.state.editing}
        editSystemMessage={this.editAnnouncement}
        saveSystemMessage={this.props.saveSystemMessage}
        showSystemMessage={this.props.showSystemMessage}
      />
    );
  }
}
AnnouncementsAdmin.propTypes = {
  saveSystemMessage: PropTypes.func.isRequired,
  showSystemMessage: PropTypes.func.isRequired,
  loadAll: PropTypes.func.isRequired,
  all: PropTypes.array.isRequired,
  allLoaded: PropTypes.bool.isRequired,
  viewParams: PropTypes.object,
};

export default AnnouncementsAdmin;
