import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { slug } from '../../../services/SlugService';
import Constants from '../../../services/Constants';

export const OpenManagerSummary = props => {
  const calculateConsultingResourcesUrl = selectedinvestmentFirm => {
    if (props.employeeActiveTab === Constants.CR_MEETING_NOTES_VIEW) {
      return `/consultingResources/meetingnotes/${slug(
        selectedinvestmentFirm
      )}/investmentFirm`;
    }
    return `/consultingResources/dds/${slug(
      selectedinvestmentFirm
    )}/investmentFirm`;
  };

  const managerName =
    _.get(props, 'data.firmName') ||
    _.get(props, 'data.investmentFirmName') ||
    _.get(props, 'data.firm') ||
    '';
  const {
    context: { viewParams },
    selectedinvestmentFirm,
  } = props;
  const baseUrl = viewParams.fplListUrl || viewParams.planUrl;
  const managerUrl = selectedinvestmentFirm
    ? calculateConsultingResourcesUrl(selectedinvestmentFirm)
    : `${baseUrl}/manager/${slug(managerName)}/strategies`;
  if (managerName === Constants.INTERNALLY_MANAGED) {
    return <span>{props.value}</span>;
  }
  return (
    <span>
      <Link
        className="gridLink"
        push="true"
        to={managerUrl}
        id={`${props.value}-${props.rowIndex}`}>
        {props.value}
      </Link>
      <span className="printItem">{props.value}</span>
    </span>
  );
};

OpenManagerSummary.propTypes = {
  calculateConsultingResourcesUrl: PropTypes.func,
  selectedPlan: PropTypes.object,
  value: PropTypes.string,
  selectedinvestmentFirm: PropTypes.string,
  data: PropTypes.object,
  context: PropTypes.object,
  employeeActiveTab: PropTypes.string,
  rowIndex: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.ui.selectedPlan,
    selectedinvestmentFirm: state.ui.selectedInvestmentFirm,
    employeeActiveTab: state.ui.employeeActiveTab,
  };
};

export default connect(mapStateToProps, null)(OpenManagerSummary);
