import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const ProfileNotifications = props => {
  const { editing, onUpdate, notifications, planNotifications } = props;
  const items = _.map(planNotifications, notification => {
    const onChange = event => {
      const {
        target: { checked: sendNotifications },
      } = event;
      const newNotifications = {
        ...notifications,
        [notification.planId]: {
          ...notification,
          sendNotifications,
        },
      };
      onUpdate(newNotifications);
    };
    const editedValue =
      notifications[notification.planId] &&
      notifications[notification.planId].sendNotifications;
    const checked =
      editedValue !== undefined ? editedValue : notification.sendNotifications;
    return (
      <Col key={notification.planId} md={6} sm={12}>
        <Label for={notification.planId}>
          <input
            type="checkbox"
            id={notification.planId}
            checked={checked}
            onChange={onChange}
            disabled={!editing}
          />
          {' ' + notification.planName}
        </Label>
      </Col>
    );
  });
  const title =
    items.length > 0 ? (
      <Col sm={12} md={12}>
        <h5>Document Notification Options</h5>
        <p>
          Use the check boxes below to indicate the portfolios for which you
          wish to receive notifications when new documents are published.
        </p>
      </Col>
    ) : null;
  return (
    <Row>
      {title}
      {items}
    </Row>
  );
};

ProfileNotifications.propTypes = {
  editing: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  notifications: PropTypes.object,
  planNotifications: PropTypes.array,
};

export default ProfileNotifications;
