import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Constants from '../../services/Constants';

class SkyanRouter extends Component {
  onSkyan = () => {
    return this.props.withMain(Constants.SKYAN);
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} render={() => this.onSkyan()} />
      </Switch>
    );
  }
}

SkyanRouter.propTypes = {
  match: PropTypes.object.isRequired,
  withMain: PropTypes.func,
};

export default SkyanRouter;
