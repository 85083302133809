import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BaseHeader from './BaseHeader';

export const FPLListHeader = props => {
  const headers = [
    { title: 'List Name', field: 'listName' },
    { title: 'Capitalization', field: 'capitalization' },
    { title: 'Asset Class', field: 'assetClass' },
    { title: 'Asset Group', field: 'betaGroup' },
  ];
  const listName = _.get(props, 'fplList.listName', '') || '';
  const title = listName + ' Strategies';
  return <BaseHeader headers={headers} data={props.fplList} title={title} />;
};

FPLListHeader.propTypes = {
  fplList: PropTypes.object,
};

export default FPLListHeader;
