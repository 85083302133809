import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';

import OktaSignInWidget from './oktaSignInWidget/OktaSignInWidget';
import {
  Storages,
  getFromLocalStorage,
  addToLocalStorage,
  removeFromLocalStorage,
} from '../../services/LocalStorageService';
import AuthContainer from './AuthContainer';
import { logoutClearRedirects } from '../../actions';

const LOGIN_REDIRECT_EXPIRY = 10 * 60 * 1000;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
    };
    this.checkAuthentication();
  }

  async _checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  checkAuthentication() {
    try {
      this._checkAuthentication();
    } catch (e) {
      // not authenticated
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  onSuccess = res => {
    if (res.status === 'SUCCESS' && res.type === 'SESSION_SSO') {
      return this.props.auth.redirect({
        sessionToken: res.session.token,
      });
    }
  };

  onError = _err => {};

  renderLogin = (disabled = false) => {
    return (
      <div>
        <AuthContainer>
          <OktaSignInWidget
            onSuccess={this.onSuccess}
            onError={this.onError}
            baseUrl={this.props.baseUrl}
            disabled={disabled}
          />
        </AuthContainer>
      </div>
    );
  };

  preLoginRedirect = () => {
    const { search } = window.location;
    if (search.startsWith('?to=')) {
      const pathname = decodeURIComponent(search.substring(4));
      const time = new Date().toISOString();
      addToLocalStorage(Storages.LOGIN_REDIRECT_URL, pathname);
      addToLocalStorage(Storages.LOGIN_REDIRECT_TIME, time);
      removeFromLocalStorage(Storages.OKTA_LOGIN_REDIRECT);
    }
  };

  postLoginRedirect = () => {
    if (this.props.maintenanceMode) {
      return <Redirect to={{ pathname: '/settings' }} />;
    }
    const time = getFromLocalStorage(Storages.LOGIN_REDIRECT_TIME);
    if (time) {
      const secondsSinceTime = new Date().getTime() - new Date(time).getTime();
      if (secondsSinceTime > LOGIN_REDIRECT_EXPIRY) {
        return <Redirect to={{ pathname: '/portfolios' }} />;
      }
    }

    const pathname =
      getFromLocalStorage(Storages.LOGIN_REDIRECT_URL) || '/portfolios';
    logoutClearRedirects();
    return <Redirect to={{ pathname }} />;
  };

  renderMaintenance = () => {
    return (
      <div className="underMaintenance">
        <div className="loginArea">{this.renderLogin(true)}</div>
        <div className="reasonArea">
          <img
            className="reasonImage"
            src={this.props.maintenanceMode}
            alt="Under maintenance"
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    if (this.props.isMaintenanceLogin || !this.props.maintenanceMode) {
      return this.renderLogin();
    }
    return this.renderMaintenance();
  };

  render() {
    if (this.state.authenticated === null) {
      return null;
    }
    this.preLoginRedirect();
    if (this.state.authenticated) {
      return this.postLoginRedirect();
    }
    return this.renderContent();
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  maintenanceMode: PropTypes.string,
  isMaintenanceLogin: PropTypes.bool,
};

export default withAuth(Login);
