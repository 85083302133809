import React from 'react';
import PropTypes from 'prop-types';

export const AccessEnabledRenderer = props => {
  return (
    <input
      type="checkbox"
      checked={props.value}
      disabled={props.data.disabled}
      onChange={props.data.onClick}
    />
  );
};

AccessEnabledRenderer.propTypes = {
  value: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

export default AccessEnabledRenderer;
