import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, NavLink } from 'reactstrap';

import {
  getFromLocalStorage,
  addToLocalStorage,
} from '../services/LocalStorageService';

export class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: this.isVisible() };
  }

  isVisible = () => {
    const value = getFromLocalStorage(this.props.name);
    return value !== false;
  };

  toggle = () => {
    const visible = !this.isVisible();
    this.setState({ visible }, () => {
      addToLocalStorage(this.props.name, visible);
    });
  };

  render() {
    const icon = this.state.visible ? '\u25BD' : '\u25B7';
    const activeClass = this.state.visible ? 'active' : '';
    const printLi = (s, i) => <li key={i}>{s}</li>;
    const printable =
      (this.props.printable && this.props.printable.map(printLi)) ||
      this.props.children;
    return (
      <div className="fpl-disclaimer-container">
        <div className={`fpl-disclaimer-web ${activeClass}`}>
          <NavLink href="#" onClick={this.toggle}>
            <span className="fpl-icon">{icon}</span>
            <span className="fpl-disclaimer-title">Disclaimer</span>
          </NavLink>
          <Collapse isOpen={this.state.visible}>
            <div className="disclaimer-content">{this.props.children}</div>
          </Collapse>
        </div>
        <div className="fpl-disclaimer-print text-justify disclaimer">
          <span className="fpl-disclaimer-title">Disclaimer</span>
          <ul className="fpl-list-style">{printable}</ul>
        </div>
      </div>
    );
  }
}

Disclaimer.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element,
  printable: PropTypes.arrayOf(PropTypes.string),
};

export default Disclaimer;
