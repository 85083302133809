import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Label,
  Button,
  Modal,
} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Storages } from '../../services/LocalStorageService';
import Constants from '../../services/Constants';
import Disclaimer from '../Disclaimer';
import {
  updateMaintenanceImage,
  updateMaintenanceImageTmp,
  resetMaintenanceTmpImage,
  getImageUrl,
} from './actions';

export class MaintenanceEditor extends Component {
  state = {
    selectedImage: null,
    initialSelectedItem: this.props.maintenanceSelectedField,
    onPreview: false,
    tmpImagePath: null,
    imgAux: null,
  };

  static layout = [
    {
      field: 'enabledMaintenance',
      label: 'Enable maintenance mode',
      type: 'radio',
    },
    {
      field: 'disabledMaintenance',
      label: 'Disable maintenance mode',
      type: 'radio',
    },
  ];

  onChangeImageHandler = event => {
    const selectedImage = event.target.files[0];
    this.setState({ selectedImage: selectedImage, imgAux: selectedImage });
    this.props.updateMaintenanceImageTmp(selectedImage);
  };

  closePreview = () => {
    const { selectedImage } = this.state;
    this.setState(() => ({ onPreview: false, imgAux: selectedImage }));
  };

  openPreview = () => {
    getImageUrl().then(resp => {
      const tmpImagePath = this.state.selectedImage
        ? `/maintenance/tmpFile.png?${Math.random()}`
        : resp.data;
      this.setState(() => ({ onPreview: true, tmpImagePath: tmpImagePath }));
    });
  };

  onPreviewImage = () => {
    const externalCloseBtn = (
      <Button
        color="primary"
        onClick={this.closePreview}
        className="close-Button"
        style={{ position: 'absolute', top: '15px', right: '15px' }}>
        Close
      </Button>
    );
    return (
      <div className="underMaintenancePreview">
        <Modal
          className="reasonAreaPreview"
          isOpen={this.state.onPreview}
          size="lg"
          external={externalCloseBtn}
          style={{ position: 'relative', 'z-index': '99999999' }}>
          <img
            className="reasonImagePreview"
            src={this.state.tmpImagePath}
            alt="Under maintenance"
            style={{
              position: 'absolute',
              left: '-10%',
              'max-width': '1200px',
            }}
          />
        </Modal>
      </div>
    );
  };

  onSave = () => {
    this.props.resetMaintenanceTmpImage();
    const enabled =
      this.props.maintenanceSelectedField === 'enabledMaintenance';
    const save = {
      enabled,
    };
    const { imgAux } = this.state;
    if (imgAux) {
      this.props.updateMaintenanceImage(imgAux);
    }
    this.setState(() => ({ selectedImage: null }));
    if (
      this.props.maintenanceSelectedField !== this.state.initialSelectedItem
    ) {
      this.props.saveMaintenance(save);
    }
  };

  onChange = (field, _type, e) => {
    if (e.target.checked) {
      this.props.updateMaintenanceOption(field);
    }
  };

  renderInput = ({ field, label, type }) => {
    const onChange = e => this.onChange(field, type, e);
    const checked = this.props.maintenanceSelectedField === field;
    return (
      <Row key={field}>
        <Col md={12}>
          <FormGroup row className="radio">
            <Input
              id={field}
              value={field}
              placeholder={label}
              type={type}
              onChange={onChange}
              checked={checked}
            />
            <Label for={field}>{label}</Label>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  getMaintenanceURL = () => {
    if (Constants.PORT !== null) {
      return `https://${Constants.HOST}:${Constants.PORT}/settings`;
    }
    return `https://${Constants.HOST}/settings`;
  };

  renderForm = () => {
    const form = _.map(MaintenanceEditor.layout, this.renderInput);
    const cannotSave = !!_.find(
      MaintenanceEditor.layout,
      _item =>
        this.props.maintenanceSelectedField === undefined ||
        this.props.maintenanceSelectedField === null
    );
    return (
      <Form className="admin">
        <Disclaimer name={Storages.SHOW_MAINTENANCE_DISCLAIMER_SETTING}>
          <span>
            Once maintenance mode is enabled, you will be forced out of the
            website and it will be unavailable for all users and can only be
            access by admin users throught the url
          </span>
          <ul>
            <li>
              <a href={this.getMaintenanceURL()}>{this.getMaintenanceURL()}</a>
            </li>
          </ul>
          <span className="ps-disclaimer">
            ps: we do recommend to bookmark the above url, before enabling the
            maintenance mode
          </span>
        </Disclaimer>
        {form}
        <br />
        <p>Select a new maintenance image to update the current one.</p>
        <input
          type="file"
          id="imgInput"
          accept="image/*"
          onChange={this.onChangeImageHandler}
        />
        <br />
        <Button
          className="previewButton float-left"
          onClick={this.openPreview}
          color="secondary">
          Preview
        </Button>
        <Row md={12} className="maintenance-buttons">
          <Col>
            <Button color="primary" onClick={this.onSave} disabled={cannotSave}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.onPreviewImage()}
      </div>
    );
  }
}

MaintenanceEditor.propTypes = {
  maintenanceSelectedField: PropTypes.string,
  saveMaintenance: PropTypes.func.isRequired,
  updateMaintenanceOption: PropTypes.func.isRequired,
  updateMaintenanceImage: PropTypes.func,
  updateMaintenanceImageTmp: PropTypes.func,
  resetMaintenanceTmpImage: PropTypes.func,
  oktaSettings: PropTypes.object,
  getImageUrl: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    oktaSettings: state.okta.settings,
  };
};

export default connect(mapStateToProps, {
  updateMaintenanceImage,
  updateMaintenanceImageTmp,
  resetMaintenanceTmpImage,
  getImageUrl,
})(MaintenanceEditor);
