import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Constants from '../../services/Constants';

/*
 * High Order Component that introduces `renderCaptcha` and `captcha` properties
 * to wrapped/decorated component.
 *
 * It uses ReCAPTCHA behind the scenes.
 *
 */
export const withCaptcha = WrappedComponent => {
  return class DecoratedWithCaptcha extends Component {
    constructor(props) {
      super(props);
      this.state = { captcha: null };
      this.recaptcha = null;
    }

    handleChange = value => {
      this.setState({ captcha: value });
    };

    renderCaptcha = () => {
      return (
        <ReCAPTCHA
          ref={recaptcha => (this.recaptcha = recaptcha)}
          sitekey={Constants.RECAPTCHA_SITEKEY}
          onChange={this.handleChange}
        />
      );
    };

    resetCaptcha = () => {
      if (this.recaptcha) {
        this.recaptcha.reset();
      }
    };

    render() {
      return (
        <WrappedComponent
          renderCaptcha={this.renderCaptcha}
          resetCaptcha={this.resetCaptcha}
          captcha={this.state.captcha}
          {...this.props}
        />
      );
    }
  };
};

export default withCaptcha;
