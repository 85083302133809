import React from 'react';
import { Container, Col, Row } from 'reactstrap';

export const NotFoundContent = () => (
  <Container>
    <Row className="content">
      <Col>
        <h2>Error 404</h2>
        <p>Sorry, the page you were looking for at this URL was not found.</p>
      </Col>
    </Row>
  </Container>
);

const NotFound = () => (
  <div className="notFound">
    <Container fluid className="header">
      <Row>
        <Col>
          <h1>Page not found</h1>
        </Col>
      </Row>
    </Container>
    <NotFoundContent />
  </div>
);

export default NotFound;
