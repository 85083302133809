import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { slug } from '../../services/SlugService';
import Constants from '../../services/Constants';
import StrategyRouter from './StrategyRouter';
import viewParams from './ViewParams';
import {
  loadMyManagers,
  loadSearchManagers,
  loadManagerSummary,
  loadManagerStrategies,
} from '../../actions';

export class MyManagersRouter extends Component {
  onManagerList = () => {
    return this.props.onDocuments(
      Constants.PLAN_PORTFOLIO,
      this.props.viewParams
    );
  };

  onStrategies = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.PLAN_MANAGER_STRATEGIES,
      viewParams
    );
  };

  onResearchDocuments = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.PLAN_MANAGER_MEETING_NOTES_VIEW,
      viewParams
    );
  };

  openPlanPopUp = (_props, viewParams) => {
    const vp = {
      ...viewParams,
      meetingNoteWriteUpId: _props.match.params.meetingNoteWriteUpId,
    };
    return this.props.onDocuments(Constants.PLAN_MANAGER_NOTES_VIEW, vp);
  };

  openDDeventsPopUp = (_props, viewParams) => {
    const vp = {
      ...viewParams,
      ddEventId: _props.match.params.ddEventId,
    };
    return this.props.onDocuments(Constants.PLAN_MANAGER_NOTES_VIEW, vp);
  };

  onInvestmentFirm = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.CONSULTING_RESOURCES_VIEW,
      viewParams
    );
  };

  onStrategy = (props, viewParams) => {
    const { match } = props;
    const { slugStrategy } = match.params;

    const strategy = _.find(
      this.props.managerStrategies,
      strategy => slug(strategy.investmentStrategyName) === slugStrategy
    );

    this.props.loadManagerStrategies(viewParams.managerId);

    if (!strategy) {
      return this.onStrategies(props, viewParams);
    }

    return (
      <StrategyRouter
        match={match}
        strategy={strategy}
        onDocuments={this.props.onDocuments}
        viewParams={viewParams}
      />
    );
  };

  onStrategyManagerActivities = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.EMPLOYEE_MANAGER_ACTIVITIES,
      viewParams
    );
  };

  onPortfolioDDs = (_props, viewParams) => {
    return this.props.onDocuments(Constants.PLAN_PORTFOLIO_DD, viewParams);
  };

  onPortfolioNotes = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.PLAN_MANAGER_NOTES_VIEW,
      viewParams
    );
  };

  onEVest = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.PLAN_MANAGER_EVEST_NARRATIVES,
      viewParams
    );
  };

  onManager = ({ match }) => {
    const { managerSearchList, myManagersList } = this.props;
    const { slugManager } = match.params;

    const slugManagerSearch = _.find(
      managerSearchList,
      item => slug(item.value) === slugManager
    );
    const slugManagerMy = _.find(
      myManagersList,
      manager => slug(manager.firmName) === slugManager
    );
    const manager = slugManagerMy || slugManagerSearch;

    const slugManagerId =
      _.get(slugManagerSearch, 'key') || _.get(slugManagerMy, 'firmId');

    if (!slugManagerId) {
      return this.onManagerList();
    }
    this.props.loadManagerSummary(slugManagerId);
    const newViewParams = {
      ...this.props.viewParams,
      manager,
      managerId: slugManagerId,
      managerSummary: this.props.managerSummary,
      managerUrl: match.url,
    };

    return (
      <Switch>
        <Route
          path={`${match.url}/strategy/:slugStrategy`}
          render={props => this.onStrategy(props, newViewParams)}
        />
        <Route
          path={`${match.url}/strategies`}
          render={props => this.onStrategies(props, newViewParams)}
        />
        <Route
          path={`${match.url}/evest`}
          render={props => this.onEVest(props, newViewParams)}
        />
        <Route
          path={`${match.url}/activities`}
          render={props =>
            this.onStrategyManagerActivities(props, newViewParams)
          }
        />
        <Route
          path={`${match.url}`}
          render={props => this.onStrategies(props, newViewParams)}
        />
      </Switch>
    );
  };

  render() {
    const { match, selectedPlan, viewParams } = this.props;
    if (selectedPlan) {
      this.props.loadMyManagers(selectedPlan.planId);
      this.props.loadSearchManagers(selectedPlan.planId);
    }
    return (
      <Switch>
        <Route
          path={`${match.url}/dds/:ddEventId`}
          render={props => this.openDDeventsPopUp(props, viewParams)}
        />
        <Route
          path={`${match.url}/meeting-notes/:meetingNoteWriteUpId`}
          render={props => this.openPlanPopUp(props, viewParams)}
        />
        <Route
          path={`${match.url}/activities`}
          render={props => this.onPortfolioNotes(props, viewParams)}
        />
        <Route
          path={`${match.url}/dds`}
          render={props => this.onPortfolioNotes(props, viewParams)}
        />
        <Route
          path={`${match.url}/meeting-notes`}
          render={props => this.onPortfolioNotes(props, viewParams)}
        />
        <Route
          path={`${match.url}/investmentFirm`}
          render={props => this.onInvestmentFirm(props, viewParams)}
        />
        <Route path={`${match.url}/:slugManager`} render={this.onManager} />
        <Route render={this.onManagerList} />
      </Switch>
    );
  }
}

MyManagersRouter.propTypes = {
  match: PropTypes.object.isRequired,
  onDocuments: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object,
  viewParams,

  loadMyManagers: PropTypes.func.isRequired,
  loadSearchManagers: PropTypes.func.isRequired,
  loadManagerSummary: PropTypes.func.isRequired,
  loadManagerStrategies: PropTypes.func.isRequired,

  managerSummary: PropTypes.object,
  myManagersList: PropTypes.array,
  managerStrategies: PropTypes.array,
  managerSearchList: PropTypes.array,
};

const mapStateToProps = state => {
  const { selectedPlan } = state.ui;
  return {
    selectedPlan,
    myManagersList: state.resources.my_managers.data || [],
    managerSearchList: state.resources.search_managers.data || [],
    managerStrategies: state.resources.manager_strategies.data || [],
    managerSummary: state.resources.manager_summary.data || {},
  };
};

export default connect(mapStateToProps, {
  loadMyManagers,
  loadSearchManagers,
  loadManagerSummary,
  loadManagerStrategies,
})(MyManagersRouter);
