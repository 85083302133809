import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import { redirect } from '../../../actions/UIActions';
import { slug } from '../../../services/SlugService';
import Constants from '../../../services/Constants';

export const InvestmentFirmLink = props => {
  const firmLink = calculateConsultingResourcesUrl(props);

  const onClick = () => props.redirect(firmLink);

  return (
    <Button
      className="gridLink"
      color="link"
      onClick={onClick}
      id={`${slug(props.value)}-${props.rowIndex}`}>
      {props.value}
    </Button>
  );
};

const calculateConsultingResourcesUrl = props => {
  if (props.employeeActiveTab === Constants.CR_MEETING_NOTES_VIEW) {
    return `/consultingResources/meetingnotes/${slug(
      props.value
    )}/investmentFirm`;
  }
  return `/consultingResources/dds/${slug(props.value)}/investmentFirm`;
};

InvestmentFirmLink.propTypes = {
  value: PropTypes.string,
  redirect: PropTypes.func,
  employeeActiveTab: PropTypes.string,
  rowIndex: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    employeeActiveTab: state.ui.employeeActiveTab,
  };
};

export default connect(mapStateToProps, { redirect })(InvestmentFirmLink);
