import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import PDFViewer from './PDFViewer';
import { saveDocSize } from '../../actions';

const MAX_ZOOM = 1.8;
const MIN_ZOOM = 1.1;
const ZOOM_STEP = 0.05;
const DEFAULT_ZOOM = 1.2;

export class PDFmodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomValue: DEFAULT_ZOOM,
      page: 0,
    };
  }

  onCloseModal = () => {
    const { page } = this.state;
    this.props.onCloseFullScreen(page > 0 ? page : this.props.page);
    this.setState({ ...this.state, zoomValue: DEFAULT_ZOOM });
  };

  onPageChanged = page => {
    this.setState({ page });
  };

  onZoomIn = () => {
    this.setState({
      ...this.state,
      zoomValue: this.state.zoomValue + ZOOM_STEP,
    });
  };

  onZoomOut = () => {
    this.setState({
      ...this.state,
      zoomValue: this.state.zoomValue - ZOOM_STEP,
    });
  };

  onResetZoom = () => {
    this.setState({ ...this.state, zoomValue: DEFAULT_ZOOM });
  };

  createThumbs = () => {
    this.setstate({ ...this.state });
  };

  render() {
    const { doc, page } = this.props;
    const fullScreenStyle = {
      maxWidth: '2500px',
      width: '100%',
      margin: '0px auto',
      maxHeight: '2000px',
      height: '100vh',
      minHeight: '100vh',
      'overflow-y': 'hidden',
    };
    if (doc) {
      return (
        <div>
          <Modal
            size="lg"
            isOpen={true}
            keyboard={true}
            style={fullScreenStyle}>
            <ModalBody
              style={{
                height: '89vh',
              }}>
              <div className="zoom-butons-area align-self-start d-flex justify-content-end flex-column-reverse">
                <button
                  type="button"
                  className="btn-zoom order-3 p-2"
                  onClick={this.onCloseModal}>
                  <i className="fas close-icon fa-times fa-lg" />
                </button>
                <button
                  type="button"
                  className="btn-zoom order-0 p-2"
                  onClick={this.onZoomOut}
                  disabled={this.state.zoomValue < MIN_ZOOM}>
                  <i className="fas zoom-icon fa-search-minus fa-lg" />
                </button>
                <button
                  type="button"
                  className="btn-zoom order-1 p-2"
                  onClick={this.onResetZoom}
                  disabled={this.state.zoomValue === DEFAULT_ZOOM}>
                  <i className="fas zoom-icon fa-search fa-lg" />
                </button>
                <button
                  type="button"
                  className="btn-zoom order-2 p-2"
                  onClick={this.onZoomIn}
                  disabled={this.state.zoomValue > MAX_ZOOM}>
                  <i className="fas zoom-icon fa-search-plus fa-lg" />
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  'justify-content': 'center',
                  'align-items': 'center',
                }}>
                <PDFViewer
                  documentFile={doc}
                  isFullScreen={true}
                  zoomValue={this.state.zoomValue}
                  page={page}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            </ModalBody>
            <ModalHeader style={{ height: '10.7vh' }} />
          </Modal>
        </div>
      );
    }
    return null;
  }
}

PDFmodal.propTypes = {
  onCloseFullScreen: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  doc: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    doc: this.state.doc,
  };
};

export default connect(mapStateToProps, {
  saveDocSize,
})(PDFmodal);
