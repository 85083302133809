import { combineReducers } from 'redux';

import okta from './OktaReducer';
import ui from './UIReducer';
import changePassword from '../components/change_password/reducer.js';
import messages from '../components/messages/reducer.js';
import terms from '../components/terms/reducer.js';
import { Reducer as resources } from '../actions/Resources';

export default combineReducers({
  okta,
  ui,
  changePassword,
  messages,
  terms,
  resources,
});
