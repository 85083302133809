import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Constants from '../../services/Constants';
import EmployeeDDEventsRouter from './EmployeeDDEventsRouter';
import EmployeeMeetingNotesRouter from './EmployeeMeetingNotesRouter';

class ConsultingResourcesRouter extends Component {
  onDDs = match => {
    return (
      <EmployeeDDEventsRouter match={match} withMain={this.props.withMain} />
    );
  };

  openDDeventsPopUp = (_props, viewParams) => {
    const vp = {
      ...viewParams,
      investmentFirm: null,
      selectedTab: Constants.CR_DD_EVENTS_VIEW,
      eventId: _props.match.params.eventId,
    };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  openMeetingNoteModal = (_props, viewParams) => {
    const vp = {
      ...viewParams,
      investmentFirm: null,
      selectedTab: Constants.CR_MEETING_NOTES_VIEW,
      writeUpId: _props.match.params.writeUpId,
    };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  onMeetingNotes = match => {
    return (
      <EmployeeMeetingNotesRouter
        match={match}
        withMain={this.props.withMain}
      />
    );
  };

  resetSelectedPlan = () => {
    const { ui } = this.props;
    if (ui.selectedPlan) {
      ui.selectedPlan = null;
      this.setState({ ui });
    }
  };

  onConsultingResources = selectedTab => {
    const vp = { investmentFirm: null, selectedTab: selectedTab };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  render() {
    this.resetSelectedPlan();
    const { match } = this.props;

    return (
      <Switch>
        <Route
          // al - Auto load DDEvents
          path={`${match.url}/dds/al/:eventId`}
          render={props => this.openDDeventsPopUp(props, match)}
        />
        <Route
          // al - Auto load Meeting Notes
          path={`${match.url}/meetingnotes/al/:writeUpId`}
          render={props => this.openMeetingNoteModal(props, match)}
        />
        <Route path={`${match.url}/dds`} render={props => this.onDDs(match)} />
        <Route
          path={`${match.url}/meetingnotes`}
          render={props => this.onMeetingNotes(match)}
        />
        <Route
          path={`${match.url}`}
          render={props =>
            this.onConsultingResources(Constants.CR_MEETING_NOTES_VIEW)
          }
        />
      </Switch>
    );
  }
}

ConsultingResourcesRouter.propTypes = {
  match: PropTypes.object.isRequired,
  withMain: PropTypes.func,
  ui: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    ui: state.ui,
  };
};

export default connect(mapStateToProps, {})(ConsultingResourcesRouter);
