import _ from 'lodash';
import moment from 'moment';

export const DEFAULT_STATE = {
  data: undefined,
  loading: false,
  error: false,
  errorLock: 0,
  errorMessage: '',
  cache: {
    accessToken: '',
    requests: {
      // [_params_json]: { data, created }
    },
  },
};

export const SUFFIX_SUCCESS = '_SUCCESS';
export const SUFFIX_FAILED = '_FAILED';
export const SUFFIX_RESET = '_RESET';
export const SUFFIX_CACHE_HIT = '_CACHE_HIT';

export const loadActionMeta = type => {
  const field = type.substring(type.indexOf('_') + 1).toLowerCase();
  const rootField = type.substring(0, type.indexOf('_')).toLowerCase();
  return { rootField, field };
};

export const RequestActionOpts = (type, service, opts, ...args) => {
  const nop = o => o;
  const cacheTTL = opts.cacheTTL || 600;
  const storageTTL = opts.storageTTL || 1200;
  const errorLock = opts.errorLock || 30;
  const skipCache = opts.skipCache || false;
  const mapper = opts.mapper || nop;
  const onData = opts.onData || nop;
  const now = moment().unix();
  const requestKey = JSON.stringify([...args]);
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    const { field, rootField } = loadActionMeta(type);
    const rootState = getState()[rootField];
    if (!rootState) {
      throw Error('Could not retrieve RequestAction ROOTstate for ' + type);
    }
    const state = rootState[field];
    if (!state) {
      throw Error('Could not retrieve RequestAction state for ' + type);
    }
    if (!state.loading && state.errorLock < now) {
      if (accessToken !== state.cache.accessToken) {
        dispatch({ type: `${type}${SUFFIX_RESET}`, accessToken });
      }
      const cache = state.cache.requests[requestKey];
      const cached = cache && cache.data;
      const hasExpired = () => cache.created + cacheTTL < now;
      if (!skipCache && cached !== undefined && !hasExpired()) {
        onData(cached);
        dispatch({ type: `${type}${SUFFIX_CACHE_HIT}`, cached });
      } else {
        const requests = clearRequestsCache(state, storageTTL, now);
        dispatch({ type, cached, requests });
        return service(accessToken, ...args)
          .then(({ data }) => {
            const created = now;
            const mapped = mapper(data, state);
            onData(mapped);
            dispatch({
              type: `${type}${SUFFIX_SUCCESS}`,
              requestKey,
              created,
              data: mapped,
            });
          })
          .catch(e => {
            dispatch({
              type: `${type}${SUFFIX_FAILED}`,
              errorMessage: e.message,
              errorLock: now + errorLock,
            });
            throw e;
          });
      }
    }
    return new Promise(resolve => setTimeout(resolve, 100));
  };
};

export const RequestActionMap = (type, service, mapper, ...args) => {
  return RequestActionOpts(type, service, { mapper }, ...args);
};

export const RequestAction = (type, service, ...args) => {
  return RequestActionMap(type, service, o => o, ...args);
};

export const RequestInitReducer = types =>
  _.reduce(
    types,
    (acct, type) => {
      const { field } = loadActionMeta(type);
      return { ...acct, [field]: { ...DEFAULT_STATE } };
    },
    {}
  );

export const RequestReducer = (state, types, action) => {
  // eslint-disable-next-line
  for (const i in types) {
    const type = types[i];
    if (action.type.startsWith(type)) {
      const { field } = loadActionMeta(type);
      // TODO: refactor to use switch
      if (action.type === type) {
        return {
          ...state,
          [field]: {
            ...state[field],
            data: action.cached,
            errorMessage: '',
            error: false,
            errorLock: 0,
            loading: true,
            isLoaded: false,
            cache: {
              ...state[field].cache,
              requests: action.requests,
            },
          },
        };
      } else if (action.type.endsWith(SUFFIX_RESET)) {
        return {
          ...state,
          [field]: {
            ...DEFAULT_STATE,
            isLoaded: true,
            cache: {
              ...DEFAULT_STATE.cache,
              accessToken: action.accessToken,
            },
          },
        };
      } else if (action.type.endsWith(SUFFIX_FAILED)) {
        return {
          ...state,
          [field]: {
            ...state[field],
            error: true,
            errorMessage: action.errorMessage,
            errorLock: action.errorLock,
            loading: false,
            isLoaded: true,
          },
        };
      } else if (action.type.endsWith(SUFFIX_SUCCESS)) {
        return {
          ...state,
          [field]: {
            ...state[field],
            data: action.data,
            error: false,
            errorMessage: '',
            errorLock: 0,
            loading: false,
            isLoaded: true,
            cache: {
              ...state[field].cache,
              requests: {
                ...state[field].cache.requests,
                [action.requestKey]: {
                  data: action.data,
                  created: action.created,
                },
              },
            },
          },
        };
      } else if (action.type.endsWith(SUFFIX_CACHE_HIT)) {
        if (action.cached === state[field].data) {
          return state;
        } else {
          return {
            ...state,
            [field]: {
              ...state[field],
              data: action.cached,
              error: false,
              errorMessage: '',
              errorLock: 0,
              loading: false,
              isLoaded: true,
            },
          };
        }
      } else {
        throw new Error('Could not handle action type ' + type);
      }
    }
  }
  return state;
};

export function clearRequestsCache(state, storageTTL, now) {
  const previous = state.cache.requests;
  return _.reduce(
    _.keys(previous),
    (requests, requestKey) => {
      const cache = previous[requestKey];
      const shouldKeep = cache.created + storageTTL > now;
      if (shouldKeep) {
        return {
          ...requests,
          [requestKey]: previous[requestKey],
        };
      }
      return requests;
    },
    {}
  );
}

export default {
  RequestAction,
  RequestActionMap,
  RequestReducer,
  RequestInitReducer,
};
