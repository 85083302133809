import _ from 'lodash';
import { NotificationManager } from 'react-notifications';

import { Events } from '../services/Constants';
import DenodoService from '../services/DenodoService';
import DocumentService from '../services/DocumentService';
import { withLoading } from './CommonActions';
import { addEvent } from './EventActions';
import {
  checkAndDispatchDocument,
  loadAllManagers,
  loadDDEventResource,
  loadFPLNepcOpinionResource,
  loadFocusedPlacementListResource,
  loadManagerSummaryResource,
  loadMarketUpdateDocs,
} from './Resources';
import * as types from './Types';

export const openDocument = withLoading(doc => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    const docId = `${doc.title}/type=${doc.deliverableType}/fileName=${doc.fileName}/objType=${doc.objectType}/objectId=${doc.objectDocumentId}/objectVersion=${doc.objectVersion}/fileId=${doc.filedocumentId}`;
    dispatch(addEvent(Events.OPEN_DOCUMENT, docId));
    return DocumentService().downloadDocumentDirect(accessToken, doc);
  };
});

export const openMarketUpdates = (doc, notifyRead) => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    const docId = `${doc.title}/type=${doc.deliverableType}/fileName=${doc.fileName}/objType=${doc.objectType}/objectId=${doc.objectDocumentId}/objectVersion=${doc.objectVersion}/fileId=${doc.filedocumentId}`;
    dispatch(addEvent(Events.OPEN_DOCUMENT, docId));
    if (notifyRead) {
      dispatch(
        addEvent(Events.OPEN_MARKET_DOCUMENT, doc.objectDocumentId)
      ).then(data => dispatch(loadMarketUpdateDocs(true)));
    }
    return DocumentService().fetchBlobDocument(accessToken, doc);
  };
};

export const checkDocument = (planIds, planTypes, email, fileId) => {
  return dispatch => {
    return dispatch(
      checkAndDispatchDocument(planIds, planTypes, email, fileId)
    );
  };
};

export const displayMeetingNotes = (meetingNotes = null) => {
  return dispatch => {
    dispatch({ type: types.DISPLAY_MEETING_NOTES_SUCCESS, meetingNotes });
  };
};

export const openMeetingNote = withLoading(meeting => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    const originId = meeting.firm
      ? `firm=${meeting.firm}`
      : `strategy=${meeting.strategyId}`;
    const meetingId = `${meeting.subject}/${originId}/writeUpId=${meeting.writeUpId}`;
    dispatch(addEvent(Events.OPEN_MEETING_NOTE, meetingId));
    return DenodoService()
      .getMeetingNotes(accessToken, meeting.writeUpId, meeting.writeUpStatus)
      .then(response => {
        if (response.status !== 200) {
          return NotificationManager.error(
            'Oops. Something went wrong. Please contact your consultant.'
          );
        }
        const { data } = response;
        const meetingNotes = _.head(data);
        dispatch(displayMeetingNotes(meetingNotes));
      })
      .catch(() =>
        NotificationManager.error(
          'Oops. Something went wrong. Please contact your consultant.'
        )
      );
  };
});

export const showDDEvent = ddEvent => {
  return dispatch => {
    dispatch({
      type: types.SHOW_DD_EVENT,
      ddEvent,
    });
  };
};

export const openDDEvent = withLoading(ddEvent => {
  return dispatch => {
    const onData = event => {
      if (!event) {
        return NotificationManager.error(
          'Oops. Something went wrong. Please contact your consultant.'
        );
      }
      dispatch(
        addEvent(
          Events.OPEN_DD_EVENT,
          `${event.eventId}/eventTitle=${event.eventTitle}/eventDate=${event.date}`
        )
      );
      dispatch(showDDEvent(event));
    };
    const { eventId } = ddEvent;
    return dispatch(loadDDEventResource(eventId, onData));
  };
});

export const loadFocusedPlacementList = fplList => dispatch => {
  return dispatch(
    loadFocusedPlacementListResource(fplList.fplListId, data => {
      dispatch(
        addEvent(
          Events.OPEN_FOCUSED_PLACEMENT_LIST,
          `${fplList.fplListId}/listName=${fplList.listName}`
        )
      );
      return _.keyBy(data, 'strategyId');
    })
  );
};

export const loadAllStrategiesManagerList = () => dispatch => {
  return dispatch(loadAllManagers());
};

export const loadFPLNepcOpinion = strategy => dispatch => {
  return dispatch(
    loadFPLNepcOpinionResource(strategy.strategyId, data => {
      dispatch(
        addEvent(
          Events.OPEN_STRATEGY,
          `${strategy.strategyId}/strategyName=${strategy.investmentStrategyName}`
        )
      );
      return data;
    })
  );
};

export const loadManagerSummary = managerId => dispatch => {
  return dispatch(
    loadManagerSummaryResource(managerId, data => {
      dispatch(
        addEvent(Events.OPEN_MANAGER, `${managerId}/firmName=${data.firmName}`)
      );
      return data;
    })
  );
};
