import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';

import { openDocument } from '../../../actions';

export const DownloadDocumentButton = props => {
  const onClick = () => props.openDocument(props.data);
  return (
    <NavLink className="download-button" href="#" onClick={onClick}>
      <i className="fa fa-file-download"></i>
    </NavLink>
  );
};

DownloadDocumentButton.propTypes = {
  openDocument: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default connect(null, { openDocument })(DownloadDocumentButton);
