import axios from 'axios';

import Constants from './Constants';

const EventService = () => {
  const EVENTS_URL = Constants.EVENTS.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const addEvent = (accessToken, evnt) =>
    axios.post(`${EVENTS_URL}/eventAdd`, evnt, _headers(accessToken));

  const lastEvent = (accessToken, query) =>
    axios.post(`${EVENTS_URL}/eventView`, query, _headers(accessToken));

  const eventsByType = (accessToken, eventType) =>
    axios.post(
      `${EVENTS_URL}/eventsByType/${eventType}`,
      {},
      _headers(accessToken)
    );

  const feedback = (accessToken, feedback) =>
    axios.post(`${EVENTS_URL}/feedback`, feedback, _headers(accessToken));

  const getFeedbacks = accessToken =>
    axios.get(`${EVENTS_URL}/feedback`, _headers(accessToken));

  return {
    addEvent,
    lastEvent,
    feedback,
    getFeedbacks,
    eventsByType,
  };
};

export default EventService;
