import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { printMessages, addEvent } from '../../../actions';
import {
  hideSystemMessage,
  markSystemMessageSeen,
} from '../../messages/actions';

export class MessagesModal extends Component {
  toggle = () => {
    const messageId = this.props.data.id;
    this.props.markSystemMessageSeen([messageId]);
    this.props.hideSystemMessage();
  };

  print = () => {
    const elem = document.querySelector('.messagesModalDetail');
    const html = elem.parentElement.innerHTML;

    const sendData = {
      documentType: 'NEPC Announcement',
      email: this.props.user.email,
      subject: this.props.data.subject,
    };
    this.props.printMessages(sendData, html);
  };

  renderContent(message) {
    return (
      <div className="messagesModalArea quill ql-editor ql-container">
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    );
  }

  renderPrint() {
    if (!this.props.data) {
      return null;
    }
    return (
      <Button color="secondary" className="text-nowrap" onClick={this.print}>
        Download PDF
      </Button>
    );
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const postedDate = moment(data.startDate).format('DD MMM YYYY');
    return (
      <Modal
        isOpen={true}
        toggle={this.toggle}
        size="lg"
        className="messagesModalDetail">
        <ModalHeader toggle={this.toggle} tag="span">
          <p className="messagesStartDate">Posted {postedDate}</p>
          <h5>{data.subject}</h5>
        </ModalHeader>
        <ModalBody>{this.renderContent(data.message)}</ModalBody>
        <ModalFooter>
          {this.renderPrint()}
          <Button color="primary" onClick={this.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MessagesModal.propTypes = {
  data: PropTypes.object,
  printMessages: PropTypes.func.isRequired,
  hideSystemMessage: PropTypes.func.isRequired,
  markSystemMessageSeen: PropTypes.func.isRequired,
  addEvent: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const data = state.ui.isLoading ? null : state.messages.showing;
  const {
    okta: { user },
  } = state;
  return { data, user };
};

export default connect(mapStateToProps, {
  printMessages,
  hideSystemMessage,
  addEvent,
  markSystemMessageSeen,
})(MessagesModal);
