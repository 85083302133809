import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

import FeedbackAdmin from '../feedback/FeedbackAdmin';
import {
  saveSystemMessage,
  showSystemMessage,
  loadAll,
  saveMaintenance,
  updateMaintenanceOption,
} from './actions';
import TermsAdmin from '../terms/TermsAdmin';
import MaintenanceEditor from './MaintenanceEditor';
import AnnouncementsAdmin from './AnnouncementsAdmin';
import Constants from '../../services/Constants';
import { NotFoundContent } from '../NotFound';
import NotificationsAdmin from '../Notifications/NotificationsAdmin';

export class HubAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: undefined,
    };
  }

  onAnnouncements = () => {
    const {
      saveSystemMessage,
      showSystemMessage,
      loadAll,
      all,
      allLoaded,
      viewParams,
    } = this.props;
    return (
      <AnnouncementsAdmin
        loadAll={loadAll}
        allLoaded={allLoaded}
        all={all}
        viewParams={viewParams}
        showSystemMessage={showSystemMessage}
        saveSystemMessage={saveSystemMessage}
      />
    );
  };

  onMaintenance = () => {
    return (
      <MaintenanceEditor
        maintenanceSelectedField={this.props.maintenanceSelectedField}
        saveMaintenance={this.props.saveMaintenance}
        updateMaintenanceOption={this.props.updateMaintenanceOption}
      />
    );
  };

  onTerms = () => {
    return <TermsAdmin viewParams={this.props.viewParams} />;
  };

  onFeedback = () => {
    return <FeedbackAdmin viewParams={this.props.viewParams} />;
  };

  onNotifications = () => {
    return <NotificationsAdmin />;
  };

  render() {
    const { isRolesLoaded, isAdmin } = this.props;
    if (!isRolesLoaded) {
      return null;
    }
    if (!isAdmin) {
      return <NotFoundContent />;
    }
    const options = {
      Announcements: this.onAnnouncements,
      Maintenance: this.onMaintenance,
      Terms: this.onTerms,
      Feedback: this.onFeedback,
      Notifications: this.onNotifications,
    };
    const tabs = _.keys(options);
    const selected = this.state.tab || tabs[0];
    const select = tab => this.setState({ tab });
    const links = _.map(tabs, l => (
      <NavItem key={l} className="adminTab">
        <NavLink onClick={() => select(l)} active={selected === l}>
          {l}
        </NavLink>
      </NavItem>
    ));
    return (
      <Container className="hub-admin" fluid>
        <Nav tabs className="mb-4">
          {links}
        </Nav>
        {options[selected]()}
      </Container>
    );
  }
}

HubAdmin.propTypes = {
  saveSystemMessage: PropTypes.func.isRequired,
  showSystemMessage: PropTypes.func.isRequired,
  loadAll: PropTypes.func.isRequired,
  maintenanceSelectedField: PropTypes.string,
  saveMaintenance: PropTypes.func.isRequired,
  updateMaintenanceOption: PropTypes.func.isRequired,

  isAdmin: PropTypes.bool.isRequired,
  isRolesLoaded: PropTypes.bool,
  all: PropTypes.array.isRequired,
  allLoaded: PropTypes.bool.isRequired,
  viewParams: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    messages: { all, allLoaded, maintenanceSelectedField },
  } = state;
  const isAdmin = _.includes(state.okta.roles, Constants.SYSTEM_ADMIN_ROLE);
  return {
    all,
    allLoaded,
    maintenanceSelectedField,
    isAdmin,
    isRolesLoaded: state.okta.isRolesLoaded,
  };
};

export default connect(mapStateToProps, {
  saveSystemMessage,
  showSystemMessage,
  loadAll,
  saveMaintenance,
  updateMaintenanceOption,
})(HubAdmin);
