import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Form, FormGroup, Row, Col, Input, Label, Button } from 'reactstrap';
import ReactQuill from 'react-quill';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import Constants from '../../services/Constants';

export class MessagesEditor extends Component {
  static layout = [
    { field: 'subject', label: 'Subject', type: 'text' },
    { field: 'startDate', label: 'Start date', type: 'date' },
    { field: 'expirationDate', label: 'Expiration date', type: 'date' },
    { field: 'message', label: 'Message', type: 'quill' },
    { field: 'active', label: 'Active', type: 'checkbox' },
  ];

  constructor(props) {
    super(props);
    const sanitizeDate = value => moment.utc(value).format('Y-MM-DD');
    this.state = {
      ...props.message,
      startDate: sanitizeDate(props.message.startDate),
      expirationDate: sanitizeDate(props.message.expirationDate),
    };
  }

  onSave = () => {
    const save = {
      ...this.state,
      startDate: new Date(this.state.startDate).toISOString(),
      expirationDate: new Date(this.state.expirationDate).toISOString(),
    };
    this.props.saveSystemMessage(save, () => {
      this.props.editSystemMessage(null);
    });
  };

  onPreview = () => {
    this.props.showSystemMessage(this.state);
  };

  onCancel = () => {
    this.props.editSystemMessage(null);
  };

  onChange = (field, type, e) => {
    const value =
      (e.target &&
        ((type !== 'checkbox' && e.target.value) ||
          (type === 'checkbox' && e.target.checked))) ||
      (!e.target && e);
    this.setState({ ...this.state, [field]: value });
  };

  renderInput = ({ field, label, type }) => {
    const Inputt = type === 'quill' ? ReactQuill : Input;
    const inputProps =
      type === 'quill' ? { modules: Constants.QUILL_TOOLBAR_MODULE } : {};
    const valueProp = type === 'checkbox' ? 'checked' : 'value';
    const value = this.state[field] || '';
    const onChange = e => this.onChange(field, type, e);
    const status = type === 'quill' ? `${value.length}/4000` : null;
    const input =
      type === 'date' ? (
        <DayPickerInput
          id={field}
          inputProps={{ className: 'form-control' }}
          onDayChange={onChange}
          value={value}
        />
      ) : (
        <Inputt
          id={field}
          placeholder={label}
          type={type}
          onChange={onChange}
          {...inputProps}
          {...{ [valueProp]: value }}
        />
      );
    return (
      <FormGroup row key={field}>
        <Col sm={12} md={2} lg={1}>
          <Label for={field}>{label}</Label>
        </Col>
        <Col sm={12} md={10} lg={11}>
          {input}
          {status}
        </Col>
      </FormGroup>
    );
  };

  render() {
    const form = _.map(MessagesEditor.layout, this.renderInput);
    const cannotSave =
      !!_.find(
        MessagesEditor.layout,
        item => item.type !== 'checkbox' && !this.state[item.field]
      ) || (this.state['message'] || '').length > 4000;
    return (
      <Form className="admin">
        {form}
        <Row>
          <Col md={12}>
            <Button color="primary" onClick={this.onSave} disabled={cannotSave}>
              Save
            </Button>
            <Button
              color="success"
              className="ml-2"
              onClick={this.onPreview}
              disabled={cannotSave}>
              Preview
            </Button>
            <Button color="secondary" className="ml-4" onClick={this.onCancel}>
              Go back
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

MessagesEditor.propTypes = {
  message: PropTypes.object.isRequired,
  editSystemMessage: PropTypes.func.isRequired,
  showSystemMessage: PropTypes.func.isRequired,
  saveSystemMessage: PropTypes.func.isRequired,
};

export default MessagesEditor;
