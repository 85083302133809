import logo from '../../../assets/squareLogo.jpg';

const OktaConfig = {
  baseUrl: '',
  logo,
  features: {
    rememberMe: true,
    registration: true,
  },
  i18n: {
    en: {
      'primaryauth.title': 'Please enter your information',
      'primaryauth.username.placeholder': 'Username',
      'registration.signup.label': ' ',
      'registration.signup.text': 'Register',
    },
  },
  helpLinks: {
    help: '',
  },
  registration: {
    click: () => {
      window.location.href = '/register';
    },
  },
};

export default OktaConfig;
