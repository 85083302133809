import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';

import viewParams from './router/ViewParams';
import { hideFeedbackModal, submitFeedback } from '../actions';

const Rating = ({ value, select, disabled }) => {
  const maxStars = 10;
  const items = _.range(maxStars).map(i => (
    <Button
      key={i}
      outline={disabled ? !(i === value - 1) : true}
      className="ml-2 custom-btn"
      active={i === value - 1}
      onClick={() => select(i + 1)}
      disabled={disabled}>
      {i + 1}
    </Button>
  ));
  return (
    <Row className="rating">
      <Col md={12}>{items}</Col>
      <Col md={12} className="legend">
        <span className="least">Not Likely</span>
        <span className="most">Very Likely</span>
      </Col>
    </Row>
  );
};
Rating.propTypes = {
  value: PropTypes.number,
  select: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export class FeedbackModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: undefined,
      message: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clear !== nextProps.clear) {
      this.setState({
        rate: undefined,
        message: '',
      });
    }
  }

  onRate = rate => {
    this.setState({ rate });
  };

  onMessage = ({ target: { value } }) => {
    this.setState({ message: value });
  };

  onSubmit = () => {
    const { rate, message } = this.state;
    this.props.submitFeedback(rate, message, this.props.viewParams);
  };

  render() {
    const { hideFeedbackModal, visible, data } = this.props;
    if (!visible) {
      return null;
    }
    const canSubmit = this.state.message.length > 5 && this.state.rate;
    const readOnly = !!data;

    // find a better way to avoid the render to re-render everytime that this data is set
    if (
      data &&
      data.message !== this.state.message &&
      data.rate !== this.state.rate
    ) {
      this.setState({
        message: data.message,
        rate: data.rate,
      });
    }

    return (
      <Modal
        className="feedback-modal"
        isOpen={true}
        toggle={hideFeedbackModal}
        size="lg">
        <ModalHeader toggle={hideFeedbackModal}>Give us Feedback!</ModalHeader>
        <ModalBody>
          <p>
            Based on your experience today with NEPC&apos;s ClientHUB, how
            likely are you to recommend ClientHUB to a friend or colleague?
          </p>
          <Rating
            value={this.state.rate}
            select={this.onRate}
            disabled={readOnly}
          />
          <p>How would you want to use ClientHUB?</p>
          <textarea
            rows={10}
            onChange={this.onMessage}
            placeholder="I want to be able to..."
            value={this.state.message}
            disabled={readOnly}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={hideFeedbackModal}>
            Close
          </Button>
          {readOnly ? null : (
            <Button
              className="submitBtn"
              onClick={this.onSubmit}
              disabled={!canSubmit}>
              Submit
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  hideFeedbackModal: PropTypes.func.isRequired,
  submitFeedback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  clear: PropTypes.number.isRequired,
  viewParams,
  data: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    visible: state.ui.feedbackVisible || false,
    data: state.ui.feedbackItem,
    clear: state.ui.feedbackClear,
  };
};

export default connect(mapStateToProps, { hideFeedbackModal, submitFeedback })(
  FeedbackModal
);
