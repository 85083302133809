import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { hideConfirmationModal } from '../actions/UIActions';

export class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  renderTitle() {
    return (
      <Row>
        <Col lg={12} md={12}>
          {this.props.title}
        </Col>
      </Row>
    );
  }
  renderMessage() {
    const { message, message2 } = this.props;
    if (message2) {
      return (
        <div>
          <p>{message}</p>
          <p>{message2}</p>
        </div>
      );
    }
    return <p>{message}</p>;
  }
  render() {
    const { visible, hideConfirmationModal, confirm } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <Modal isOpen={true} toggle={hideConfirmationModal} size="md">
        <ModalHeader toggle={hideConfirmationModal}>
          {this.renderTitle()}
        </ModalHeader>
        <ModalBody>{this.renderMessage()}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={hideConfirmationModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={confirm}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  message: PropTypes.string.isRequired,
  message2: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  hideConfirmationModal: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    visible: state.ui.confirmationModalVisible || false,
  };
};

export default connect(mapStateToProps, { hideConfirmationModal })(
  ConfirmationModal
);
