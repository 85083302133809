export const formatNumber = (number, decimals = 0) => {
  if (number || number === 0) {
    return parseFloat(number)
      .toFixed(decimals)
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
  }
  return '';
};

export const formatCurrency = value => {
  const formattedValue = formatNumber(value);
  if (formattedValue) {
    return '$' + formattedValue;
  }
  return formattedValue;
};

export const columnNumberFormatter = props => {
  const number = props.value;
  return formatNumber(number);
};

export const columnCurrencyFormatter = props => {
  const value = props.value;
  return formatCurrency(value);
};

export const columnNumberFilterValueGetter = props => {
  const number = props.data[props.colDef.field];
  if (number) {
    return parseFloat(number);
  }
  return null;
};

export const numberColumn = {
  valueFormatter: columnNumberFormatter,
  filterValueGetter: columnNumberFilterValueGetter,
  filter: 'agNumberColumnFilter',
  width: 160,
};

export const currencyColumn = {
  valueFormatter: columnCurrencyFormatter,
  filterValueGetter: columnNumberFilterValueGetter,
  filter: 'agNumberColumnFilter',
  width: 160,
  cellStyle: { textAlign: 'right' },
};
