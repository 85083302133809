import _ from 'lodash';

import OktaService from '../../services/OktaService';
import * as types from './types';
import { withLoading } from '../../actions/CommonActions';

export const changePassword = withLoading((oldPassword, newPassword) => {
  return (dispatch, getState) => {
    const {
      okta: {
        user: { sub: userId },
        accessToken,
      },
    } = getState();
    dispatch({ type: types.CHANGE_PASSWORD_STARTED });
    return OktaService()
      .changePassword(accessToken, userId, oldPassword, newPassword)
      .then(() => dispatch({ type: types.CHANGE_PASSWORD_SUCCEED }))
      .catch(e => {
        const reason =
          (_.get(e, 'response.status') === 403 &&
            _.get(e, 'response.data.status')) ||
          'Error changing password';
        dispatch({ type: types.CHANGE_PASSWORD_FAILED, reason });
      });
  };
});

export const resetChangePassword = () => ({
  type: types.CHANGE_PASSWORD_RESET,
});
