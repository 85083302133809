import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import _ from 'lodash';

import Constants from '../../../services/Constants';
import BaseHeader from './BaseHeader';
import OpenManagerSummary from './OpenManagerSummary';
import { Storages } from '../../../services/LocalStorageService';
import Disclaimer from '../../Disclaimer';

export class FPLStrategyHeader extends React.Component {
  renderDisclaimer = () => {
    const disclaimer = [
      'Past performance is no guarantee of future results.',
      'All lists of products are subject to change without notice.',
      'The lists generally do not include funds that are closed to new investment.',
      'Not all products rated 1 or 2 are appropriate for all investors or suitable for all investment programs.',
      'Products are often offered through multiple vehicles. Investors should select a vehicle carefully and obtain advice from a tax advisor or lawyer.',
      'FPLs or other lists of products are confidential and are intended for NEPC client use only.',
    ];
    const disclaimerLIs = _.map(disclaimer, (text, index) => (
      <li key={index}>{text}</li>
    ));
    return (
      <Disclaimer
        name={Storages.SHOW_DISCLAIMER_SETTING}
        printable={disclaimer}>
        <ul className="fpl-list-style">{disclaimerLIs}</ul>
      </Disclaimer>
    );
  };

  renderWhatIsLinks(strategy) {
    let isOwnedLed = '---';
    if (strategy.diverseLed !== strategy.diverseOwned) {
      if (strategy.diverseOwned === 'No') {
        isOwnedLed = 'Led';
      } else {
        isOwnedLed = 'Owned';
      }
    }

    const drawWhatIs = (field, action) => {
      const value =
        field === 'ownedLed' ? isOwnedLed : _.get(strategy, field, '') || '';
      return (
        <span>
          {value}
          <Button color="link" className="whatIsLink" onClick={action}>
            <i className="fa fa-info-circle"></i>
          </Button>
        </span>
      );
    };
    const dueDiligenceRating = drawWhatIs(
      'dueDiligenceRating',
      this.props.showWhatIsDueDiligenceRating
    );
    const strategyDueDiligenceStatus = drawWhatIs(
      'strategyDueDiligenceStatus',
      this.props.showWhatIsDueDiligenceStatus
    );
    const betaGroup = drawWhatIs('betaGroup', this.props.showWhatAreBetaGroups);
    const ownedLed = drawWhatIs('ownedLed', this.props.showWhatIsOwnedLed);
    const esgRating = drawWhatIs('esgRating', this.props.showWhatIsESGRating);
    const deiRating = drawWhatIs('deiRating', this.props.showWhatIsDEIRating);
    return {
      dueDiligenceRating,
      strategyDueDiligenceStatus,
      betaGroup,
      ownedLed,
      esgRating,
      deiRating,
    };
  }

  renderManagerLink(strategy, viewParams) {
    const context = {
      viewParams,
    };
    return (
      <span className="managerLink">
        <OpenManagerSummary
          data={strategy}
          value={strategy.investmentFirmName}
          context={context}
        />
      </span>
    );
  }

  render() {
    const headers = [
      { title: 'Strategy Name', field: 'investmentStrategyName' },
      { title: 'Current DD Status', field: 'strategyDueDiligenceStatus' },
      { title: 'Investment Firm', field: 'investmentFirmName' },
      { title: 'Current DD Rating', field: 'dueDiligenceRating' },
      { title: 'Asset Group', field: 'betaGroup' },
      { title: '', field: '' },
      { title: '', field: '' },
      { title: 'ESG Rating', field: 'esgRating' },
      { title: '', field: '' },
      { title: 'DEI Rating', field: 'deiRating' },
      { title: '', field: '' },
      { title: 'Diverse Owned/Led', field: 'ownedLed' },
    ];
    const { strategy, viewParams } = this.props;
    if (!strategy) return null;

    const {
      dueDiligenceRating,
      strategyDueDiligenceStatus,
      betaGroup,
      esgRating,
      deiRating,
      ownedLed,
    } = this.renderWhatIsLinks(strategy);
    const { documentView } = viewParams;
    const investmentFirmName = this.renderManagerLink(strategy, viewParams);
    const data = {
      ...strategy,
      dueDiligenceRating,
      strategyDueDiligenceStatus,
      investmentFirmName,
      betaGroup,
      esgRating,
      ownedLed,
      deiRating,
    };
    let documentType = '';
    if (documentView === Constants.FPL_NEPC_NARRATIVES) {
      documentType = 'NEPC Opinion';
    } else if (documentView === Constants.FPL_EVEST_NARRATIVES) {
      documentType = 'eVestment Narratives';
    } else if (documentView === Constants.FPL_ESG) {
      documentType = 'ESG Opinion';
    } else if (documentView === Constants.FPL_DEI) {
      documentType = 'DEI Opinion';
    }
    return (
      <BaseHeader
        headers={headers}
        data={data}
        disclaimer={this.renderDisclaimer()}
        title={strategy.investmentStrategyName}
        subtitle="Strategy Information"
        documentType={documentType}
      />
    );
  }
}

FPLStrategyHeader.propTypes = {
  viewParams: PropTypes.object,
  strategy: PropTypes.object,
  investmentFirmTab: PropTypes.bool,
  showWhatIsDueDiligenceRating: PropTypes.func.isRequired,
  showWhatIsDueDiligenceStatus: PropTypes.func.isRequired,
  showWhatAreBetaGroups: PropTypes.func.isRequired,
  showWhatIsOwnedLed: PropTypes.func.isRequired,
  showWhatIsESGRating: PropTypes.func,
  showWhatIsDEIRating: PropTypes.func,
};

export default FPLStrategyHeader;
