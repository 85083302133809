import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Constants from '../../services/Constants';

class EmployeeDDEventsRouter extends Component {
  onManagerDDEvents = ({ match }, documentView, url) => {
    const url1 = `${url}/dds/${match.params.investmentFirm}/investmentFirm`;
    const vp = {
      investmentFirm: match.params.investmentFirm,
      documentView,
      managerUrl: url1,
      selectedTab: Constants.CR_DD_EVENTS_VIEW,
    };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  onManagerDDEventsStrategy = ({ match }, documentView, url) => {
    const url1 = `${url}/dds/${match.params.investmentFirm}/investmentFirm`;
    const vp = {
      investmentFirm: match.params.investmentFirm,
      strategyName: match.params.strategyName,
      documentView,
      managerUrl: url1,
      selectedTab: Constants.CR_DD_EVENTS_VIEW,
    };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  onConsultingResources = selectedTab => {
    const vp = { investmentFirm: null, selectedTab: selectedTab };
    return this.props.withMain(Constants.CONSULTING_RESOURCES_VIEW, vp);
  };

  resetSelectedPlan = () => {
    const { ui } = this.props;
    if (ui.selectedPlan) {
      ui.selectedPlan = null;
      this.setState({ ui });
    }
  };

  render() {
    this.resetSelectedPlan();
    const { match } = this.props;

    const viewParams = {
      baseURL: match.url,
    };

    return (
      <Switch>
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/activities`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_MANAGER_ACTIVITIES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/meetingnotes`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_RESEARCH,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/dd`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_DD_EVENTS,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/evest`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_EVEST_NARRATIVES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/dei-opinion`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_DEI,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName/esg-opinion`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_ESG,
              viewParams.baseURL
            )
          }
        />

        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategy/:strategyName`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.FPL_MANAGER_ACTIVITIES,
              viewParams.baseURL
            )
          }
        />
        {/* <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/dd`}
          render={props =>
            this.onManagerDDEvents(
              props,
              Constants.PLAN_MANAGER_DD_EVENTS,
              viewParams.baseURL
            )
          }
        /> */}
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/evest`}
          render={props =>
            this.onManagerDDEvents(
              props,
              Constants.PLAN_MANAGER_EVEST_NARRATIVES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/strategies`}
          render={props =>
            this.onManagerDDEvents(
              props,
              Constants.PLAN_MANAGER_STRATEGIES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm/activities`}
          render={props =>
            this.onManagerDDEventsStrategy(
              props,
              Constants.EMPLOYEE_MANAGER_ACTIVITIES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/:investmentFirm/investmentFirm`}
          render={props =>
            this.onManagerDDEvents(
              props,
              Constants.PLAN_MANAGER_STRATEGIES,
              viewParams.baseURL
            )
          }
        />
        <Route
          path={`${match.url}/dds/`}
          render={props =>
            this.onConsultingResources(Constants.CR_DD_EVENTS_VIEW)
          }
        />
      </Switch>
    );
  }
}

EmployeeDDEventsRouter.propTypes = {
  match: PropTypes.object.isRequired,
  withMain: PropTypes.func,
  ui: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    ui: state.ui,
  };
};

export default connect(mapStateToProps, {})(EmployeeDDEventsRouter);
