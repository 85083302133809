import moment from 'moment';

export const formatDate = date => {
  if (date) {
    return moment.utc(date).format('L');
  }
  return '';
};

export const columnDateFormatter = props => {
  const date = props.value;
  return formatDate(date);
};

export const columnDateFilterValueGetter = props => {
  const date = props.data[props.colDef.field];
  if (date) {
    const normalDate = moment.utc(date).startOf('day');
    const year = normalDate.get('year');
    const month = normalDate.get('month');
    const day = normalDate.get('date');
    return new Date(year, month, day);
  }
  return null;
};

/*
  Compare the given date with actual date
  - returns 1 for given date null or greather than today
  - returns -1 for given date less than today
  - returns 0 for equal dates
*/
export const compareWithToday = date1 => {
  let dateToday = new Date();
  dateToday.setHours(0, 0, 0, 0);
  dateToday = moment(dateToday).format('YYYY-MM-DDTHH:mm:ss.SSS');
  return columnDateFormatterComparator(date1, dateToday) > -1;
};

export const columnDateFormatterComparator = (date1, date2) => {
  // leave the null values as first in the list
  if (!date1) {
    return 1;
  }
  if (!date2) {
    return -1;
  }
  if (
    moment.utc(date1, 'YYYY-MM-DD').isAfter(moment.utc(date2, 'YYYY-MM-DD'))
  ) {
    return 1;
  } else if (
    moment.utc(date1, 'YYYY-MM-DD').isBefore(moment.utc(date2, 'YYYY-MM-DD'))
  ) {
    return -1;
  }
  return 0;
};

export const dateColumn = {
  valueFormatter: columnDateFormatter,
  filterValueGetter: columnDateFilterValueGetter,
  filter: 'agDateColumnFilter',
  width: 145,
  comparator: columnDateFormatterComparator,
  suppressSizeToFit: true,
};
