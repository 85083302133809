import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { loadPlans, openDocument, checkDocument } from '../actions';

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloaded: false,
    };
  }

  downloadDocumentDirectByLink = () => {
    const { openDocument, document } = this.props;
    if (document) {
      return openDocument(document);
    }
  };

  retrieveDocumentMeta = (planIds, planTypes) => {
    const { email, urlParams, checkDocument } = this.props;
    checkDocument(planIds, planTypes, email, urlParams.fileId);
  };

  retrieveUserPlans = () => {
    const { email, isEmployee, loadPlans } = this.props;
    return loadPlans(email, isEmployee);
  };

  render() {
    const { plans, email, isEmployee } = this.props;
    if (email && isEmployee !== undefined) {
      this.retrieveUserPlans();
    }
    if (plans) {
      const planIds = _.map(plans, 'planIdGuid');
      const planTypes = _.map(plans, 'planType');

      this.retrieveDocumentMeta(_.compact(planIds), _.compact(planTypes));
      this.downloadDocumentDirectByLink();
    }
    return null;
  }
}

Downloads.propTypes = {
  email: PropTypes.string,
  loadPlans: PropTypes.func.isRequired,
  checkDocument: PropTypes.func.isRequired,
  plans: PropTypes.array,
  document: PropTypes.array,
  openDocument: PropTypes.func,
  isEmployee: PropTypes.bool,
  urlParams: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    plans: state.resources.plans.data,
    document: state.resources.document_meta.data,
    isEmployee: state.okta.isEmployee,
    email: _.get(state, 'okta.user.email'),
  };
};

export default connect(mapStateToProps, {
  loadPlans,
  openDocument,
  checkDocument,
})(Downloads);
