import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';

import { loadPlanTeam, loadTeamPictures } from '../../../actions';
import PlanTeam from './PlanTeam';

export class PlanDetailsTeam extends Component {
  render() {
    const {
      selectedPlan,
      team,
      teamPictures,
      loadPlanTeam,
      loadTeamPictures,
    } = this.props;
    if (selectedPlan) {
      loadPlanTeam(selectedPlan.planId);
      const emails = _.map(team, t => t.email);
      if (emails.length > 0) {
        loadTeamPictures(emails);
      }
      return (
        <div className="planDetails">
          <Row>
            <Col lg={12} md={12}>
              <PlanTeam team={team} teamPictures={teamPictures} />
            </Col>
          </Row>
        </div>
      );
    }
    return null;
  }
}

PlanDetailsTeam.propTypes = {
  selectedPlan: PropTypes.object,
  teamPictures: PropTypes.object,
  team: PropTypes.array,
  loadPlanTeam: PropTypes.func.isRequired,
  loadTeamPictures: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.ui.selectedPlan,
    team: state.resources.plan_team.data || [],
    teamPictures: state.resources.team_pictures.data || {},
  };
};

export default connect(mapStateToProps, { loadPlanTeam, loadTeamPictures })(
  PlanDetailsTeam
);
