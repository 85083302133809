import _ from 'lodash';
import moment from 'moment';

import OktaService from '../services/OktaService';
import DenodoService from '../services/DenodoService';
import { Constants, Events } from '../services/Constants';
import * as types from './Types';
import { addEvent } from './EventActions';
import { DEFAULT_REGISTERING } from '../reducers/OktaReducer';
import { withLoading } from './CommonActions';
import {
  Storages,
  getFromLocalStorage,
  addToLocalStorage,
  removeFromLocalStorage,
} from '../services/LocalStorageService';
import { updateMaintenanceOption } from '../components/messages/actions';
import { shouldReadTermsAndConditions } from '../components/terms/actions';
import { loadNotificationFilterResource } from '../actions';

export const getOktaSettings = () => {
  return dispatch => {
    return OktaService()
      .getSettings()
      .then(response => {
        const {
          clientId,
          authParams,
          baseUrl,
          maintenanceModeUrl,
        } = response.data;
        dispatch({
          type: types.GET_OKTA_SETTINGS_SUCCESS,
          clientId,
          issuer: authParams.issuer,
          baseUrl,
          maintenanceMode: maintenanceModeUrl,
        });
        if (maintenanceModeUrl) {
          dispatch(updateMaintenanceOption('enabledMaintenance'));
        } else {
          dispatch(updateMaintenanceOption('disabledMaintenance'));
        }
      });
  };
};

export const registerCaptchaReseted = () => {
  return (dispatch, getState) => {
    const {
      okta: {
        registering: { user },
      },
    } = getState();
    dispatch(
      _registerUpdate(user, {
        wrongCaptcha: false,
      })
    );
  };
};

const _registerUpdate = (user, status) => {
  return dispatch => {
    const registering = { ...DEFAULT_REGISTERING, ...status, user };
    const eventType = registering.success
      ? types.REGISTER_USER_SUCCESS
      : types.REGISTER_USER_FAILED;
    dispatch({ type: eventType, registering });
  };
};

const _createUser = user => {
  return dispatch => {
    return OktaService()
      .register(user)
      .then(response => {
        if (response.status === 200) {
          dispatch(
            _registerUpdate(user, {
              success: true,
            })
          );
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          // Unauthorized 401 = invalid captcha
          dispatch(
            _registerUpdate(user, {
              wrongCaptcha: true,
            })
          );
        } else {
          dispatch(
            _registerUpdate(user, {
              error: true,
            })
          );
        }
      });
  };
};

const _lookupUserOnOkta = user => {
  return dispatch => {
    return OktaService()
      .loginExists(user.email)
      .then(response => {
        if (response.status === 200) {
          dispatch(
            _registerUpdate(user, {
              emailAlreadyRegistered: true,
            })
          );
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 404) {
          // Email already registered, common!!
          return dispatch(_createUser(user));
        } else {
          dispatch(
            _registerUpdate(user, {
              error: true,
            })
          );
        }
      });
  };
};

const _validateCRMEmail = user => {
  return dispatch => {
    return DenodoService()
      .authorize(user.email)
      .then(response => {
        if (response.status === 200) {
          return dispatch(_lookupUserOnOkta(user));
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 406) {
          // Not Acceptable = user not in CRM
          dispatch(
            _registerUpdate(user, {
              emailNoClientFound: true,
            })
          );
        } else {
          dispatch(
            _registerUpdate(user, {
              error: true,
            })
          );
        }
      });
  };
};

export const _registerUser = _validateCRMEmail;
export const registerUser = withLoading(_registerUser);

export const saveAuthentication = withLoading((user, accessToken) => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken: currentAccessToken, user: currentUser },
    } = getState();
    if (currentAccessToken === accessToken && currentUser === user) {
      return;
    }
    const email = ((user && user.email) || '').toLowerCase();
    dispatch({
      type: types.SAVE_OKTA_USER_AND_TOKEN,
      user,
      email,
      accessToken,
    });
    return OktaService()
      .loadRoles(user.sub)
      .then(({ data: { groups: roles } }) => {
        const isEmployee = _.includes(roles, Constants.EMPLOYEE_ROLE);
        const isClient = !isEmployee;
        const isRolesLoaded = !!roles;
        dispatch({
          type: types.SAVE_OKTA_USER_AND_TOKEN,
          user,
          email,
          isEmployee,
          isClient,
          isRolesLoaded,
          accessToken,
          roles,
        });
        dispatch(shouldReadTermsAndConditions());
        const last = moment(getFromLocalStorage(Storages.LOGGEDIN_EVENT));
        if (
          !last ||
          !last.isValid() ||
          moment().diff(last) > Constants.LOGIN_EVENT_TTL
        ) {
          addToLocalStorage(Storages.LOGGEDIN_EVENT, new Date().toISOString());
          dispatch(addEvent(Events.LOGIN));
        }
      });
  };
});

export const updateNotificationsFilter = notifications => {
  return dispatch => {
    return dispatch(OktaService().updateNotifications(notifications));
  };
};

export const loadNotificationFilter = () => dispatch =>
  dispatch(loadNotificationFilterResource());

export const logoutClearRedirects = () => {
  removeFromLocalStorage(Storages.LOGGEDIN_EVENT);
  removeFromLocalStorage(Storages.LOGIN_REDIRECT_URL);
  removeFromLocalStorage(Storages.LOGIN_REDIRECT_EXPIRY);
  removeFromLocalStorage(Storages.OKTA_LOGIN_REDIRECT);
};

export const logout = (value = true) => {
  return dispatch => {
    logoutClearRedirects();
    dispatch({
      type: types.LOGOUT,
      value,
    });
  };
};
