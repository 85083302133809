import React from 'react';

const NoPlans = () => {
  return (
    <div className="noPlans">
      Currently, either you are not entitled to any Portfolio or there are no
      Portfolios available. Please contact your consultant.
    </div>
  );
};

export default NoPlans;
