import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Constants from '../../services/Constants';
import { loadFPLNepcOpinion } from '../../actions';
import viewParams from './ViewParams';

export class StrategyRouter extends Component {
  onStrategyEsg = (props, vps) => {
    return this.props.onDocuments(Constants.FPL_ESG, vps);
  };

  onStrategyDei = (props, vps) => {
    return this.props.onDocuments(Constants.FPL_DEI, vps);
  };

  onStrategyNepc = (props, vps) => {
    return this.props.onDocuments(Constants.FPL_NEPC_NARRATIVES, vps);
  };

  onStrategyManagerActivities = (props, vps) => {
    return this.props.onDocuments(Constants.FPL_MANAGER_ACTIVITIES, vps);
  };

  onStrategyEVest = (props, vps) => {
    return this.props.onDocuments(Constants.FPL_EVEST_NARRATIVES, vps);
  };

  render() {
    const { match, viewParams, strategy } = this.props;
    const newViewParams = {
      ...viewParams,
      fplStrategy: strategy,
      fplStrategyUrl: match.url,
    };
    this.props.loadFPLNepcOpinion(strategy);
    const lastUpdatedOn = _.get(this.props, 'nepcStrategy.lastUpdatedOn');
    return (
      <Switch>
        <Route
          path={`${match.url}/esg-opinion`}
          render={props => this.onStrategyEsg(props, newViewParams)}
        />
        <Route
          path={`${match.url}/dei-opinion`}
          render={props => this.onStrategyDei(props, newViewParams)}
        />
        <Route
          path={`${match.url}/nepc`}
          render={props => this.onStrategyNepc(props, newViewParams)}
        />
        <Route
          path={`${match.url}/activities`}
          render={props =>
            this.onStrategyManagerActivities(props, newViewParams)
          }
        />
        <Route
          path={`${match.url}/evest`}
          render={props => this.onStrategyEVest(props, newViewParams)}
        />
        <Route
          path={`${match.url}`}
          render={props =>
            lastUpdatedOn
              ? this.onStrategyNepc(props, newViewParams)
              : this.onStrategyManagerActivities(props, newViewParams)
          }
        />
      </Switch>
    );
  }
}

StrategyRouter.propTypes = {
  match: PropTypes.object.isRequired,
  onDocuments: PropTypes.func.isRequired,
  viewParams,

  strategy: PropTypes.object,
  nepcStrategy: PropTypes.object,

  loadFPLNepcOpinion: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    nepcStrategy: state.resources.fpl_nepc_opinion.data || {},
  };
};

export default connect(mapStateToProps, { loadFPLNepcOpinion })(StrategyRouter);
