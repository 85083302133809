import moment from 'moment';

import AccessEnabledRenderer from './AccessEnabledRenderer';

const dateValueFormatter = props => {
  if (!props.value) {
    return 'never';
  }
  const date = props.value;
  return moment(date).format('LLL');
};

export const AdminColumnDefs = [
  {
    field: 'fullName',
    headerName: 'Name',
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    field: 'lastLoginDate',
    headerName: 'Last login',
    valueFormatter: dateValueFormatter,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    field: 'accessEnabledEdit',
    headerName: 'Is Enabled?',
    cellRenderer: AccessEnabledRenderer,
    width: 120,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export default AdminColumnDefs;
