import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import OpenDocumentLink from '../documents/OpenDocumentLink';
import { formatDate } from '../../../services/DateService';

const RecentDocument = ({ doc }) => {
  const { publishedDate } = doc;
  const formattedCreatedDate = formatDate(publishedDate);

  return (
    <div className="note">
      <Row>
        <Col>
          <OpenDocumentLink data={doc} />
        </Col>
      </Row>

      <Row>
        <Col lg="12" className="recentDocumentPublished">
          Published Date: {formattedCreatedDate}
        </Col>
      </Row>
    </div>
  );
};

RecentDocument.propTypes = {
  doc: PropTypes.object.isRequired,
};

export default RecentDocument;
