import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import {
  Form,
  FormGroup,
  Label,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import Constants from '../../services/Constants';

export class TermsEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terms: '',
      activeDate: null,
    };
  }

  componentDidMount() {
    this.setState({
      terms: this.props.terms.terms,
      activeDate: this.props.terms.activeDate,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      terms: nextProps.terms.terms,
      activeDate: nextProps.terms.activeDate,
    });
  }

  onTerms = terms => {
    this.setState({ terms });
  };

  onActiveDate = activeDate => {
    this.setState({ activeDate });
  };

  onSave = () => {
    this.props.save(
      {
        ...this.props.terms,
        ...this.state,
      },
      () => {
        this.props.edit(null);
      }
    );
  };

  onDuplicate = () => {
    this.props.edit({
      ...this.props.terms,
      ...this.state,
      id: null,
      activeDate: null,
      createdOn: null,
      createdBy: null,
    });
  };

  onCancel = () => {
    this.props.edit(null);
  };

  renderSave = () => {
    if (!this.props.draft) {
      return (
        <Button color="info" className="ml-1" onClick={this.onDuplicate}>
          Duplicate
        </Button>
      );
    }
    const cannotSave =
      this.state.terms.length < 5 ||
      moment
        .utc(this.state.activeDate, 'YYYY-MM-DD')
        .isSame(moment.utc(this.props.latest.activeDate, 'YYYY-MM-DD'), 'day');
    return (
      <Button
        color="primary"
        className="ml-1"
        onClick={this.onSave}
        disabled={cannotSave}>
        Save
      </Button>
    );
  };

  render() {
    const readOnly = !this.props.draft;
    const readOnlyClass = readOnly ? 'ql-editor-readonly' : '';
    return (
      <Container className="mt-3" fluid>
        <Row>
          <Col md={12}>
            <Form className="admin">
              <FormGroup row>
                <Label md={2}>Activate date:</Label>
                <Col md={10}>
                  <DayPickerInput
                    value={this.state.activeDate || ''}
                    onDayChange={this.onActiveDate}
                    inputProps={{ className: 'form-control' }}
                    readOnly={readOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2}>Terms:</Label>
                <Col md={10}>
                  <ReactQuill
                    className={readOnlyClass}
                    modules={Constants.QUILL_TOOLBAR_MODULE}
                    value={this.state.terms}
                    onChange={this.onTerms}
                    readOnly={readOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                {this.renderSave()}
                <Button
                  color="secondary"
                  className="ml-4"
                  onClick={this.onCancel}>
                  Go back
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

TermsEditor.propTypes = {
  draft: PropTypes.bool.isRequired,
  terms: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  latest: PropTypes.object.isRequired,
};

export default TermsEditor;
