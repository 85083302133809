import _ from 'lodash';

export const renderTableGrid = (
  columnDefs,
  viewParams,
  unSortedRows,
  rowsPerPage,
  sortModel,
  documentsView = ''
) => {
  const sortColumns = _.map(_.map(sortModel, 'colId'), colId => row => {
    const value = row[colId];
    if (typeof value === 'string') {
      return value.toLowerCase();
    }
    return value;
  });
  const sortOrders = _.map(sortModel, 'sort');

  const rows = _.orderBy(unSortedRows, sortColumns, sortOrders);

  if (rows.length > rowsPerPage.firstPage) {
    const postPagesHeader =
      documentsView === '' ? '' : documentsView + ' (continued)';
    return _.reduce(
      _.chunk(
        rows.slice(rowsPerPage.firstPage, rows.length),
        rowsPerPage.postPages
      ),
      (tables, chunk) => {
        return (
          tables +
          renderTableGrid(
            columnDefs,
            viewParams,
            chunk,
            rowsPerPage.postPages,
            sortModel,
            postPagesHeader
          )
        );
      },
      renderTableGrid(
        columnDefs,
        viewParams,
        rows.slice(0, rowsPerPage.firstPage),
        rowsPerPage.firstPage,
        sortModel,
        documentsView
      )
    );
  }
  const colDefs = _.filter(columnDefs, colDef => {
    const { hide } = colDef;
    if (hide && typeof hide === 'function') {
      if (hide(viewParams)) {
        return false;
      }
    }
    return true;
  });
  const headers = _.reduce(
    colDefs,
    (trs, colDef) => {
      return `${trs}<th class="${colDef.field}">${colDef.headerName}</th>`;
    },
    ''
  );
  const grid = _.reduce(
    rows,
    (table, manager) => {
      const row = _.reduce(
        colDefs,
        (tds, colDef) => {
          const value = manager[colDef.field] || '';
          const formatter = colDef.valueFormatter || (o => o.value);
          const formatted = formatter({ value });
          return `${tds}<td class="${colDef.field}"><div class="gridCell">${formatted}</div></td>`;
        },
        ''
      );
      return `${table}<tr>${row}</tr>`;
    },
    headers
  );

  return `<h2 class="documentViewTitle">${documentsView}</h2><div class="gridArea"><table>${grid}</table></div>`;
};

export default renderTableGrid;
