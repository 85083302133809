import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadPlans, loadLetterPlans } from '../../actions';
import NoPlans from './NoPlans';
import EmployeePlans from './employee/EmployeePlans';
import ClientPlans from './ClientPlans';

export class Plans extends Component {
  render() {
    const {
      plans,
      email,
      isEmployee,
      plansByLetter,
      loadLetterPlans,
      loadPlans,
    } = this.props;
    if (email && isEmployee !== undefined) {
      loadPlans(email, isEmployee);
    }
    if (plans === undefined) {
      return null;
    }

    if (!isEmployee && plans.length === 0) {
      return <NoPlans />;
    }

    const userPlans = !isEmployee ? (
      <ClientPlans plans={plans} user={this.props.user} />
    ) : (
      <EmployeePlans
        plans={plans}
        plansByLetter={plansByLetter.data}
        requestPlansForLetter={loadLetterPlans}
        emptyLetter={Boolean(
          plansByLetter.isLoaded &&
            !plansByLetter.data &&
            !plansByLetter.loading
        )}
      />
    );
    return <div className="plans">{userPlans}</div>;
  }
}

Plans.propTypes = {
  plans: PropTypes.array,
  getClientPlans: PropTypes.func,
  loadPlans: PropTypes.func.isRequired,
  loadLetterPlans: PropTypes.func.isRequired,
  user: PropTypes.object,
  email: PropTypes.string,
  isEmployee: PropTypes.bool,
  plansByLetter: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.okta.user,
    email: _.get(state, 'okta.user.email'),
    plans: state.resources.plans.data,
    plansByLetter: state.resources.all_plans || {},
    isEmployee: state.okta.isEmployee,
  };
};

export default connect(mapStateToProps, {
  loadPlans,
  loadLetterPlans,
})(Plans);
