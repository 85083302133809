import axios from 'axios';

import Constants from './Constants';

const HubSpotService = () => {
  const HUBSPOT_URL = Constants.HUBSPOT.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getBlogsPage = accessToken =>
    axios.get(`${HUBSPOT_URL}/blogPosts`, _headers(accessToken));

  const getVersion = () =>
    axios.get(`${HUBSPOT_URL}/maintenance/clienthubVersion?${Math.random()}`);

  const sendNotificationTest = notification =>
    axios.post(`${HUBSPOT_URL}/notification/test`, {
      ...notification,
    });

  return {
    getBlogsPage,
    getVersion,
    sendNotificationTest,
  };
};

export default HubSpotService;
