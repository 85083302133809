import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Media } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import viewParams from './router/ViewParams';
import LogoImg from '../assets/logo.jpg';
import SquareLogo from '../assets/squareLogo.jpg';
import MainMenu from './MainMenu';

export class Header extends Component {
  render() {
    const { user, viewParams } = this.props;
    const welcomeMessage =
      (user && `Welcome, ${user.given_name} ${user.family_name}`) || '';

    return (
      <Container className="header" fluid>
        <div className="headerContent">
          <Media>
            <Media left href="#">
              <Media object src={LogoImg} alt="NEPC Logo" />
            </Media>
            <Media body>
              <Media heading>{welcomeMessage}</Media>
            </Media>
            <Media
              right
              object
              height="75px"
              src={SquareLogo}
              alt="NEPC Logo Square"
            />
          </Media>
        </div>
        <div className="menuContent">
          <MainMenu viewParams={viewParams} />
        </div>
      </Container>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object,
  viewParams,
};

const mapStateToProps = state => {
  return {
    user: state.okta.user,
  };
};

export default withRouter(connect(mapStateToProps, {})(Header));
