import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import _ from 'lodash';

import { redirect } from '../../actions';
import { loadLatest, acceptTermsAndConditions } from './actions';

export class TermsAndConditions extends Component {
  componentDidMount() {
    if (!this.props.terms) {
      this.props.loadLatest();
    }
  }

  handleAccept = () => {
    this.props.acceptTermsAndConditions();
  };

  handleDecline = () => {
    this.props.redirect('/logout');
  };

  renderControls = () => {
    if (
      this.props.accepted ||
      this.props.loading ||
      !this.props.terms ||
      !this.props.user
    ) {
      return null;
    }

    return (
      <div>
        <p className="text-justify">
          I have read and accept NEPC’s Terms of Use written above.
        </p>

        <p>
          <Button onClick={this.handleAccept} style={{ marginRight: '1em' }}>
            Accept
          </Button>
          <Button onClick={this.handleDecline}>Decline</Button>
        </p>
      </div>
    );
  };

  render() {
    const html = this.props.terms;
    return (
      <div className="review">
        <div className="quill ql-snow">
          <span
            className="ql-editor ql-container"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        {this.renderControls()}
        <p className="text-justify">
          Please contact your NEPC Consultant should you have any questions or
          concerns.
        </p>
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  redirect: PropTypes.func.isRequired,
  loadLatest: PropTypes.func.isRequired,
  acceptTermsAndConditions: PropTypes.func.isRequired,
  accepted: PropTypes.bool,

  terms: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const latest = state.terms.latest;
  const { loading } = latest;
  const terms = _.get(latest, 'data.terms');
  const user = state.okta.user;
  return {
    accepted: state.terms.accepted,
    terms,
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  redirect,
  acceptTermsAndConditions,
  loadLatest,
})(TermsAndConditions);
