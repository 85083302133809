import _ from 'lodash';
import { NotificationManager } from 'react-notifications';

import * as types from './Types';
import { Events } from '../services/Constants';
import { addEvent } from './EventActions';
import CrmService from '../services/CrmService';
import { withLoading } from './CommonActions';
import { loadPlanAdminUserListResource } from './Resources';

const updateUserActivation = (dispatch, accessToken, user, status) => {
  return CrmService()
    .toggleUser(accessToken, user, status)
    .then(() => {
      let nepcEvent;
      let reduxEvent;
      if (status) {
        nepcEvent = Events.ACTIVATE_USER;
        reduxEvent = types.ACTIVATE_USER_SUCCESS;
      } else {
        nepcEvent = Events.DEACTIVATE_USER;
        reduxEvent = types.DEACTIVATE_USER_SUCCESS;
      }
      dispatch(addEvent(nepcEvent, user.email));
      dispatch({ type: reduxEvent, user });
    });
};

export const savePlanUserActivationsAndLoad = withLoading((users, planId) => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    dispatch({ type: types.USER_ACTIVATIONS_STARTED });
    const affectedUsers = _.filter(
      users,
      user => user.accessEnabledEdit !== user.accessEnabled
    );
    const tasks = _.map(affectedUsers, user => {
      const task = updateUserActivation(
        dispatch,
        accessToken,
        user,
        user.accessEnabledEdit
      );
      return task;
    });
    return Promise.all(tasks)
      .then(() => {
        const newUsers = _.map(users, user => {
          return { ...user, accessEnabled: user.accessEnabledEdit };
        });
        dispatch({ type: types.USER_ACTIVATIONS_SUCCESS, users: newUsers });
        dispatch(loadPlanAdminUserListResource(planId, true)).then(() => {
          NotificationManager.success(
            'Access settings updated',
            'Access Admin'
          );
        });
      })
      .catch(() => {
        const newUsers = _.map(users, user => {
          return { ...user, accessEnabledEdit: user.accessEnabled };
        });
        dispatch({ type: types.USER_ACTIVATIONS_FAILED, users: newUsers });
        NotificationManager.error(
          'Access settings not updated, please try again',
          'Access Admin'
        );
      });
  };
});

export const loadPlanAdminUserList = (planId, skipCache = false) => {
  return loadPlanAdminUserListResource(planId, skipCache);
};
