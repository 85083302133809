import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';

import { Events } from '../../../services/Constants';
import {
  addEvent,
  redirectPlan,
  loadPlanAdminUserListResource,
} from '../../../actions';
import LoadingComponentOverlay from '../../LoadingSpinner';

const renderAccessEnabled = props => (props.value ? 'Yes' : 'No');

const columnDefs = [
  {
    field: 'fullName',
    headerName: 'Name',
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    field: 'accessEnabled',
    headerName: 'Is Enabled?',
    cellRenderer: renderAccessEnabled,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export class Review extends Component {
  handleReviewNextTime = () => {
    this.props.redirectPlan('/client');
  };

  handleReviewNow = () => {
    this.props.redirectPlan('/admin');
  };

  handleReviewComplete = () => {
    this.props.addEvent(Events.REVIEW);
    this.props.redirectPlan('/client');
  };

  render() {
    if (this.props.planId) {
      this.props.loadPlanAdminUserListResource(this.props.planId);
    }
    return (
      <div className="review">
        <Row>
          <p>
            You are listed as the administrative user for this portfolio site.
            As such, you will be presented with the list below every 6 months in
            order to confirm that the correct people are entitled to access the
            system. If the list is correct, click{' '}
            <span className="font-weight-bold">Review Complete</span> and you
            will not be reminded again for 6 months. Click{' '}
            <span className="font-weight-bold">Review Now</span> to be taken
            directly to the Admin tab, where you can make changes to the
            entitlements. If prefer to review later, click{' '}
            <span className="font-weight-bold">Review Next Time</span> and will
            be reminded the next time you login.
          </p>
        </Row>
        <Row>
          <div className="userListGrid mt-3 mb-3 ag-theme-balham">
            <AgGridReact
              columnDefs={columnDefs}
              rowData={this.props.users}
              suppressDragLeaveHidesColumns={true}
              components={{ agLoadingOverlay: LoadingComponentOverlay }}
            />
          </div>
        </Row>
        <Row>
          <Col sm={{ size: 6, offset: 3 }} className="options">
            <Button onClick={this.handleReviewNextTime}>
              Review Next Time
            </Button>
            <Button onClick={this.handleReviewNow}>Review Now</Button>
            <Button onClick={this.handleReviewComplete}>Review Complete</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

Review.propTypes = {
  addEvent: PropTypes.func.isRequired,
  redirectPlan: PropTypes.func.isRequired,
  loadPlanAdminUserListResource: PropTypes.func.isRequired,
  users: PropTypes.array,
  planId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    users: state.resources.plan_admin_userlist.data || [],
    planId: _.get(state, 'ui.selectedPlan.planId') || '',
  };
};

export default connect(mapStateToProps, {
  addEvent,
  redirectPlan,
  loadPlanAdminUserListResource,
})(Review);
