import React, { Component } from 'react';
import {
  Row,
  Col,
  TabContent,
  TabPane,
  CustomInput,
  Nav,
  NavItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PlanDDEventModal from '../plans/documents/PlanDDEventModal';
import EmployeeManagerMeetingNotes from './EmployeeManagerMeetingNotes';
import EmployeeDDEvents from './EmployeeDDEvents';
import Constants from '../../services/Constants';
import {
  saveEmployeeActiveTab,
  changeEmployeeMeetingNotesSwitch,
  changeEmployeeDDEventsSwitch,
} from '../../actions';

export class ConsultingResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentFirm: false,
      allManagerMeetingNotes: this.props.meetingNotesAllManagerSwitch,
      allDDEvents: this.props.ddEventsAllManagerSwitch,
    };
  }

  showAllManagerMeetingNotes = e => {
    this.setState({ allManagerMeetingNotes: e.target.checked });
    this.props.changeEmployeeMeetingNotesSwitch(e.target.checked);
  };

  showAllDDEvents = e => {
    this.setState({ allDDEvents: e.target.checked });
    this.props.changeEmployeeDDEventsSwitch(e.target.checked);
  };

  renderTabs() {
    const { viewParams, saveEmployeeActiveTab, activeTab } = this.props;
    if (viewParams.selectedTab !== activeTab) {
      saveEmployeeActiveTab(viewParams.selectedTab);
    }
    const meetingNotesSelected =
      viewParams.selectedTab === Constants.CR_MEETING_NOTES_VIEW;
    const meetingNotesClass = meetingNotesSelected ? 'active' : '';

    const ddEventsSelected =
      viewParams.selectedTab === Constants.CR_DD_EVENTS_VIEW;
    const ddEventsClass = ddEventsSelected ? 'active' : '';

    return (
      <div className="consulting-resources-top">
        <Nav tabs className="consultingResourcesTab">
          <NavItem
            key={Constants.CR_MEETING_NOTES_VIEW}
            id="consulting-resources-meeting-notes-item">
            <Link
              className={`nav-link ${meetingNotesClass} meetingnotes-nav`}
              to="/consultingResources/meetingnotes">
              {Constants.CR_MEETING_NOTES_VIEW}
            </Link>
          </NavItem>
          <NavItem
            key={Constants.CR_DD_EVENTS_VIEW}
            id="consulting-resources-ddevents-item">
            <Link
              className={`nav-link ${ddEventsClass} dds-nav`}
              to="/consultingResources/dds">
              {Constants.CR_DD_EVENTS_VIEW}
            </Link>
          </NavItem>
        </Nav>
        <div className="switchGroup">
          <div id="my-clients-managers">My clients&apos; managers</div>
          {meetingNotesSelected && (
            <CustomInput
              name="myMeetingNotesSwitch"
              id="my-meeting-notes-switch"
              type="switch"
              checked={this.state.allManagerMeetingNotes}
              className="switch"
              onChange={e => this.showAllManagerMeetingNotes(e)}
              label="All managers"
            />
          )}
          {ddEventsSelected && (
            <CustomInput
              name="myDDEventsSwitch"
              id="my-ddevents-switch"
              type="switch"
              checked={this.state.allDDEvents}
              className="switch"
              onChange={e => this.showAllDDEvents(e)}
              label="All managers"
            />
          )}
        </div>
      </div>
    );
  }
  renderContents() {
    const { viewParams } = this.props;
    return (
      <TabContent activeTab={viewParams.selectedTab}>
        {Boolean(this.props.activeTab === Constants.CR_MEETING_NOTES_VIEW) && (
          <TabPane tabId={Constants.CR_MEETING_NOTES_VIEW}>
            {this.renderMeetingNotes()}
          </TabPane>
        )}
        {Boolean(this.props.activeTab === Constants.CR_DD_EVENTS_VIEW) && (
          <TabPane tabId={Constants.CR_DD_EVENTS_VIEW}>
            {this.renderDD()}
          </TabPane>
        )}
      </TabContent>
    );
  }

  renderMeetingNotes() {
    const { viewParams } = this.props;
    return (
      <EmployeeManagerMeetingNotes
        investmentFirm={viewParams.investmentFirm}
        strategyName={viewParams.strategyName}
        documentView={viewParams.documentView}
        managerUrl={viewParams.managerUrl}
        viewParams={viewParams}
        writeUpId={viewParams.writeUpId}
        allManagerMeetingNotes={this.state.allManagerMeetingNotes}
      />
    );
  }

  renderDD() {
    const { viewParams } = this.props;
    return (
      <EmployeeDDEvents
        investmentFirm={viewParams.investmentFirm}
        strategyName={viewParams.strategyName}
        documentView={viewParams.documentView}
        managerUrl={viewParams.managerUrl}
        viewParams={viewParams}
        ddEventId={viewParams.eventId}
        allDDEvents={this.state.allDDEvents}
      />
    );
  }

  render() {
    if (this.props.isEmployee === true) {
      return (
        <div className="employeeHub">
          <Row>
            <Col className="mt-3" md={12}>
              {this.renderTabs()}
            </Col>
            <Col md={12}>
              {this.renderContents()}
              <PlanDDEventModal showPlans={true} consultant={true} />
            </Col>
          </Row>
        </div>
      );
    }
    return null;
  }
}

ConsultingResources.propTypes = {
  writeUpId: PropTypes.string,
  eventId: PropTypes.string,
  isEmployee: PropTypes.bool,
  viewParams: PropTypes.object,
  saveEmployeeActiveTab: PropTypes.func,
  activeTab: PropTypes.string,
  changeEmployeeMeetingNotesSwitch: PropTypes.func,
  changeEmployeeDDEventsSwitch: PropTypes.func,
  meetingNotesAllManagerSwitch: PropTypes.bool,
  ddEventsAllManagerSwitch: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    isEmployee: state.okta.isEmployee,
    activeTab: state.ui.employeeActiveTab,
    meetingNotesAllManagerSwitch: state.ui.employeeMeetingNotesAllManagerSwitch,
    ddEventsAllManagerSwitch: state.ui.employeeDDEventsAllManagerSwitch,
  };
};

export default connect(mapStateToProps, {
  saveEmployeeActiveTab,
  changeEmployeeMeetingNotesSwitch,
  changeEmployeeDDEventsSwitch,
})(ConsultingResources);
