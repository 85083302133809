import * as types from '../actions/Types';

export const DEFAULT_REGISTERING = {
  user: {
    email: '',
    firstName: '',
    lastName: '',
  },
  success: false,
  emailNoClientFound: false,
  emailAlreadyRegistered: false,
  wrongCaptcha: false,
  error: false,
};

export const DEFAULT_STATE = {
  settings: {
    issuer: '',
    clientId: '',
    redirectUri: `${window.location.origin}/implicit/callback`,
    baseUrl: '',
    maintenanceMode: '',
  },
  isEmployee: false,
  isClient: false,
  isRolesLoaded: false,
  user: null,
  roles: [],
  accessToken: null,
  contactId: null,
  registering: DEFAULT_REGISTERING,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_OKTA_SETTINGS_SUCCESS: {
      let {
        clientId,
        issuer,
        baseUrl,
        maintenanceMode,
        notificationFilter,
      } = action;
      return {
        ...state,
        settings: {
          ...state.settings,
          clientId,
          issuer,
          baseUrl,
          maintenanceMode,
          notificationFilter,
        },
      };
    }
    case types.SAVE_OKTA_USER_AND_TOKEN: {
      return {
        ...state,
        user: action.user,
        isEmployee: action.isEmployee,
        isClient: action.isClient,
        isRolesLoaded: action.isRolesLoaded,
        accessToken: action.accessToken,
        roles: action.roles,
      };
    }
    case types.REGISTER_USER_SUCCESS:
    case types.REGISTER_USER_FAILED: {
      return {
        ...state,
        registering: action.registering,
      };
    }
    default: {
      return state;
    }
  }
};
