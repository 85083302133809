import moment from 'moment';
import _ from 'lodash';

import { Constants, Events } from '../services/Constants';
import {
  loadUserPlans,
  loadAllPlans,
  loadTeamPicturesResources,
  loadClientDeliverables,
  loadNepcPublishedWithoutLoading,
  loadMarketUpdateDocs,
} from './Resources';
import DenodoService from '../services/DenodoService';
import { lastEvent, addEvent } from './EventActions';
import { redirectPlan } from './UIActions';
import { RequestActionOpts } from '../utils/Request';
import * as types from './Types';
import { withLoading } from './CommonActions';

export const loadPlans = (email, isEmployee, skipCache = false) => {
  return dispatch => {
    const loader = isEmployee
      ? DenodoService().getEmployeePlans
      : DenodoService().getClientPlans;
    return dispatch(
      loadUserPlans(email, loader, skipCache, plans => {
        const filteredPlan = isEmployee
          ? plans
          : plans.filter(p => p.planAdministratorEmail);
        if (
          !isEmployee &&
          filteredPlan.length === 1 &&
          filteredPlan[0].accessEnabled
        ) {
          dispatch(selectPlan(filteredPlan[0]));
        }
        return filteredPlan;
      })
    );
  };
};

export const loadLetterPlans = letter => {
  const fetchLetter = letter === '#' ? 'numeric' : letter.toUpperCase();
  return loadAllPlans(fetchLetter);
};

export const loadTeamPictures = emails =>
  loadTeamPicturesResources(
    emails,
    (accessToken, emails) => {
      const queries = _.map(emails, email =>
        DenodoService().getPlanMemberPicture(accessToken, email)
      );
      return Promise.all(queries).then(responses => {
        const data = responses.map(response => response.data);
        return { data };
      });
    },
    data => {
      return _.reduce(
        data,
        (r, member) => ({ ...r, [member.email]: member.picture }),
        {}
      );
    }
  );

export const adminShouldReview = (getState, isAdmin) => {
  return new Promise(resolve => {
    if (!isAdmin) {
      resolve(false);
      return;
    }
    lastEvent(getState, Events.REVIEW)
      .then(({ status, data: evnt }) => {
        if (status === 200) {
          const lastReview = (evnt && moment(evnt.timeStamp)) || moment();
          const now = moment();
          const timeSinceLastReview = moment.duration(now.diff(lastReview));
          if (
            timeSinceLastReview.asMonths() > Constants.REVIEW_AFTER_X_MONTHS
          ) {
            resolve(true);
            return;
          }
        }
        if (status === 204) {
          resolve(true);
          return;
        }
        resolve(false);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const isPlanAdmin = (plan, user) => {
  const planAdminEmail = _.get(plan, 'planAdministratorEmail', '') || '';
  const userEmail = _.get(user, 'email', '') || '';
  return (
    userEmail !== '' && planAdminEmail.toLowerCase() === userEmail.toLowerCase()
  );
};

export const canViewAdminTab = (isEmployee, plan) => {
  const planAdminEmail = _.get(plan, 'planAdministratorEmail', false) || false;
  return isEmployee && !planAdminEmail;
};

export const refreshDocuments = (force = false) => {
  return (dispatch, getState) => {
    const {
      ui: { selectedPlan: plan },
    } = getState();
    const { planIdGuid, planType } = plan;
    dispatch(loadClientDeliverables(planIdGuid, force));
    dispatch(loadNepcPublishedWithoutLoading(planType, force));
    dispatch(loadMarketUpdateDocs());
  };
};

export const selectPlan = withLoading(plan => {
  return (dispatch, getState) => {
    const {
      okta: { user },
    } = getState();
    const isAdmin = isPlanAdmin(plan, user);

    return adminShouldReview(getState, isAdmin).then(showReview => {
      dispatch({
        type: types.SELECT_PLAN,
        plan,
      });
      if (showReview) {
        dispatch(redirectPlan(`/review`));
      }
      dispatch(refreshDocuments());
      const mapper = data => {
        dispatch(addEvent(Events.OPEN_PLAN));
        return data;
      };
      const longTTL = 99999999;
      return dispatch(
        RequestActionOpts(
          types.UI_OPEN_PLAN,
          () => Promise.resolve({ data: 'ok' }),
          { mapper, cacheTTL: longTTL, storageTTL: longTTL },
          plan.planId
        )
      );
    });
  };
});
