import React, { Component } from 'react';
import { Button, FormGroup, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Label from 'reactstrap/lib/Label';
import Row from 'reactstrap/lib/Row';

import { slug } from '../../services/SlugService';
import Constants from '../../services/Constants';

export default class TilePlan extends Component {
  linkBuild = () => {
    const { plan } = this.props;
    return Constants.CRM_PLAN_URL.replace('{{planId}}', plan.planId);
  };

  render() {
    const { plan, isMyPlans } = this.props;
    const { clientName, accessMyManager } = plan;
    let letter = clientName
      .toLowerCase()
      .trim()
      .replace(/[^a-z]/, '-')[0];

    const page = accessMyManager ? 'manager' : 'client';
    const isPublished = plan.planAdministratorEmail ? (
      <Label className="label" id="publishedLabel">
        Published
      </Label>
    ) : (
      <Label className="label" id="unpublishedLabel">
        Unpublished
      </Label>
    );

    const btnId = isMyPlans
      ? `myBtn${slug(plan.planName).replace(/-/g, '')}`
      : `btn${slug(plan.planName).replace(/-/g, '')}`;

    return (
      <div className="tilePlan">
        <h2>{plan.planName}</h2>
        <NavLink
          className="btn btn-success goBtn"
          to={`/portfolio/${letter}/${slug(plan.planName)}/${page}`}
          id={btnId}>
          Go
        </NavLink>
        {Boolean(this.props.isEmployee) && (
          <FormGroup className="pubLink">
            <Row xs={2} sm={2} md={2} lg={2}>
              <Col sm={6} md={6} lg={6}>
                {isPublished}
              </Col>
              <Col sm={6} md={6} lg={6}>
                <a
                  href={this.linkBuild()}
                  rel="noopener noreferrer"
                  target="_blank">
                  <Button
                    className="text-nowrap link crmLink"
                    color="link"
                    id="crmLink">
                    view in Backstop
                  </Button>
                </a>
              </Col>
            </Row>
          </FormGroup>
        )}
      </div>
    );
  }
}

TilePlan.propTypes = {
  plan: PropTypes.object.isRequired,
  isEmployee: PropTypes.bool,
  isMyPlans: PropTypes.bool,
};
