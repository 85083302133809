import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { NotificationContainer } from 'react-notifications';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import FeedbackIcon from './FeedbackIcon';
import FeedbackModal from './FeedbackModal';
import LoadingAnimation from './LoadingAnimation';
import viewParams from './router/ViewParams';

const Main = props => {
  return (
    <Container className="main" fluid>
      <Header viewParams={props.viewParams} />
      <Content
        selectedView={props.selectedView}
        viewParams={props.viewParams}
      />
      <Footer />
      <LoadingAnimation />
      <FeedbackIcon />
      <NotificationContainer />
      <FeedbackModal viewParams={props.viewParams} />
    </Container>
  );
};

Main.propTypes = {
  selectedView: PropTypes.string,
  viewParams,
};

export default Main;
