import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

import TilePlan from './TilePlan';

class ClientPlans extends Component {
  renderRequestAccess = (email, plan) => {
    const { planAdministratorEmail, planName, fullName } = plan;
    const subject = `NEPC ClientHUB access request to ${planName}`;
    const body = `Hello,

I would like to request access to the ${planName} portfolio on the NECP ClientHUB.

Thanks,
${fullName}`;
    let requestAccess = 'mailto:';
    requestAccess += planAdministratorEmail;
    requestAccess += '&subject=' + encodeURI(subject);
    requestAccess += '&body=' + encodeURI(body).replace('%0A', '%0D%0A');
    return requestAccess;
  };

  renderPlan = plan => {
    const email = this.props.user && this.props.user.email;
    const requestAccess = this.renderRequestAccess(email, plan);
    return (
      <Row className="mb-2" key={plan.planId}>
        <Col lg={9}>{plan.planName}</Col>
        <Col lg={3}>
          <a className="btn btn-secondary" role="button" href={requestAccess}>
            Request Access
          </a>
        </Col>
      </Row>
    );
  };

  renderDisabledPlans = disabledPlans => {
    if (disabledPlans.length > 0) {
      const plans = disabledPlans.map(this.renderPlan);
      return (
        <div className="plansDisabled">
          <p>
            You are associated with the plan(s) listed below but have not been
            granted access. Use appropriate Request Access button to create an
            email you can send to the plan administrator.
          </p>
          {plans}
        </div>
      );
    }
    return null;
  };

  renderEnabledPlans = enabledPlans => {
    const tiles = _.map(enabledPlans, plan => (
      <TilePlan
        key={plan.planId}
        plan={plan}
        isEmployee={false}
        isMyPlans={false}
      />
    ));
    return <div className="tilesWrapper">{tiles}</div>;
  };

  render() {
    const [enabledPlans, disabledPlans] = _.partition(this.props.plans, {
      accessEnabled: true,
    });
    return (
      <div>
        {this.renderEnabledPlans(enabledPlans)}
        {this.renderDisabledPlans(disabledPlans)}
      </div>
    );
  }
}

ClientPlans.propTypes = {
  plans: PropTypes.array.isRequired,
  user: PropTypes.object,
};

export default ClientPlans;
