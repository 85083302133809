import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class LoadingAnimation extends Component {
  render() {
    if (this.props.isLoading) {
      return (
        <div className="loaderWrapper">
          <div className="spinnerContainer">
            <div className="spinnerIcon" />
          </div>
        </div>
      );
    }
    return null;
  }
}

LoadingAnimation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    isLoading: state.ui.isLoading,
  };
};

export default connect(mapStateToProps, null)(LoadingAnimation);
