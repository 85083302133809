import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { hideWhatIs } from '../../../actions';
import Constants from '../../../services/Constants';

export class WhatIsModal extends Component {
  renderDueDiligenceStatusDescription() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="dueDiligenceStatusColumn">Status</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1. No Action</th>
            <td>No actions recommended.</td>
          </tr>
          <tr>
            <th scope="row">2. Watch</th>
            <td>
              Issues have surfaced which may require increased monitoring, but
              which are not considered serious in the near-term. Close
              monitoring is appropriate until the issues are resolved. Examples
              of issues that may result in a product being on watch are:
              <ul>
                <li>Changes or potential changes in key personnel</li>
                <li>Changes or potential changes in ownership</li>
                <li>
                  Changes in business conditions (meaningful change in AUM,
                  shift in focus of overall organization, etc.)
                </li>
                <li>Modifications to the investment process</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">3. On Hold</th>
            <td>
              Serious issues have surfaced which require increased monitoring.
              These issues could have long-term implications on the investment
              team’s ability to effectively execute the investment strategy.
              Following are examples of issues that may result in a product
              being placed on hold:
              <ul>
                <li>Changes or potential changes in key personnel</li>
                <li>Changes or potential changes in ownership</li>
                <li>Modifications to the investment process</li>
                <li>Material litigation</li>
                <li>Significant client losses or additions</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">4. Client Review</th>
            <td>
              Very serious issues have surfaced with an investment manager. For
              products that have been fully vetted, the issues may be
              significant enough to violate the original investment thesis.
              Clients are advised to review their relationship with the manager.
              Examples of issues that may result in a product undergoing client
              review:
              <ul>
                <li>Changes or potential changes in key personnel</li>
                <li>Changes or potential changes in ownership</li>
                <li>Modifications to the investment process</li>
                <li>Material litigation</li>
                <li>SEC Investigation</li>
                <li>
                  Lack of confidence in transacting business with the company
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">5. Terminate</th>
            <td>
              We have lost confidence in the firm or product. Following are
              examples of issues that may trigger a loss of confidence:
              <ul>
                <li>Changes or potential changes in key personnel</li>
                <li>Changes or potential changes in ownership</li>
                <li>Significant changes to the investment process</li>
                <li>Material litigation</li>
                <li>Significant client losses or additions</li>
                <li>Serious regulatory violation </li>
                <li>Viability of the firm or product is in question</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDueDiligenceRatingDescription() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Rating</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="ratingColumn text-center">
              1
            </th>
            <td>
              A high conviction investment product. Product has a clear and
              economically-grounded investment thesis, and is managed by an
              investment team that is sufficiently resourced and incented to
              execute on the thesis.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              2
            </th>
            <td>
              NEPC has a positive view of the strategy. Strategy has a
              compelling and sound investment thesis. The manager is
              sufficiently resourced and incented to execute on the thesis.
              Strengths outweigh the weaknesses, but the strategy does not meet
              all requirements for a 1 rating.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              3
            </th>
            <td>
              A satisfactory investment product. The strategy lacks a compelling
              investment thesis, however there are no significant concerns
              around the manager’s viability.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              4
            </th>
            <td>
              The strategy may have an unclear or ambiguous investment thesis or
              the manager may lack the ability to execute on the stated thesis.
              The strategy likely has strengths and weaknesses and the
              weaknesses may outweigh the strengths.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              5
            </th>
            <td>
              A strategy that lacks an investment thesis or NEPC has no
              confidence in the manager’s ability to execute on the thesis,
              and/or the investment firm may not be viable. Serious issues have
              been identified with an investment manager or product. This rating
              aligns with a Due Diligence status of Terminate for client-owned
              products.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              Not Rated
            </th>
            <td>
              Due diligence has not been sufficiently completed on the product
              or manager.
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderBetaGroupsDescription() {
    return (
      <p>
        NEPC segments the capital markets by Asset group, which aligns asset
        classes according to their broad market exposure. This alignment is
        meant to provide a framework for portfolio structuring to support
        overall investment goals, objectives, and constraints. Each of the four
        Asset groups is comprised of underlying asset classes and ultimately
        investment strategies.
      </p>
    );
  }

  renderESGRatingDescription() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Rating</th>
            <th>Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="ratingColumn text-center">
              1
            </th>
            <td>
              The firm and strategy fully integrate ESG into their processes,
              going above and beyond their peers. They have likely been doing so
              for an extended period of time. They have shown true commitment to
              ESG through their investment actions, and view ESG as part of a
              holistic investment process, even where a short-term benefit is
              not available.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              2
            </th>
            <td>
              ESG has been identified as a material factor in the investment
              decision-making process and the firm has likely developed policies
              and procedures to foster how ESG is integrated into its investment
              strategies. ESG may not be fully integrated across all strategies
              but the firm has established robust policies to ensure adoption
              over time. The strategy has likely demonstrated a detailed
              approach to incorporating ESG into its process supported by
              thorough internal analysis and/or external data.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              3
            </th>
            <td>
              The firm and/or strategy have made solid efforts to incorporate
              ESG and/or explicitly accept the materiality of ESG factors. They
              have likely made numerous commitments to understanding ESG and
              formalizing its integration practices; however, they may not be
              fully integrated across both the firm and the strategy or are
              early on in their adoption. Further, they may have focused on
              efforts that have more immediate short-term benefits, such as
              meeting client demand, over the long-term role of ESG integration.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              4
            </th>
            <td>
              The firm and/or strategy have begun to make efforts to incorporate
              ESG. They have likely made some investments in ESG in areas that
              sound impressive on the surface, but may not ultimately entail
              very much effort, such as signing the UN PRI, purchasing data from
              a third party provider, appointing ESG personnel, or creating
              blanket policies. The efforts may represent a true commitment, but
              likely have not existed long enough to determine the intent of the
              firm and strategy.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              5
            </th>
            <td>
              The firm and strategy have made no effort to incorporate ESG. Any
              point gained here is incidental, and should not be seen as a true
              effort to implement ESG.
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDEIRatingDescription() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Rating</th>
            <th>Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="ratingColumn text-center">
              1
            </th>
            <td>
              The firm and strategy fully integrate DEI into their processes,
              going above and beyond their peers. They have likely been doing so
              for an extended period of time. They have shown true commitment to
              DEI through their investment actions, and view DEI as part of a
              holistic investment process, even where a short-term benefit is
              not available.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              2
            </th>
            <td>
              DEI has been identified as a material factor in the investment
              decision-making process and the firm has likely developed policies
              and procedures to foster how DEI is integrated into its investment
              strategies. DEI may not be fully integrated across all strategies
              but the firm has established robust policies to ensure adoption
              over time. The strategy has likely demonstrated a detailed
              approach to incorporating DEI into its process supported by
              thorough internal analysis and/or external data.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              3
            </th>
            <td>
              The firm and/or strategy have made solid efforts to incorporate
              DEI and/or explicitly accept the materiality of DEI factors. They
              have likely made numerous commitments to understanding DEI and
              formalizing its integration practices; however, they may not be
              fully integrated across both the firm and the strategy or are
              early on in their adoption. Further, they may have focused on
              efforts that have more immediate short-term benefits, such as
              meeting client demand, over the long-term role of DEI integration.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              4
            </th>
            <td>
              The firm and/or strategy have begun to make efforts to incorporate
              DEI. They have likely made some investments in DEI in areas that
              sound impressive on the surface, but may not ultimately entail
              very much effort, such as signing the UN PRI, purchasing data from
              a third party provider, appointing DEI personnel, or creating
              blanket policies. The efforts may represent a true commitment, but
              likely have not existed long enough to determine the intent of the
              firm and strategy.
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              5
            </th>
            <td>
              The firm and strategy have made no effort to incorporate DEI. Any
              point gained here is incidental, and should not be seen as a true
              effort to implement DEI.
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderOwnedLedDescription() {
    return (
      <div>
        <p>
          Diverse-owned: A firm that’s <b>at least 50% owned</b> by an{' '}
          <b>under-represented group</b>
        </p>

        <p>
          Diverse Led: A firm that’s <b>33% to 50%-owned</b> by an{' '}
          <b>under-represented group</b>
        </p>

        <p>
          (underrepresented groups include Black/African American, Latinx,
          Asian/Pacific Islander, American Indian/Alaskan Native, Female,
          Disabled, Veteran, LGBTQ+)
        </p>
      </div>
    );
  }

  renderDescription() {
    const nop = () => null;
    const renderers = {
      [Constants.WHAT_IS_DUE_DILIGENCE_RATING]: this
        .renderDueDiligenceRatingDescription,
      [Constants.WHAT_IS_DUE_DILIGENCE_STATUS]: this
        .renderDueDiligenceStatusDescription,
      [Constants.WHAT_IS_BETA_GROUPS]: this.renderBetaGroupsDescription,
      [Constants.WHAT_IS_ESG_RATING]: this.renderESGRatingDescription,
      [Constants.WHAT_IS_DEI_RATING]: this.renderDEIRatingDescription,
      [Constants.WHAT_IS_OWNED_LED]: this.renderOwnedLedDescription,
    };
    const renderer = renderers[this.props.whatIsModal] || nop;
    return renderer();
  }

  hide = () => {
    this.props.hideWhatIs();
  };

  render() {
    return (
      <Modal isOpen={!!this.props.whatIsModal} toggle={this.hide} size="lg">
        <ModalHeader toggle={this.hide}>{this.props.whatIsModal}</ModalHeader>
        <ModalBody>{this.renderDescription()}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.hide}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

WhatIsModal.propTypes = {
  hideWhatIs: PropTypes.func.isRequired,
  whatIsModal: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    whatIsModal: state.ui.whatIsVisible,
  };
};

export default connect(mapStateToProps, {
  hideWhatIs,
})(WhatIsModal);
