import React from 'react';

import Constants from '../../services/Constants';

const Skyan = () => {
  const getURL = () => {
    switch (Constants.ENVIRONMENT) {
      case 'LOCAL':
        return 'https://skyan-qa.nepc.com/';
      case 'QA':
        return 'https://skyan-qa.nepc.com/';
      case 'UAT':
        return 'https://skyan-uat.nepc.com/';
      case 'PROD':
        return 'https://skyan.nepc.com/';
      default:
        return 'https://skyan.nepc.com/';
    }
  };
  const environmentUrl = getURL();

  return (
    <iframe
      title="skyan"
      className="skyan-frame"
      src={environmentUrl}
      frameBorder="0"
      allowFullScreen
    />
  );
};

export default Skyan;
