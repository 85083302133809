import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFeedbackList, openFeedbackModal } from '../../actions/UIActions';
import DocumentsGrid from '../plans/documents/DocumentsGrid';
import { textColumn } from '../../services/StringService';
import { dateColumn } from '../../services/DateService';

const StatusRenderer = ({ data, context }) => {
  const onClick = () => context.loadFeedback(data);
  return (
    <span className="fa-lg" onClick={onClick}>
      <i className="fa fa-eye" title="View full message">
        &nbsp;
      </i>
    </span>
  );
};
StatusRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export class FeedbackAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  static columnDefs = [
    {
      field: 'createdAt',
      headerName: 'Created',
      ...dateColumn,
    },
    {
      field: 'name',
      headerName: 'Author',
      ...textColumn,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      ...textColumn,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      suppressSizeToFit: true,
      width: 75,
    },
    {
      field: 'message',
      headerName: 'Message',
      ...textColumn,
    },
    {
      field: 'pageTitle',
      headerName: 'Page Title',
      ...textColumn,
    },
    {
      field: 'pageUrl',
      headerName: 'Page URL',
      ...textColumn,
    },
    {
      field: 'clienthub_version',
      headerName: 'Client Hub Version',
      sort: 'desc',
      ...textColumn,
      suppressSizeToFit: true,
      width: 165,
    },
    {
      field: 'dummy',
      headerName: 'view',
      cellRenderer: StatusRenderer,
      suppressSizeToFit: true,
      width: 75,
    },
  ];

  componentDidMount() {
    this.props.getFeedbackList().then(() => this.setState({ loaded: true }));
  }

  loadFeedback = data => {
    this.props.openFeedbackModal(true, data);
  };

  renderContent = () => {
    const context = {
      loadFeedback: this.loadFeedback,
    };
    return (
      <div>
        <div className="messagesGrid mt-3 mb-3 ag-theme-balham">
          <DocumentsGrid
            context={context}
            columnDefs={FeedbackAdmin.columnDefs}
            rowData={this.props.feedbackList}
            viewParams={this.props.viewParams}
            pagination={true}
            noRowsMessage="No rows"
            hasLoaded={this.state.loaded || false}
          />
        </div>
      </div>
    );
  };

  render() {
    return <div className="admin">{this.renderContent()}</div>;
  }
}

FeedbackAdmin.propTypes = {
  feedbackList: PropTypes.array,
  viewParams: PropTypes.object,
  getFeedbackList: PropTypes.func,
  openFeedbackModal: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    feedbackList: state.ui.feedback.data || [],
  };
};

export default connect(mapStateToProps, { getFeedbackList, openFeedbackModal })(
  FeedbackAdmin
);
