import axios from 'axios';

import Constants from './Constants';

const ManagerService = () => {
  const DENODO_URL = Constants.DENODO.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getMyManagersList = (accessToken, planId) =>
    axios.get(
      `${DENODO_URL}/manager/myManagers?planId=${planId}`,
      _headers(accessToken)
    );

  const getManagerSearchListByPlanId = (accessToken, planId) =>
    axios.get(
      `${DENODO_URL}/manager/searchListByPlan?planId=${planId}`,
      _headers(accessToken)
    );

  const loadManagerSummary = (accessToken, managerId) =>
    axios.get(
      `${DENODO_URL}/manager/summary?managerId=${managerId}`,
      _headers(accessToken)
    );

  const loadManagerActivitiesByFirm = (accessToken, managerId) =>
    axios.get(
      `${DENODO_URL}/manager/activities?managerId=${managerId}`,
      _headers(accessToken)
    );

  const loadManagerStrategies = (accessToken, managerId) =>
    axios.get(
      `${DENODO_URL}/manager/strategies?managerId=${managerId}`,
      _headers(accessToken)
    );

  const loadAllManagerStrategies = accessToken =>
    axios.get(`${DENODO_URL}/manager/strategies/all`, _headers(accessToken));

  const loadManagerEVest = (accessToken, managerId) =>
    axios.get(
      `${DENODO_URL}/manager/evest?managerId=${managerId}`,
      _headers(accessToken)
    );

  return {
    getMyManagersList,
    loadAllManagerStrategies,
    getManagerSearchListByPlanId,
    loadManagerSummary,
    loadManagerActivitiesByFirm,
    loadManagerStrategies,
    loadManagerEVest,
  };
};

export default ManagerService;
