export const Storages = {
  // Login Tracking
  LOGGEDIN_EVENT: 'loggedin_event',
  LOGIN_REDIRECT_URL: 'login_redirect_url',
  LOGIN_REDIRECT_TIME: 'login_redirect_time',
  LOGIN_REDIRECT_EXPIRY: 'login_redirect_expiry',
  OKTA_LOGIN_REDIRECT: 'secureRouterReferrerPath',
  // FPL Disclaimer
  SHOW_DISCLAIMER_SETTING: 'fpl_disclaimer_visible',
  // Terms and Conditions Disclaimer
  SHOW_TERMS_DISCLAIMER_SETTING: 'terms_disclaimer_visible',
  // Maintenance Disclaimer
  SHOW_MAINTENANCE_DISCLAIMER_SETTING: 'maintenance_disclaimer_visible',
  // Show unpublished plans
  HIDE_UNPUBLISHED_PLANS: 'hide_unpublished',
};

export const getFromLocalStorage = name => {
  return JSON.parse(localStorage.getItem(name));
};

export const addToLocalStorage = (name, object) => {
  localStorage.setItem(name, JSON.stringify(object));
};

export const removeFromLocalStorage = name => {
  localStorage.removeItem(name);
};

export const itemExists = name => {
  if (localStorage.getItem(name) === null) {
    return false;
  }
  return true;
};
