import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import viewParams from '../../router/ViewParams';
import LoadingComponentOverlay from '../../LoadingSpinner';

export class DocumentsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reloaded: false,
    };
    this.columnApi = null;
    this.gridApi = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filterValue !== nextProps.filterValue) {
      this.updateFilters();
    }
    if (this.props.columnDefs !== nextProps.columnDefs) {
      this.sizeColumnsToFit();
    }
    if (
      this.props.rowData !== nextProps.rowData ||
      this.props.noRowsMessage !== nextProps.noRowsMessage ||
      this.props.isLoading !== nextProps.isLoading
    ) {
      this.refreshNoRows();
    }
  }

  refreshNoRows = _.debounce(() => {
    if (!this.gridApi) {
      return this.refreshNoRows();
    }
    this.gridApi.hideOverlay();
    if (!this.props.hasLoaded || this.props.isLoading) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.getRowData()?.length === 0 || this.getRowData() === '') {
      this.gridApi.showNoRowsOverlay();
    }
  }, 1);

  updateFilters = _.debounce(() => this.onFilterChanged(), 100);

  onFilterChanged = () => {
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    } else {
      this.updateFilters();
    }
  };

  setColumnGridApiAndApplySizeColumnsToFit = ({ columnApi, api }) => {
    this.columnApi = columnApi;
    this.gridApi = api;
    this.sizeColumnsToFit();
    const sortModel = api.sortController.getSortModel();
    this.saveSortState(sortModel);
  };

  saveSortState = sortModel => {
    if (this.props.saveSortState) {
      this.props.saveSortState(sortModel);
    }
  };

  onGridReady = ({ columnApi, api }) => {
    this.setColumnGridApiAndApplySizeColumnsToFit({ columnApi, api });
  };

  firstDataRendered = ({ columnApi, api }) => {
    this.setColumnGridApiAndApplySizeColumnsToFit({ columnApi, api });
  };

  // Note: return true to keep item, false to hide
  shouldFilterRow = node => {
    const { filterValue } = this.props;
    if (filterValue) {
      const properties = Object.keys(filterValue);
      let shouldReturnRow = true;
      properties.forEach(item => {
        if (shouldReturnRow) {
          if (Array.isArray(node.data[item])) {
            shouldReturnRow =
              !filterValue[item] ||
              filterValue[item].includes('All') ||
              node.data[item].some(filter =>
                filterValue[item].includes(filter)
              );
          } else if (item === 'investmentFirmName' || item === 'firm') {
            shouldReturnRow =
              !filterValue[item] ||
              node.data[item].toLowerCase().includes(filterValue[item]);
          } else {
            shouldReturnRow =
              !filterValue[item] ||
              filterValue[item].includes('All') ||
              filterValue[item].includes(node.data[item]);
          }
        }
      });
      return shouldReturnRow;
    }
    return true;
  };

  onGridSizeChanged = () => {
    this.sizeColumnsToFit();
  };

  onSortChanged = event => {
    const sortModel = event.api.sortController.getSortModel();
    this.saveSortState(sortModel);
  };

  sizeColumnsToFit = () => {
    const poll = _.debounce(() => {
      if (this.gridApi?.eventService?.firedEvents?.gridReady) {
        this.gridApi.sizeColumnsToFit();
        return;
      }
      poll();
    }, 100);
    poll();
  };

  getRowData = () => {
    // const { filterValue, gridContext } = this.props;
    // if (
    //   gridContext === 'all_managers' &&
    //   (!filterValue?.betaGroup || filterValue?.betaGroup[0] === 'All')
    // ) {
    //   return [];
    // }
    return this.props.rowData;
  };

  render() {
    const gridOptions = {
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: this.shouldFilterRow,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        autoHeaderHeight: true,
      },
      context: {
        ...this.props.context,
        viewParams: this.props.viewParams,
      },
    };
    const getRowClass = params => `ag-custom-rowindex-${params.node.rowIndex}`;
    const getRowHeight = () => this.props.rowHeight | 30;
    const rowData = this.getRowData();
    return (
      <div
        className={`gridArea ag-theme-alpine mt-3 mb-3 ${this.props.className}`}>
        <AgGridReact
          gridOptions={gridOptions}
          columnDefs={this.props.columnDefs}
          rowData={rowData}
          onGridReady={this.onGridReady}
          onSortChanged={this.onSortChanged}
          onFilterChanged={this.props.onCustomFilterRow}
          onFirstDataRendered={this.firstDataRendered}
          pagination={this.props.pagination}
          components={{ agLoadingOverlay: LoadingComponentOverlay }}
          overlayNoRowsTemplate={this.props.noRowsMessage}
          onGridSizeChanged={this.onGridSizeChanged}
          getRowClass={getRowClass}
          rowHeight={getRowHeight()}
          suppressMenuHide={true}
          suppressDragLeaveHidesColumns={true}
          suppressMultiSort={true}
        />
      </div>
    );
  }
}

DocumentsGrid.propTypes = {
  viewParams,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.array.isRequired,
  noRowsMessage: PropTypes.string.isRequired,
  saveSortState: PropTypes.func,
  filterProperty: PropTypes.array,
  filterValue: PropTypes.object,
  className: PropTypes.string,
  rowHeight: PropTypes.number,
  onCustomFilterRow: PropTypes.func,
  context: PropTypes.object,
  pagination: PropTypes.bool,
  hasLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  gridContext: PropTypes.string,
  groupDefaultExpanded: PropTypes.number,
  getDataPath: PropTypes.func,
  autoGroupColumnDef: PropTypes.object,
};

export default DocumentsGrid;
