export function slug(text) {
  return (text || '')
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
    .replace(/^-+|-+$/g, ''); // remove leading, trailing -
}

export function slugUnderscore(text) {
  return (text || '')
    .trim()
    .replace(/[^\w\s-]/g, ' ') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, ' ') // swap any length of whitespace, underscore, hyphen characters with a single -
    .replace(/^-+|-+$/g, '') // remove leading, trailing -
    .replace('_vti_', ''); // Microsoft documentation recomends dont let _vti_ in any part of file or folder name
}

export function slugInitialLetter(text) {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-z]/, '-')[0];
}
