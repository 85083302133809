import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TeamMember from './TeamMember';
import person from '../../../assets/person.jpg';

export class PlanTeam extends Component {
  retrievePicture = email => {
    const pic = this.props.teamPictures[email];
    if (pic) {
      return `data:image/png;base64,${pic}`;
    }
    return person;
  };

  renderMember = member => {
    const picture = this.retrievePicture(member.email);
    return (
      <TeamMember key={member.teamMemberId} member={member} picture={picture} />
    );
  };

  renderTeam = () => {
    const members = _.sortBy(this.props.team, 'teamDisplaySeq');
    return _.map(members, this.renderMember);
  };

  render() {
    return (
      <div className="planTeam mb-4">
        <h4 className="title">Your Team</h4>
        {this.renderTeam()}
      </div>
    );
  }
}

PlanTeam.propTypes = {
  team: PropTypes.array.isRequired,
  teamPictures: PropTypes.object,
};

export default PlanTeam;
