import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as d3 from 'd3';

import Constants from '../../../services/Constants';
import { formatCurrency } from '../../../services/NumberService';
import { changeDocumentsViewFilter } from '../../../actions';

const exposurePercentage = (d, total) => {
  const v = Math.round((d.exposure / total) * 1000) / 10;
  if (v.toString().indexOf('.') < 0) {
    return v + '.0';
  }
  return v;
};

let assetClassMapping = {};
const legendSize = 20;
const leftOffset = 20;
const legendSpacing = 3;

const colors = (assetClass, i) => {
  return assetClassMapping[assetClass]
    ? assetClassMapping[assetClass].color
    : Constants.ASSET_CLASS_COLORS.Overlay.color;
};

function arcTween(el, arc, outerRadius, delay) {
  d3.select(el)
    .transition()
    .delay(delay)
    .attrTween('d', function(d) {
      const i = d3.interpolate(d.outerRadius, outerRadius);
      return function(t) {
        d.outerRadius = i(t);
        return arc(d);
      };
    });
}

export class Landing extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.redraw);
    if (this.getTotal() > 0) {
      this.redraw();
      this.mounted = true;
    }
  }

  componentDidUpdate() {
    if (this.getTotal() > 0) {
      this.redraw();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.redraw);
    this.mounted = false;
    this.draw.cancel();
    this.hideTooltip();
  }

  getTotal = () => {
    const { data } = this.props;
    return data.reduce((tot, d) => tot + d.exposure, 0);
  };

  openManagers = group => {
    this.props.changeDocumentsViewFilter(Constants.PLAN_PORTFOLIO, {
      assetClass: group,
    });
  };

  redraw = () => {
    this.draw(this.openManagers);
  };

  draw = _.throttle(openManagers => {
    // eslint-disable-next-line
    const parent = ReactDOM.findDOMNode(this);
    if (!parent) {
      return null;
    }
    const container = parent.children[0];
    const { width: parentWidth } = parent.getBoundingClientRect();

    const { data } = this.props;
    if (this.getTotal() <= 0) {
      return null;
    }

    const pieDiameter = 345;
    const legendWidth = 360;
    const legendHeight =
      (legendSize + legendSpacing) *
      (data.filter(d => d.exposure !== 0).length + 2); // +2 for Total + padding

    const containerWidth = Math.min(parentWidth, pieDiameter + legendWidth);
    const portraitMode = containerWidth === parentWidth;
    const containerHeight = portraitMode
      ? pieDiameter + legendHeight
      : Math.max(pieDiameter, legendHeight);

    d3.select(container)
      .selectAll('*')
      .remove();

    const svg = d3
      .select(container)
      .attr('width', containerWidth)
      .attr('height', containerHeight)
      .append('g')
      // .attr('cursor', 'pointer')
      .attr(
        'transform',
        'translate(' + pieDiameter / 2 + ',' + pieDiameter / 2 + ')'
      );

    const total = data.reduce((tot, d) => tot + d.exposure, 0);

    this.drawPie(svg, data, total, pieDiameter, pieDiameter, openManagers);

    const legendLeft = portraitMode ? pieDiameter * -0.25 : pieDiameter * 0.5;
    const legendTop = portraitMode ? pieDiameter * 0.5 : -150;
    this.drawLegend(svg, data, total, legendLeft, legendTop, openManagers);
  }, 300);

  drawLegend = (svg, allData, total, left, top, openManagers) => {
    if (this.getTotal() <= 0) {
      return null;
    }
    const data = allData
      .filter(d => d.exposure !== 0)
      .sort(
        (a, b) => this.getChartOrder(a.group) - this.getChartOrder(b.group)
      );
    const legendContainer = svg.append('g');

    const yOffset = top;
    const xOffset = leftOffset + left + legendSize * 2;

    // const _this = this;
    const addEvents = el =>
      el.on('click', function(d) {
        // const { group } = d;
        // openManagers(group);
        // _this.hideTooltip();
      });

    const legend = legendContainer
      .selectAll('rect')
      .data(data)
      .enter();

    addEvents(
      legend
        .append('rect')
        .attr('x', xOffset - legendSize)
        .attr('y', function(_d, i) {
          return i * (legendSize + legendSpacing) + yOffset;
        })
        .attr('width', legendSize)
        .attr('height', legendSize)
        .style('stroke', 'black')
        .style('stroke-width', '1')
        // .style('cursor', 'pointer')
        .style('fill', function(d, i) {
          return colors(d.group, i);
        })
    );

    this.drawLegendLabels(
      legendContainer,
      data,
      addEvents,
      legendSize,
      legendSpacing,
      xOffset,
      yOffset,
      total
    );
  };

  drawLegendLabels(
    legendContainer,
    data,
    addEvents,
    legendSize,
    legendSpacing,
    xOffset,
    yOffset,
    total
  ) {
    const labels = legendContainer
      .selectAll('text')
      .data(data)
      .enter();

    addEvents(
      labels
        .append('text')
        .attr('x', xOffset + legendSize / 2)
        .attr('y', function(_d, i) {
          return (
            i * (legendSize + legendSpacing) + legendSize / 2 + 4 + yOffset
          );
        })
        .attr('text-anchor', 'start')
        .attr('font-size', '0.8em')
        // .attr('cursor', 'pointer')
        .text(function(d) {
          return d.group;
        })
    );

    const assetXOffset = 270;

    addEvents(
      labels
        .append('text')
        .attr('x', leftOffset + xOffset + legendSize / 2 + assetXOffset)
        .attr('y', function(_d, i) {
          return (
            i * (legendSize + legendSpacing) + legendSize / 2 + 4 + yOffset
          );
        })
        .attr('text-anchor', 'end')
        .attr('font-size', '0.8em')
        // .attr('cursor', 'pointer')
        .text(function(d) {
          return formatCurrency(d.exposure);
        })
    );

    const totalYoffset =
      data.length * (legendSize + legendSpacing) + legendSize / 2 + 4 + yOffset;

    const resetFilter = () => {
      this.openManagers('All');
    };

    legendContainer
      .append('text')
      .attr('x', leftOffset + xOffset + legendSize / 2 + assetXOffset)
      .attr('y', totalYoffset)
      .attr('text-anchor', 'end')
      .attr('font-size', '0.8em')
      .text(formatCurrency(total))
      .on('click', resetFilter);

    legendContainer
      .append('text')
      .attr('x', xOffset + legendSize / 2)
      .attr('y', totalYoffset)
      .attr('text-anchor', 'start')
      .attr('font-size', '0.8em')
      .text('Total')
      .on('click', resetFilter);
  }

  drawTooltip() {
    const existing = d3
      .select(document.body)
      .select('.landingPageChartTooltip');
    if (!existing.empty()) return existing;
    return d3
      .select(document.body)
      .append('div')
      .attr('class', 'landingPageChartTooltip');
  }

  showTooltip = (data, total) => {
    const tooltip = this.drawTooltip();
    const percent = exposurePercentage(data, total);
    tooltip
      .style('display', 'block')
      .style('top', d3.event.pageY + 20 + 'px')
      .style('left', d3.event.pageX + 20 + 'px').html(`
          <span>
            <p><strong>${data.group}</strong></p>
            <p>${formatCurrency(data.exposure)}</p>
            <p>${percent}%</p>
          </span>
          `);
  };

  hideTooltip = () => {
    const tooltip = this.drawTooltip();
    tooltip.style('display', 'none');
  };

  getChartOrder = group => {
    return assetClassMapping[group]
      ? assetClassMapping[group].order
      : Constants.ASSET_CLASS_COLORS.Overlay.order;
  };

  drawPie = (svg, data, total, width, height, openManagers) => {
    const _this = this;
    if (this.getTotal() <= 0) {
      return null;
    }
    const outerRadius = Math.min(width, height) / 2 - 20;
    const innerRadius = 0;
    const labelInnerRadius = outerRadius / 2;
    const hoverRadius = 20;

    const arc = d3
      .arc()
      .padRadius(outerRadius)
      .innerRadius(innerRadius);

    const pie = d3.pie().value(function(d) {
      return d.exposure;
    });

    pie.sort((a, b) => {
      return this.getChartOrder(a.group) - this.getChartOrder(b.group);
    });

    const chart = svg.selectAll('path').data(pie(data));
    const arcs = chart.enter();

    arcs
      .append('path')
      .each(function(d) {
        d.outerRadius = outerRadius - hoverRadius;
      })
      .attr('d', arc)
      .style('fill', function(d, i) {
        return colors(d.data.group, i);
      })
      .on('mouseover', function(d) {
        arcTween(this, arc, outerRadius, 0);
        _this.showTooltip(d.data, total);
      })
      .on('mousemove', function(d) {
        _this.showTooltip(d.data, total);
      })
      .on('mouseout', function() {
        arcTween(this, arc, outerRadius - hoverRadius, 150);
        _this.hideTooltip();
      });

    this.drawPieLabels(arcs, outerRadius, labelInnerRadius, total);
  };

  drawPieLabels(arcs, outerRadius, labelInnerRadius, total) {
    const labelArc = d3
      .arc()
      .padRadius(outerRadius)
      .innerRadius(labelInnerRadius);

    arcs
      .append('text')
      .attr('transform', function(d) {
        return 'translate(' + labelArc.centroid(d) + ')';
      })
      .style('fill', 'white')
      .attr('text-anchor', 'middle')
      .attr('font-size', '1.0em')
      .attr('pointer-events', 'none')
      .text(function(d) {
        const percent = exposurePercentage(d.data, total);
        return parseInt(percent, 10) > 3 ? percent + '%' : '';
      });
  }

  render() {
    if (this.getTotal() <= 0) {
      return null;
    }
    return (
      <Fragment>
        <div className="landingPageChart" id="landing-page-chart">
          <svg />
        </div>
        <h3>Portfolio Details</h3>
      </Fragment>
    );
  }
}

Landing.propTypes = {
  data: PropTypes.array,
  changeDocumentsViewFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const managers = state.resources.my_managers.data || [];
  const byAssetClass = _.groupBy(managers, 'assetType');
  assetClassMapping = _.chain(managers)
    .keyBy('assetType')
    .mapValues(i => {
      return {
        color: i.assetTypeColor,
        order: i.assetTypeSortOrder,
      };
    })
    .value();
  const data = _.map(_.keys(byAssetClass), group => {
    const exposure = _.reduce(
      byAssetClass[group],
      (total, manager) => total + manager.assetValue,
      0
    );
    return { group, exposure };
  });
  return {
    data,
  };
};

export default connect(mapStateToProps, { changeDocumentsViewFilter })(Landing);
