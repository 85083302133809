import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

import Constants from '../../../services/Constants';
import { compareWithToday } from '../../../services/DateService';
import { slug, slugInitialLetter } from '../../../services/SlugService';
import PlanRecent from '../details/PlanRecent';
import {
  loadNepcPublished,
  loadMarketUpdateDocs,
} from '../../../actions/Resources';

export class DocumentsMenu extends Component {
  options = () => {
    const planSlug = slug(this.props.planName || '');
    const letterSlug = slugInitialLetter(this.props.clientName || '');
    const planUrl = `/portfolio/${letterSlug}/${planSlug}`;
    const menuItems = [];
    if (this.props.hasMyManagers) {
      menuItems.push({
        title: Constants.PLAN_PORTFOLIO,
        to: `${planUrl}/manager`,
      });
    }
    menuItems.push({
      title: Constants.PLAN_DOCUMENTS_VIEW,
      to: `${planUrl}/client`,
    });
    menuItems.push({
      title: Constants.PLAN_RESEARCH_DOCUMENTS_VIEW,
      to: `${planUrl}/nepc`,
    });
    if (this.props.hasFpl) {
      menuItems.push({
        title: Constants.PLAN_FOCUSED_PLACEMENT_LISTS,
        to: `${planUrl}/fpl`,
      });
    }
    if (this.props.hasMarketUpdates) {
      menuItems.push({
        title: (
          <span>
            {Constants.PLAN_MARKET_UPDATES_VIEW}
            {this.checkMarketUpdatesViewed()}
          </span>
        ),
        to: `${planUrl}/market`,
      });
    }
    if (this.props.hasPerformancePortal) {
      menuItems.push({
        title: Constants.PLAN_PERFORMANCE_PORTAL,
        href: Constants.PERFORMANCE_PORTAL_URI,
        target: '_blank',
      });
    }
    menuItems.push({
      title: Constants.MY_TEAM_TITLE,
      to: `${planUrl}/team`,
    });
    if (this.props.isPlanAdmin || this.props.canViewAdminTab) {
      menuItems.push({
        title: Constants.ADMIN_TITLE,
        to: `${planUrl}/admin`,
      });
    }
    return menuItems;
  };

  renderNavItem = ({ title, ...props }) => {
    const Tag = props.href ? NavLink : RouterNavLink;
    return (
      <NavItem key={title}>
        <Tag className={'nav-link'} {...props}>
          {title}
        </Tag>
      </NavItem>
    );
  };

  checkMarketUpdatesViewed = () => {
    const docs = this.props.publishedDocs;
    const docsViewed = this.props.marketUpdateDocsViewed;
    if (docs && docsViewed) {
      let missingToRead = false;
      const filteredDocs = docs.filter(
        doc =>
          doc.publishingLocation === Constants.DOCUMENT_MARKET_UPDATES &&
          compareWithToday(doc.expirationDate)
      );
      // eslint-disable-next-line
      filteredDocs.map(doc => {
        if (
          docsViewed.filter(
            dv => parseInt(dv.eventData, 10) === doc.objectDocumentId
          ).length === 0
        ) {
          missingToRead = true;
          return null;
        }
      });
      return missingToRead ? this.renderBadge() : null;
    }
  };

  renderBadge = () => {
    return <div className={'badge notification-flag'}> </div>;
  };

  render() {
    const options = this.options();
    const items = _.map(options, this.renderNavItem);
    return (
      <div className="documentsMenuRoot">
        <div className="documentsMenuContainer">
          <Card>
            <Nav vertical>{items}</Nav>
          </Card>
        </div>
        <PlanRecent />
      </div>
    );
  }
}

DocumentsMenu.propTypes = {
  planName: PropTypes.string,
  clientName: PropTypes.string,
  selection: PropTypes.string,
  hasFpl: PropTypes.bool.isRequired,
  hasMarketUpdates: PropTypes.bool.isRequired,
  hasPerformancePortal: PropTypes.bool.isRequired,
  isPlanAdmin: PropTypes.bool.isRequired,
  canViewAdminTab: PropTypes.bool.isRequired,
  hasMyManagers: PropTypes.bool.isRequired,
  publishedDocs: PropTypes.array,
  marketUpdateDocsViewed: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    publishedDocs: state.resources.nepc_published.data,
    marketUpdateDocsViewed: state.resources.market_update_docs.data || [],
  };
};

export default connect(mapStateToProps, {
  loadNepcPublished,
  loadMarketUpdateDocs,
})(DocumentsMenu);
