if (!Object.is) {
  // eslint-disable-next-line no-extend-native
  Object.is = function is(x, y) {
    if (x === y) {
      return x !== 0 || 1 / x === 1 / y;
    }
    return x !== x && y !== y; // eslint-disable-line no-self-compare
  };
}

if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.includes = function includes(value) {
    for (let i = 0; i < this.length; i += 1) {
      if (Object.is(this[i], value)) {
        return true;
      }
    }
    return false;
  };
}
