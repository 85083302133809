import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';
import _ from 'lodash';

import OktaConfig from './OktaConfig';

export default class OktaSignInWidget extends Component {
  componentDidMount() {
    const { baseUrl, onSuccess, onError } = this.props;
    const el = this.node;
    OktaConfig.baseUrl = baseUrl;
    this.widget = new OktaSignIn(OktaConfig);
    this.widget.renderEl({ el }, onSuccess, onError);
    this.toggleMaintenance();
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  componentDidUpdate() {
    this.toggleMaintenance();
  }

  toggleMaintenance = _.debounce(() => {
    if (this.props.disabled) {
      if (this.node) {
        this.disableControls();
        return;
      }
      this.toggleMaintenance();
    }
  }, 500);

  disableControls = value => {
    const inputs = document.querySelectorAll('input');
    const links = document.querySelectorAll('a:not(.nooped)');
    if (inputs || links) {
      _.each(inputs, e => e.setAttribute('disabled', value));
      _.each(links, l => {
        const newLink = document.createElement('a');
        newLink.setAttribute('class', l.className + ' nooped');
        newLink.appendChild(document.createTextNode(l.innerText));
        l.parentElement.replaceChild(newLink, l);
      });
    }
    this.toggleMaintenance();
  };

  render() {
    return <div ref={node => (this.node = node)} />;
  }
}

OktaSignInWidget.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
