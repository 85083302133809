import React, { Component } from 'react';
import { Container, Row, Col, NavLink, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import {
  hideLatestSystemMessages,
  showSystemMessage,
  markSystemMessageSeen,
} from './actions';
import { redirect } from '../../actions';
import Constants from '../../services/Constants';
import { hijackScrollbar } from '../../services/UIService';

export class MessagesPopup extends Component {
  componentDidMount() {
    this.bodyScroll = hijackScrollbar(document.body);
  }

  componentWillUnmount() {
    this.bodyScroll.dismiss();
  }

  shortifyMessage = text => {
    const stripper = /(<([^>]+)>)/g;
    const emptier = /\s{2,}/g;
    return (
      (text || '')
        .replace(stripper, ' ')
        .replace(emptier, ' ')
        .trim()
        .substring(0, 150) + '...'
    );
  };

  markAllRead = () => {
    const { latest, seenMessages } = this.props;
    const allMessages = latest.map(message => message.id);
    const unreadMessageIds = _.difference(allMessages, seenMessages);
    this.props.markSystemMessageSeen(unreadMessageIds);
  };

  getMessages = () => {
    const messages = [];
    if (this.props.isAdmin) {
      const onAdmin = () => {
        this.props.hideLatestSystemMessages();
        this.props.redirect('/settings');
      };
      messages.push(
        <Row key="messages-header" className="message message-open-header">
          <Col md={6}>
            <NavLink onClick={onAdmin} href="#">
              Admin
            </NavLink>
          </Col>
          <Col md={6}>
            <NavLink
              onClick={this.markAllRead}
              href="#"
              className="message-mark-all-read">
              Mark all Read
            </NavLink>
          </Col>
        </Row>
      );
    } else {
      messages.push(
        <Row key="messages-header" className="message message-open-header">
          <Col md={12}>
            <NavLink
              onClick={this.markAllRead}
              href="#"
              className="message-mark-all-read">
              Mark all Read
            </NavLink>
          </Col>
        </Row>
      );
    }
    if (!_.isEmpty(this.props.latest)) {
      const { latest } = this.props;
      _.forEach(latest, message => {
        const shortMessage = this.shortifyMessage(message.message);
        const onClick = () => {
          this.props.hideLatestSystemMessages();
          this.props.showSystemMessage(message);
        };
        const isUnread = !_.includes(this.props.seenMessages, message.id);
        const unreadIcon = isUnread ? (
          <Badge color="danger">&nbsp;</Badge>
        ) : null;
        const postedDate = moment(message.startDate).format('DD MMM YYYY');
        messages.push(
          <Row key={message.id} className="message" onClick={onClick}>
            <Col md={12}>
              <h3 className="title">
                {message.subject}{' '}
                <span className="announcementsUnreadBadge">{unreadIcon}</span>
              </h3>
              <span className="postedDate">Posted {postedDate}</span>
              <span className="short">
                {shortMessage}
                <NavLink href="#">Read more</NavLink>
              </span>
            </Col>
          </Row>
        );
      });
    } else {
      messages.push(
        <Row key="noMessage" className="message">
          <Col md={12}>
            <h3 className="title">No Messages</h3>
            <span className="short">There are no new messages.</span>
          </Col>
        </Row>
      );
    }
    return messages;
  };

  render() {
    const messages = this.getMessages();
    const { left, top } = this.props;
    const onClose = () => this.props.hideLatestSystemMessages();
    const parentClassName = 'systemMessagesPopup';
    const onScroll = ({ target: { className } }) => {
      if (parentClassName === className) {
        onClose();
      }
    };
    return (
      <div
        className={parentClassName}
        onClick={onClose}
        onWheel={onScroll}
        onScroll={onScroll}>
        <Container
          className="messages"
          style={{ left: left - 150, top: top + 15 }}>
          {messages}
        </Container>
      </div>
    );
  }
}
MessagesPopup.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  latest: PropTypes.array.isRequired,
  seenMessages: PropTypes.array.isRequired,

  left: PropTypes.number,
  top: PropTypes.number,

  hideLatestSystemMessages: PropTypes.func.isRequired,
  showSystemMessage: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  markSystemMessageSeen: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const isAdmin = _.includes(state.okta.roles, Constants.SYSTEM_ADMIN_ROLE);
  return {
    visible: state.messages.latestPopup.visible,
    left: state.messages.latestPopup.left,
    top: state.messages.latestPopup.top,
    latest: state.messages.latest || [],
    seenMessages: state.messages.seenMessages || [],
    isAdmin,
  };
};

export default connect(mapStateToProps, {
  hideLatestSystemMessages,
  showSystemMessage,
  redirect,
  markSystemMessageSeen,
})(props => {
  if (!props.visible) {
    return <div />;
  }
  return <MessagesPopup {...props} />;
});
