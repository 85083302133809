import axios from 'axios';

import Constants from './Constants';

const TermsService = () => {
  const TERMS_URL = `${Constants.EVENTS.URL}/termsAndConditions`;
  const OKTA_URL = `${Constants.OKTA.URL}/termsAndConditions`;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  const save = (accessToken, term) =>
    axios.post(`${TERMS_URL}/save`, term, _headers(accessToken));

  const fetchLatest = () => axios.get(`${OKTA_URL}/latest`);

  const fetchAll = accessToken =>
    axios.get(`${TERMS_URL}/list`, _headers(accessToken));

  const accept = (accessToken, email, terms) =>
    axios.post(
      `${TERMS_URL}/accept`,
      {
        email,
        acceptedDate: new Date().toISOString(),
        termsAndConditionsDto: terms,
      },
      _headers(accessToken)
    );

  const hasAccepted = (accessToken, email, termsId) =>
    axios.get(
      `${TERMS_URL}/checkAcceptance/${email}/${termsId}`,
      _headers(accessToken)
    );

  return {
    save,
    fetchLatest,
    fetchAll,

    accept,
    hasAccepted,
  };
};

export default TermsService;
