import { NotificationManager } from 'react-notifications';

import * as types from './types';
import { RequestAction } from '../../utils/Request';
import termsService from '../../services/TermsService';
import { withLoading } from '../../actions/CommonActions';
import { redirect } from '../../actions/UIActions';
import { loadLatest as loadLatestAnnouncements } from '../messages/actions';

export const loadLatest = withLoading(() => {
  return dispatch => {
    return dispatch(
      RequestAction(types.TERMS_LATEST, termsService().fetchLatest)
    );
  };
});

export const loadAll = withLoading(() => {
  return dispatch => {
    return dispatch(RequestAction(types.TERMS_ALL, termsService().fetchAll));
  };
});

export const save = withLoading((terms, success) => {
  return (dispatch, getState) => {
    const {
      okta: {
        user: { email },
      },
    } = getState();
    const today = new Date().toISOString();
    if (!terms.createdBy) {
      return save(
        {
          ...terms,
          createdBy: email,
          createdOn: today,
          active: true,
        },
        success
      );
    }
    return dispatch(
      RequestAction(types.TERMS_SAVE, termsService().save, {
        ...terms,
        modifiedBy: email,
        modifiedOn: today,
      })
    )
      .then(() => {
        if (success) {
          success();
        }
        dispatch(loadLatest()).then(() => {
          dispatch(loadAll()).then(() => {
            NotificationManager.success(
              'Terms and Conditions successfully saved.',
              'Terms and Conditions'
            );
          });
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Error saving terms and conditions.',
          'Terms and Conditions'
        );
      });
  };
});

export const acceptTermsAndConditions = withLoading(() => {
  return (dispatch, getState) => {
    const {
      terms: {
        latest: { data: currentTerms },
      },
      okta: {
        accessToken,
        user: { email },
      },
    } = getState();
    return termsService()
      .accept(accessToken, email, currentTerms)
      .then(() => {
        dispatch({ type: types.TERMS_ACCEPTED });
        dispatch(loadLatestAnnouncements());
        dispatch(redirect('/portfolios'));
      });
  };
});

export const shouldReadTermsAndConditions = withLoading(() => {
  return (dispatch, getState) => {
    return dispatch(loadLatest()).then(() => {
      const {
        terms: {
          latest: { data: currentTerms },
        },
        okta: {
          accessToken,
          user: { email },
        },
      } = getState();
      if (!accessToken) {
        return;
      }
      const termsId = currentTerms.id;
      return termsService()
        .hasAccepted(accessToken, email, termsId)
        .then(response => {
          if (!response.data || !response.data.email) {
            dispatch({ type: types.TERMS_MUST_ACCEPT });
          } else {
            dispatch({ type: types.TERMS_ACCEPTED });
            dispatch(loadLatestAnnouncements());
          }
        })
        .catch(() => {
          dispatch({ type: types.TERMS_MUST_ACCEPT });
        });
    });
  };
});
