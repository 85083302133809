import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openFeedbackModal } from '../actions';

export const FeedbackIcon = props => (
  <div className="feedback-icon">
    <i
      className="fas fa-comment-dots fa-flip-horizontal"
      onClick={props.openFeedbackModal}
      title="Give Us Feedback!"
    />
  </div>
);

FeedbackIcon.propTypes = {
  openFeedbackModal: PropTypes.func.isRequired,
};

export default connect(null, { openFeedbackModal })(FeedbackIcon);
