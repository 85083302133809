import * as types from './Types';

export const updateLoading = loadingState => {
  return dispatch => {
    const change = loadingState ? +1 : -1;
    dispatch({ type: types.LOADING, change });
  };
};

export const withLoading = actionCreatorThunk => {
  return (...args) => {
    return (dispatch, getState) => {
      dispatch(updateLoading(true));
      const done = () => dispatch(updateLoading(false));

      let thunk = actionCreatorThunk(...args);
      while (typeof thunk === 'function') {
        thunk = thunk(dispatch, getState);
      }
      return thunk.then(done).catch(e => {
        done();
        throw e;
      });
    };
  };
};

export const withLoadingPromise = (dispatch, promise) => {
  dispatch(updateLoading(true));
  const done = () => dispatch(updateLoading(false));
  return promise.then(done).catch(done);
};
