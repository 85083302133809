import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Constants from '../services/Constants';
import viewParams from './router/ViewParams';

let _title = '';

class ClientHUBTitle extends React.Component {
  update = _.debounce(() => {
    if (document.title !== _title) {
      document.title = _title;
    }
  }, 300);

  render() {
    let title = 'NEPC ClientHub';
    const {
      selectedView,
      viewParams: { plan, documentView, fplList, fplStrategy, managerSummary },
    } = this.props;
    if (selectedView === Constants.PROFILE_VIEW) {
      title = 'Profile - ' + title;
    } else if (selectedView === Constants.TERMS_AND_CONDITIONS_VIEW) {
      title = 'Terms and Conditions - ' + title;
    } else if (selectedView === Constants.PLANS_VIEW) {
      title = 'Portfolios - ' + title;
    } else if (selectedView) {
      if (selectedView === Constants.DOCUMENTS_VIEW) {
        title = documentView + ' - ' + title;
      } else if (selectedView === Constants.PLAN_TEAM_VIEW) {
        title = 'Team - ' + title;
      } else if (selectedView === Constants.ADMIN_VIEW) {
        title = 'Admin - ' + title;
      }
      if (fplStrategy) {
        title = fplStrategy.investmentStrategyName + ' - ' + title;
      } else if (fplList) {
        title = fplList.listName + ' - ' + title;
      } else {
        if (managerSummary && managerSummary.firmName) {
          title = managerSummary.firmName + ' ' + title;
        }
      }
      if (plan) {
        title = plan.planName + ' - ' + title;
      }
    }
    _title = title;
    this.update();
    return null;
  }
}

ClientHUBTitle.propTypes = {
  selectedView: PropTypes.string,
  viewParams,
};

export default ClientHUBTitle;
