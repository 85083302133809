import axios from 'axios';

import Constants from './Constants';

const EmployeeService = () => {
  const DENODO_URL = Constants.DENODO.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getEmployeeMeetingNotes = (accessToken, email, allEmployees) =>
    axios.get(
      `${DENODO_URL}/employee/meetingnotes/${allEmployees}?email=${email}`,
      _headers(accessToken)
    );

  const getEmployeeDDEvents = (accessToken, email, allManagers) =>
    axios.get(
      `${DENODO_URL}/employee/ddevents/${allManagers}?email=${email}`,
      _headers(accessToken)
    );

  const getEmployeeMeetingNotesPlans = (accessToken, email, writeUpId) =>
    axios.get(
      `${DENODO_URL}/employee/meetingnotes/plans/strategies?writeUpId=${writeUpId}&email=${email}`,
      _headers(accessToken)
    );

  const getEmployeeDDEventsAffectedPlans = (accessToken, eventId) =>
    axios.get(
      `${DENODO_URL}/employee/dd/plans?eventId=${eventId}`,
      _headers(accessToken)
    );

  return {
    getEmployeeMeetingNotes,
    getEmployeeMeetingNotesPlans,
    getEmployeeDDEvents,
    getEmployeeDDEventsAffectedPlans,
  };
};

export default EmployeeService;
