import axios from 'axios';

import Constants from './Constants';

const MessagesService = () => {
  const MESSAGES_URL = Constants.EVENTS.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const loadAll = accessToken =>
    axios.get(`${MESSAGES_URL}/systemMessage/list`, _headers(accessToken));

  const loadLatest = accessToken =>
    axios.get(`${MESSAGES_URL}/systemMessage/latest`, _headers(accessToken));

  const save = (accessToken, message) =>
    axios.post(
      `${MESSAGES_URL}/systemMessage/save`,
      message,
      _headers(accessToken)
    );

  return {
    loadAll,
    loadLatest,
    save,
  };
};

export default MessagesService;
