import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import _ from 'lodash';

import NotesModal from './NotesModal';
import {
  showDDEvent,
  loadEmployeeDDeventsAffectedPlans,
  printDDEvent,
} from '../../../actions';
import { formatDate } from '../../../services/DateService';
import { slug, slugInitialLetter } from '../../../services/SlugService';

const headerLayout = [
  [{ field: 'eventTitle', title: 'Title' }],
  [
    { field: 'investmentFirmName', title: 'Investment Firm' },
    {
      field: 'eventDate',
      title: 'Event Date',
      formatter: formatDate,
    },
  ],
  [{ field: 'firmDueDiligenceStatus', title: 'Event Action' }],
  [{ field: 'affectedStrategies', title: 'Affected Strategies' }],
];

const contentLayout = [
  { title: 'Recommendation', field: 'recommendation' },
  { title: 'Description of Event', field: 'descriptionOfEvent', type: `html` },
];

export class PlanDDEventModal extends Component {
  static MAX_AFFECTED_STRATEGIES_LEN = 300;

  constructor(props) {
    super(props);
    this.state = {
      affectedStrategiesExpanded: false,
      showAffectedPlans: true,
      showWarText: true,
    };
  }

  onToggle = () => {
    this.props.showDDEvent(null);
  };

  renderWhatIs(value, onClick) {
    return (
      <span>
        {value || ''}
        <Button color="link" className="whatIsLink" onClick={onClick}>
          (what is this?)
        </Button>
      </span>
    );
  }

  renderAffectedStrategiesLink = () => {
    const { affectedStrategiesExpanded } = this.state;
    const title = affectedStrategiesExpanded
      ? '[ show less ]'
      : ' [ show more ]';
    const onToggle = () =>
      this.setState({
        affectedStrategiesExpanded: !affectedStrategiesExpanded,
      });
    return (
      <Button color="link" className="whatIsLink" onClick={onToggle}>
        {title}
      </Button>
    );
  };

  calculateNewData() {
    const { ddEvent, affectedPlans, consultant } = this.props;
    if (!ddEvent || !(Object.keys(ddEvent).length > 0)) {
      return null;
    }
    const { affectedStrategies, eventId } = ddEvent;

    this.props.loadEmployeeDDeventsAffectedPlans(eventId);

    const newAffectedStrategies = this.renderAffectedStrategies(
      affectedStrategies
    );
    if (affectedPlans.length > 0) {
      const links = _.map(affectedPlans, plan =>
        this.openPlanMeetingNotes(plan.clientName, plan.planName)
      );

      return {
        ...ddEvent,
        injectedPlansField: <span>{links}</span>,
        affectedStrategies: newAffectedStrategies,
      };
    }
    if (consultant) {
      return {
        ...ddEvent,
        injectedPlansField: 'None of your clients are affected',
        affectedStrategies: newAffectedStrategies,
      };
    }
    return {
      ...ddEvent,
      affectedStrategies: newAffectedStrategies,
    };
  }

  renderAffectedStrategies = affectedStrategies => {
    if (
      affectedStrategies &&
      affectedStrategies.length > PlanDDEventModal.MAX_AFFECTED_STRATEGIES_LEN
    ) {
      const truncated =
        affectedStrategies.slice(
          0,
          PlanDDEventModal.MAX_AFFECTED_STRATEGIES_LEN
        ) + '... ';
      const text = this.state.affectedStrategiesExpanded
        ? affectedStrategies
        : truncated;
      return (
        <span>
          {text} {this.renderAffectedStrategiesLink()}
        </span>
      );
    }
    return affectedStrategies;
  };

  openPlanMeetingNotes = (clientName, planName) => {
    const slugPlanName = slug(planName);
    const slugLetter = slugInitialLetter(clientName);
    const url = `/portfolio/${slugLetter}/${slugPlanName}/manager/dds`;
    return (
      <a
        key={planName}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className="DDEventsPlanLink">
        {planName}
      </a>
    );
  };

  calculateHeaderLayout() {
    const { showPlans, affectedPlans, consultant } = this.props;
    if (showPlans && this.state.showAffectedPlans && affectedPlans.length > 0) {
      const injectedPlansField = [
        {
          field: 'injectedPlansField',
          title: consultant ? 'My Affected Plans' : 'Affected Plans',
        },
      ];
      return [...headerLayout, injectedPlansField];
    }
    return headerLayout;
  }

  showAffectedPlans = showAffectedPlans => {
    this.setState({ showAffectedPlans });
  };

  showWarText = showWarText => {
    this.setState({ showWarText });
  };

  render() {
    const { ddEvent, user } = this.props;
    if (!ddEvent || !(Object.keys(ddEvent).length > 0)) {
      return null;
    }
    const { eventTitle } = ddEvent;

    return (
      <NotesModal
        headerLayout={this.calculateHeaderLayout()}
        contentLayout={contentLayout}
        toggle={this.onToggle}
        data={this.calculateNewData()}
        title={eventTitle}
        documentType={'Due Diligence Event'}
        user={user}
        printable={true}
        printDocument={this.props.printDDEvent}
        showAffectedPlans={this.showAffectedPlans}
        showWarText={this.showWarText}
      />
    );
  }
}

PlanDDEventModal.propTypes = {
  showDDEvent: PropTypes.func.isRequired,
  loadEmployeeDDeventsAffectedPlans: PropTypes.func,
  ddEvent: PropTypes.object,
  showPlans: PropTypes.bool,
  affectedPlans: PropTypes.array.isRequired,
  consultant: PropTypes.bool.isRequired,
  printDDEvent: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { ddEvent } = state.ui;
  const {
    okta: { user },
  } = state;
  return {
    ddEvent,
    affectedPlans:
      _.get(state, 'resources.employee_dd_events_plan_list.data') || [],
    user,
  };
};

export default connect(mapStateToProps, {
  showDDEvent,
  loadEmployeeDDeventsAffectedPlans,
  printDDEvent,
})(PlanDDEventModal);
