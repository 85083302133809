import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import {
  loadManagerSummary,
  loadManagerActivities,
  loadManagerStrategies,
  loadManagerManagerActivities,
  selectInvestmentFirm,
  loadFPLNepcOpinion,
  loadFPLResearchWriteups,
  loadFPLNepcOpinionResource,
  loadFPLManagerActivities,
  showWhatIsDueDiligenceRating,
  showWhatIsDueDiligenceStatus,
} from '../../actions/';
import Constants from '../../services/Constants';
import PlanDDEventModal from '../plans/documents/PlanDDEventModal';
import WhatIsModal from '../plans/documents/WhatIsModal';
import DocumentsList from '../plans/documents/DocumentsList';
import ManagerContents from '../plans/documents/ManagerContents';
import StrategyContents from '../plans/documents/StrategyContents';
import ManagerSummaryHeader from '../plans/documents/ManagerSummaryHeader';
import { slug } from '../../services/SlugService';
import FPLStrategyHeader from '../plans/documents/FPLStrategyHeader';
import MeetingNotesModal from '../plans/documents/MeetingNotesModal';

export class InvestmentFirmPanel extends Component {
  hideInvestmentFirm = () => {
    this.setState({ investmentFirm: null });
  };

  isStrategy = () => {
    return this.props.strategyName;
  };

  loadInvestmentFirmData = id => {
    this.props.loadManagerSummary(id);
    switch (this.props.documentView) {
      case Constants.PLAN_MANAGER_STRATEGIES:
        this.props.loadManagerStrategies(id);
        break;
      case Constants.FPL_MANAGER_ACTIVITIES:
        this.props.loadManagerActivities(id);
        break;
      case Constants.EMPLOYEE_MANAGER_ACTIVITIES:
        this.props.loadManagerManagerActivities(id);
        break;
      default:
        break;
    }
  };

  loadStrategyData = (strategy, documentView) => {
    this.props.loadFPLResearchWriteups(strategy.strategyId);
    this.props.loadFPLNepcOpinionResource(strategy.strategyId);
    switch (documentView) {
      case Constants.FPL_NEPC_NARRATIVES:
        this.props.loadFPLNepcOpinion(strategy);
        break;
      case Constants.FPL_MANAGER_ACTIVITIES:
        this.props.loadFPLManagerActivities(strategy.strategyId);
        break;
      default:
        break;
    }
  };

  loadData() {
    const { firmId } = this.props;

    if (this.isStrategy()) {
      this.props.loadManagerStrategies(firmId);

      const { managerStrategies, strategyName, documentView } = this.props;
      if (managerStrategies && managerStrategies.data) {
        const strategy = managerStrategies.data.find(
          strategy => slug(strategy.investmentStrategyName) === strategyName
        );

        if (strategy) {
          this.loadStrategyData(strategy, documentView);
          return strategy;
        }
      }
    } else {
      this.loadInvestmentFirmData(firmId);
    }
  }

  renderStrategy = (strategy, viewParams) => {
    const {
      investmentFirm,
      strategyName,
      showWhatIsDueDiligenceRating,
      showWhatIsDueDiligenceStatus,
      baseUrl,
    } = this.props;
    const url = `${baseUrl}${investmentFirm}/investmentFirm`;
    const strategyUrl = `${url}/strategy/${strategyName}`;
    return (
      <div className="investmentTab">
        <FPLStrategyHeader
          viewParams={viewParams}
          strategy={strategy}
          showWhatIsDueDiligenceRating={showWhatIsDueDiligenceRating}
          showWhatIsDueDiligenceStatus={showWhatIsDueDiligenceStatus}
        />
        <StrategyContents
          viewParams={viewParams}
          documentView={viewParams.documentView}
          strategy={strategy}
          strategyUrl={strategyUrl}
        />
        <DocumentsList
          documentView={viewParams.documentView}
          visible={true}
          fplList={null}
          viewParams={viewParams.newViewParams}
          filterByDocumentType={{}}
        />
        <MeetingNotesModal showPlans={true} consultant={false} />
        <PlanDDEventModal consultant={false} />
        <WhatIsModal />
      </div>
    );
  };

  renderInvestmentFirm = viewParams => {
    if (viewParams.managerSummary) {
      return (
        <div className="investmentTab">
          <Link to={this.props.baseUrl}>
            <Button
              color="primary"
              className="close-Button"
              onClick={this.hideInvestmentFirm}>
              Close
            </Button>
          </Link>
          <ManagerSummaryHeader managerSummary={viewParams.managerSummary} />
          <ManagerContents
            viewParams={viewParams}
            documentView={viewParams.documentView}
            managerSummary={viewParams.managerSummary}
            managerUrl={viewParams.managerUrl}
          />
          <DocumentsList
            documentView={viewParams.documentView}
            visible={true}
            fplList={null}
            viewParams={viewParams.newViewParams}
            filterByDocumentType={{}}
          />
          <PlanDDEventModal consultant={false} />
          <WhatIsModal />
        </div>
      );
    }
    return null;
  };

  componentDidMount() {
    const { investmentFirm } = this.props;
    this.props.selectInvestmentFirm(investmentFirm);
  }

  render() {
    const strategy = this.loadData();
    const { documentView, managerUrl, managerSummary, firmId } = this.props;

    const viewParams = {
      documentView: documentView,
      managerId: firmId,
      managerSummary: managerSummary,
      manager: null,
      managerUrl: managerUrl,
      fplStrategy: strategy,
    };

    if (this.isStrategy()) {
      return this.renderStrategy(strategy, viewParams);
    }
    return this.renderInvestmentFirm(viewParams);
  }
}

InvestmentFirmPanel.propTypes = {
  firmId: PropTypes.string.isRequired,
  selectInvestmentFirm: PropTypes.func.isRequired,
  investmentFirm: PropTypes.string.isRequired,
  strategyName: PropTypes.string,
  loadManagerSummary: PropTypes.func.isRequired,
  managerSummary: PropTypes.object,
  loadManagerStrategies: PropTypes.func.isRequired,
  loadManagerActivities: PropTypes.func.isRequired,
  loadManagerManagerActivities: PropTypes.func.isRequired,
  managerStrategies: PropTypes.object,
  loadManagerDDEvents: PropTypes.func.isRequired,
  documentView: PropTypes.string,
  managerUrl: PropTypes.string,
  user: PropTypes.object,
  baseUrl: PropTypes.string,

  loadFPLNepcOpinion: PropTypes.func.isRequired,
  loadFPLResearchWriteups: PropTypes.func.isRequired,
  loadFPLNepcOpinionResource: PropTypes.func.isRequired,
  loadFPLManagerActivities: PropTypes.func.isRequired,
  showWhatIsDueDiligenceRating: PropTypes.func.isRequired,
  showWhatIsDueDiligenceStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.okta.user,
    managerSummary: state.resources.manager_summary.data,
    selectedInvestmentFirm: state.ui.selectedInvestmentFirm,
    managerStrategies: state.resources.manager_strategies,
  };
};

export default connect(mapStateToProps, {
  loadManagerSummary,
  loadManagerStrategies,
  loadManagerActivities,
  loadManagerManagerActivities,
  selectInvestmentFirm,
  loadFPLNepcOpinion,
  loadFPLResearchWriteups,
  loadFPLNepcOpinionResource,
  loadFPLManagerActivities,
  showWhatIsDueDiligenceRating,
  showWhatIsDueDiligenceStatus,
})(InvestmentFirmPanel);
