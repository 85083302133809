import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseManager } from 'ag-grid-enterprise';

import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import configStore from './store/configStore';

const store = configStore();
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-060719}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{NEPC,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ClientHUB}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ClientHUB}_need_to_be_licensed___{ClientHUB}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_July_2025}____[v3]_[01]_MTc1Mjg3OTYwMDAwMA==a1492c4ea5e1320fa4e4593649eee4ef'
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
