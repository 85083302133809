import axios from 'axios';

import Constants from './Constants';

const MaintenanceService = () => {
  const MAINTENANCE_URL = Constants.OKTA.URL + '/api/v1';

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const saveMaintenance = (accessToken, enabled) =>
    axios.post(
      `${MAINTENANCE_URL}/maintenance`,
      enabled,
      _headers(accessToken)
    );

  const updateMaintenanceImage = (accessToken, newImage) => {
    const fd = new FormData();
    fd.append('file', newImage, newImage.name);
    fd.append('fileName', newImage.name);
    return axios.post(
      `${MAINTENANCE_URL}/maintenance/img`,
      fd,
      _headers(accessToken)
    );
  };

  const updateMaintenanceImageTmp = (accessToken, newImage) => {
    const fd = new FormData();
    fd.append('file', newImage, newImage.name);
    fd.append('fileName', newImage.name);
    return axios.post(
      `${MAINTENANCE_URL}/maintenance/imgTmp?${Math.random()}`,
      fd,
      _headers(accessToken)
    );
  };

  const getImageUrl = () => {
    return axios.get(`${MAINTENANCE_URL}/maintenance/imgUrl?${Math.random()}`);
  };

  const resetMaintenanceImageTmp = accessToken => {
    return axios.post(
      `${MAINTENANCE_URL}/maintenance/imgTmpReset?${Math.random()}`,
      _headers(accessToken)
    );
  };

  return {
    saveMaintenance,
    updateMaintenanceImage,
    updateMaintenanceImageTmp,
    resetMaintenanceImageTmp,
    getImageUrl,
  };
};

export default MaintenanceService;
