import _ from 'lodash';
import { NotificationManager } from 'react-notifications';

import MessagesService from '../../services/MessagesService';
import * as types from './types';
import { withLoading } from '../../actions/CommonActions';
import { logout, getOktaSettings } from '../../actions/OktaActions';
import { addEvent } from '../../actions';
import MaintenanceService from '../../services/MaintenanceService';
import { Events } from '../../services/Constants';
import EventService from '../../services/EventService';

export const loadAll = withLoading(() => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
      messages: { allLoaded },
    } = getState();
    if (accessToken && !allLoaded) {
      dispatch({ type: types.MESSAGES_LOAD_ALL });
      return MessagesService()
        .loadAll(accessToken)
        .then(({ data: all }) =>
          dispatch({ type: types.MESSAGES_LOAD_ALL_SUCCESS, all: all || [] })
        )
        .catch(() => dispatch({ type: types.MESSAGES_LOAD_ALL_FAILED }));
    }
    return Promise.resolve();
  };
});

export const loadLatest = withLoading(() => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    dispatch({ type: types.MESSAGES_LOAD_LATEST });
    const loadLatest = MessagesService().loadLatest(accessToken);
    const loadEvents = EventService().eventsByType(
      accessToken,
      Events.OPEN_ANNOUNCEMENT
    );
    return Promise.all([loadLatest, loadEvents])
      .then(([{ data: latest }, { data: events }]) => {
        const seenMessages = _.map(events, event => event.eventData);
        dispatch({
          type: types.MESSAGES_LOAD_LATEST_SUCCESS,
          latest: latest || [],
          seenMessages,
        });
        const lastMessage = _.first(latest);
        const isUnread =
          lastMessage && !_.includes(seenMessages, lastMessage.id);
        if (isUnread) {
          dispatch(showSystemMessage(lastMessage));
        }
      })
      .catch(() => dispatch({ type: types.MESSAGES_LOAD_LATEST_FAILED }));
  };
});

export const saveMaintenance = withLoading(enabled => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    return MaintenanceService()
      .saveMaintenance(accessToken, enabled)
      .then(result => {
        if (result.data) {
          NotificationManager.success(
            'Maintenance mode enabled/disabled with success',
            'Maintenance Mode',
            2000
          );
          // get the settings to know the maintenance mode state
          _.debounce(() => {
            const okta = dispatch(getOktaSettings());
            // Apply logout only when Okta Settings is loaded
            okta.then(() => dispatch(logout()));
          }, 2000)();
        } else {
          NotificationManager.error(
            'Error to enabled/disabled maintenance mode',
            'Maintenance Mode'
          );
          // rollback last state
          dispatch(updateMaintenanceOption(getOldValue(enabled)));
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to enabled/disabled maintenance mode',
          'Maintenance Mode'
        );
        // rollback last state
        dispatch(updateMaintenanceOption(getOldValue(enabled)));
      });
  };
});

export const getImageUrl = () => {
  return MaintenanceService().getImageUrl();
};

export const updateMaintenanceImage = withLoading(newImage => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    return MaintenanceService()
      .updateMaintenanceImage(accessToken, newImage)
      .then(result => {
        if (result.data) {
          NotificationManager.success(
            'Maintenance image uploaded with success',
            'Maintenance Image',
            2000
          );
        } else {
          NotificationManager.error(
            'Error to upload new image of maintenance mode',
            'Maintenance Image'
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to update new image of maintenance mode',
          'Maintenance Image'
        );
      });
  };
});

export const updateMaintenanceImageTmp = withLoading(newTempImage => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    return MaintenanceService()
      .updateMaintenanceImageTmp(accessToken, newTempImage)
      .then(result => {
        if (result.data) {
          NotificationManager.success(
            'Temp Maintenance image uploaded with success',
            'Temp Maintenance Image',
            2000
          );
        } else {
          NotificationManager.error(
            'Error to upload new Temp image of maintenance mode',
            'Temp Maintenance Image'
          );
        }
      })
      .catch(() => {
        NotificationManager.error(
          'Error to update new Temp image of maintenance mode',
          'Temp Maintenance Image'
        );
      });
  };
});

export const resetMaintenanceTmpImage = () => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    return MaintenanceService().resetMaintenanceImageTmp(accessToken);
  };
};

export const updateMaintenanceOption = selectedField => {
  return dispatch => {
    dispatch({
      type: types.UPDATE_SELECTED_MAINTENANCE_FIELD,
      maintenanceSelectedField: selectedField,
    });
  };
};

export const showSystemMessage = systemMessage => {
  return dispatch => {
    dispatch({ type: types.MESSAGES_SHOW, systemMessage });
  };
};

export const showLatestSystemMessages = (left, top, visible = true) => {
  return dispatch => {
    dispatch({ type: types.MESSAGES_SHOW_LATEST, left, top, visible });
  };
};

export const hideLatestSystemMessages = () => {
  return showLatestSystemMessages(0, 0, false);
};

export const hideSystemMessage = () => {
  return showSystemMessage(null);
};

export const markSystemMessageSeen = messageIds => {
  return dispatch => {
    dispatch(addEvent(Events.OPEN_ANNOUNCEMENT, messageIds));
    dispatch({ type: types.MESSAGES_MARK_SEEN, messageIds });
  };
};

export const saveSystemMessage = withLoading((systemMessage, success) => {
  return (dispatch, getState) => {
    const {
      okta: {
        accessToken,
        user: { email: userName },
      },
    } = getState();
    const today = new Date().toISOString();
    if (!systemMessage.createdBy) {
      return dispatch(
        saveSystemMessage(
          {
            ...systemMessage,
            createdBy: userName,
            createdOn: today,
          },
          success
        )
      );
    }
    dispatch({ type: types.MESSAGES_SAVE, id: systemMessage.id });

    const isNew = systemMessage.id === types.MESSAGES_NEW;
    const id = isNew ? null : systemMessage.id;
    return MessagesService()
      .save(accessToken, {
        ...systemMessage,
        modifiedBy: userName,
        modifiedOn: today,
        id,
      })
      .then(({ data: newSystemMessage }) => {
        const {
          messages: { all },
        } = getState();

        const update = messages => {
          if (isNew) {
            return [...messages, newSystemMessage];
          }
          return _.map(messages, message => {
            if (message.id === newSystemMessage.id) {
              return newSystemMessage;
            }
            return message;
          });
        };

        dispatch({
          type: types.MESSAGES_SAVE_SUCCESS,
          systemMessage: newSystemMessage,
          all: update(all),
        });

        dispatch(loadLatest());

        NotificationManager.success(
          'Announcement successfully saved.',
          'Announcements'
        );
        success();
      })
      .catch(() => {
        dispatch({ type: types.MESSAGES_SAVE_FAILED });
        NotificationManager.error(
          'Error saving announcement.',
          'Announcements'
        );
      });
  };
});

const getOldValue = enabled => {
  return enabled === 'disabledMaintenance'
    ? 'enabledMaintenance'
    : 'disabledMaintenance';
};
