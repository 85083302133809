import axios from 'axios';
import download from 'downloadjs';

import Constants from './Constants';

const PrintService = () => {
  const PRINT_URL = Constants.PRINT.URL;

  const generatePdf = (data, template, orientation, contents) =>
    axios.post(
      `${PRINT_URL}/generatePdf`,
      { template, contents, orientation, data },
      {
        responseType: 'arraybuffer',
      }
    );

  const printHtmlPdf = (data, fileName, template, orientation, contentHTML) => {
    return generatePdf(data, template, orientation, contentHTML).then(
      ({ data: file }) => {
        download(file, fileName);
      }
    );
  };

  return {
    printHtmlPdf,
  };
};

export default PrintService;
