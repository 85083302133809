import React from 'react';
import { Container, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

import Constants from '../services/Constants';

const Footer = () => {
  return (
    <Container className="footer">
      <NavLink href="http://www.nepc.com" id="nepc-link">
        www.nepc.com
      </NavLink>
      <span className="pipe">|</span>
      <NavLink
        href="https://nepc.com/institutional/privacy-statement/"
        id="privacy-link">
        Privacy Statement
      </NavLink>
      <span className="pipe">|</span>
      <RouterNavLink className="nav-link" to="/terms" id="terms-link">
        Terms and Conditions
      </RouterNavLink>

      <span className="buildId">Build: {Constants.BUILD_ID}</span>
    </Container>
  );
};

export default Footer;
