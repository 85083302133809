import React from 'react';
import PropTypes from 'prop-types';

import BaseHeader from './BaseHeader';

export class ManagerSummaryHeader extends React.Component {
  render() {
    const headers = [
      { title: 'Website', field: 'website' },
      // { title: 'NEPC Client Exposure to Firm', field: 'sumOfClientExposureIv' },
      { title: 'Firm Due Diligence Status', field: 'duediligenceStatus' },
    ];
    const { managerSummary } = this.props;
    const title = managerSummary.firmName;
    let websiteUrl = managerSummary.website;
    if (
      websiteUrl &&
      !(websiteUrl.indexOf('http://') > -1) &&
      !(websiteUrl.indexOf('https://') > -1)
    ) {
      websiteUrl = 'http://' + websiteUrl;
    }
    const data = {
      ...managerSummary,
      // sumOfClientExposureIv: formatCurrency(
      //   managerSummary.sumOfClientExposureIv
      // ),
      website: (
        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
          {websiteUrl}
        </a>
      ),
    };

    return (
      <BaseHeader
        headers={headers}
        data={data}
        title={title}
        isSingleColumn={true}
      />
    );
  }
}

ManagerSummaryHeader.propTypes = {
  managerSummary: PropTypes.object.isRequired,
};
export default ManagerSummaryHeader;
