import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SimpleTabsMenu from '../../tabsmenu/SimpleTabsMenu';
import { Constants } from '../../../services/Constants';

export const LandingMenu = props => {
  const options = [{ title: Constants.PLAN_PORTFOLIO_HOLDINGS, path: '/' }];
  let {
    viewParams: { planUrl },
    documentView,
    hasPortfolioDDs,
    hasManagerNotes,
  } = props;
  if (documentView === Constants.PLAN_PORTFOLIO) {
    documentView = Constants.PLAN_PORTFOLIO_HOLDINGS;
  }

  if (hasManagerNotes || hasPortfolioDDs) {
    options.push({
      title: Constants.PLAN_MANAGER_NOTES_VIEW,
      path: '/activities',
    });
  }

  return (
    <div>
      <SimpleTabsMenu
        options={options}
        baseUrl={planUrl + '/manager'}
        selected={documentView}
        prefix="Portfolio "
      />
    </div>
  );
};
LandingMenu.propTypes = {
  viewParams: PropTypes.object,
  documentView: PropTypes.string.isRequired,
  hasPortfolioDDs: PropTypes.bool.isRequired,
  hasManagerNotes: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const selectedPlan = state.ui.selectedPlan;
  return {
    hasManagerNotes:
      (selectedPlan && selectedPlan.planMmnIndicator === 'enabled') || false,
  };
};

export default connect(mapStateToProps)(LandingMenu);
