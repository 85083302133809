import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

export const SimpleTabsMenu = ({
  baseUrl,
  options,
  selected,
  prefix,
  prefix2 = '',
}) => {
  const menuItems = _.map(options, menuItem => {
    const { title, path } = menuItem;
    const isActive = selected === title;
    const activeClass = isActive ? 'active' : '';
    const displayTitle = title
      .replace(prefix || '', '')
      .replace(prefix2 || '', '');
    return (
      <NavItem key={title}>
        <Link className={`nav-link ${activeClass}`} to={`${baseUrl}${path}`}>
          {displayTitle}
        </Link>
      </NavItem>
    );
  });
  return (
    <Nav tabs className="mb-4">
      {menuItems}
    </Nav>
  );
};
SimpleTabsMenu.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  prefix: PropTypes.string,
  prefix2: PropTypes.string,
};

export default SimpleTabsMenu;
