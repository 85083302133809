import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';

import Disclaimer from '../../../components/Disclaimer';
import MyPlans from './MyPlans';

export const AllPlans = props => {
  const [plans, setPlans] = useState(props.plansByLetter);
  const [filter, setFilter] = useState();

  useEffect(() => {
    setPlans(props.plansByLetter);
  }, [props.plansByLetter]);

  async function plansRequest(letter) {
    await props.requestPlansForLetter(letter);
  }

  const filterPlans = newFilter => {
    if (newFilter !== filter) {
      plansRequest(newFilter);
    }
    setFilter(newFilter);
  };

  const renderFilter = () => {
    const alphabet = '#abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
    return (
      <div className="filterList">
        {_.map(alphabet, filter => (
          <Button
            key={filter}
            onClick={() => filterPlans(filter)}
            id={`${filter}-button`}>
            {filter}
          </Button>
        ))}
      </div>
    );
  };

  const noPlansMessage = () => {
    return <div className="no-plans-message">{`No plans available`}</div>;
  };

  return (
    <div>
      <Disclaimer id="allPlansDisclaimer" name="allPlansDisclaimer col-lg-5">
        <ul>
          <li>
            <b>Published</b> portfolios have an assigned ClientHUB Administrator
            in Backstop and are visible to clients with permission to access
            them.
          </li>
          <li>
            <b>Unpublished</b> portfolios do not have a ClientHUB Administrator
            in Backstop and will not show for any clients until the ClientHUB
            Administrator is defined in Backstop.
          </li>
          <li>
            You can <b>Hide Unpublished Portfolios</b> from this view using the
            toggle at the top of this page.
          </li>
          <li>
            See{' '}
            <a href="https://central.nepc.com/x/OhZwDw">
              ClientHUB Set-Up Checklist
            </a>{' '}
            for more details on configuring the client experience.
          </li>
        </ul>
      </Disclaimer>
      <Row>
        <Col lg={10} className="filter">
          {renderFilter()}
        </Col>
      </Row>
      {props.emptyLetter && noPlansMessage()}
      <MyPlans plans={plans} isMyPlans={false} />
    </div>
  );
};

AllPlans.propTypes = {
  plansByLetter: PropTypes.array.isRequired,
  requestPlansForLetter: PropTypes.func.isRequired,
  emptyLetter: PropTypes.bool,
};

export default AllPlans;
