import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

export const BaseHeader = ({
  headers,
  data,
  title,
  subtitle = null,
  disclaimer = null,
  isSingleColumn = false,
  documentType = '',
}) => {
  if (!data) {
    return null;
  }
  const history = useHistory();
  const leftElements = [];
  const rightElements = [];
  let current = leftElements;
  let other = rightElements;
  _.each(headers, header => {
    if (header.field !== '' && header.title !== '') {
      const el = (
        <Col md={12} key={header.field}>
          <span className="detailTitle">{header.title}: </span>
          {data[header.field]}
        </Col>
      );
      current.push(el);
    }
    if (!isSingleColumn) {
      [current, other] = [other, current];
    }
  });
  const subtitleElement = subtitle && (
    <Col md={12}>
      <h5>{subtitle}</h5>
    </Col>
  );

  const elementsRender = colElementsRender(
    isSingleColumn,
    leftElements,
    rightElements
  );

  const back = () => {
    history.goBack();
  };

  return (
    <Row className="fpl-header">
      <div>
        <div className="back-button noPrint" onClick={back}>
          <i className="fas fa-chevron-left icon"></i>
          <span className="text"> Back</span>
        </div>
        {disclaimer}
      </div>
      <Col md={12} className="noPrint">
        <h4>{title}</h4>
      </Col>
      {subtitleElement}
      {elementsRender}
    </Row>
  );
};

const colElementsRender = (isSingleColumn, leftElements, rightElements) => {
  if (isSingleColumn) {
    return (
      <Col md={12}>
        <Row>{leftElements}</Row>
      </Col>
    );
  }

  return (
    <Col md={12}>
      <Row>
        <Col md={6}>
          <Row>{leftElements}</Row>
        </Col>
        <Col md={6}>
          <Row>{rightElements}</Row>
        </Col>
      </Row>
    </Col>
  );
};

BaseHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.object,
  disclaimer: PropTypes.element,
  isSingleColumn: PropTypes.bool,
  documentType: PropTypes.string,
};

export default BaseHeader;
