import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';

import { openDocument } from '../../../actions';

export const OpenDocumentLink = props => {
  const onClick = () => props.openDocument(props.data);
  return (
    <NavLink className="gridLink" href="#" onClick={onClick}>
      {props.data.title}
    </NavLink>
  );
};

OpenDocumentLink.propTypes = {
  openDocument: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default connect(null, { openDocument })(OpenDocumentLink);
