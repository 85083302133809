import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import Constants from './Constants';

const OktaService = () => {
  const OKTA_URL = Constants.OKTA.URL;

  const getSettings = () => axios.get(`${OKTA_URL}/sign-in-widget-config`);

  const loginExists = email =>
    axios.get(`${OKTA_URL}/api/v1/loginExists?email=${email}`);

  const register = user => axios.post(`${OKTA_URL}/api/v1/register`, user);

  const changePassword = (accessToken, userId, oldPassword, newPassword) =>
    axios.post(`${OKTA_URL}/api/v1/changePassword?userId=${userId}`, {
      oldPassword: { value: oldPassword },
      newPassword: { value: newPassword },
    });

  const loadRoles = userId =>
    axios.get(`${OKTA_URL}/api/v1/groups?userId=${userId}`);

  const loadNotificationsFilter = () =>
    axios.get(
      `${OKTA_URL}/api/v1/maintenance/notificationFilter?${Math.random()}`
    );

  const updateNotifications = notifications =>
    axios
      .post(`${OKTA_URL}/api/v1/maintenance/notificationFilter`, {
        ...notifications,
      })
      .then(() => {
        return NotificationManager.success(
          'Success saving notification parameters',
          ''
        );
      })
      .catch(() => {
        return NotificationManager.error(
          'Error saving notification parameters',
          ''
        );
      });

  return {
    getSettings,
    loginExists,
    register,
    changePassword,
    loadRoles,
    updateNotifications,
    loadNotificationsFilter,
  };
};

export default OktaService;
