import React from 'react';

const LoadingComponentOverlay = () => {
  return (
    <div className="loaderWrapperInGrid">
      <div className="spinnerContainer">
        <div className="spinnerIcon" />
      </div>
    </div>
  );
};

export default LoadingComponentOverlay;
