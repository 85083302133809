import axios from 'axios';

import Constants from './Constants';

const CrmService = () => {
  const CRM_URL = Constants.CRM.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const saveProfile = (accessToken, profile) =>
    axios.put(`${CRM_URL}/user-profile`, profile, _headers(accessToken));

  const toggleUser = (
    accessToken,
    // eslint-disable-next-line
    user,
    EnabledInExtranet
  ) =>
    axios.put(
      `${CRM_URL}/update-status`,
      {
        // eslint-disable-next-line
        contactId: user.contactId,
        planId: user.planId,
        email: user.email,
        accessEnabled: EnabledInExtranet,
      },
      _headers(accessToken)
    );

  return {
    saveProfile,
    toggleUser,
  };
};

export default CrmService;
