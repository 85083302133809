import * as types from '../actions/Types';
import { RequestReducer, RequestInitReducer } from '../utils/Request';

const DEFAULT_STATE = {
  redirect: null,
  logout: false,
  loadingCount: 0,
  isLoading: false,
  selectedPlan: null,
  adminSavedSuccessfully: false,
  whatIsVisible: null,
  filterByDocumentType: {},
  filterDocumentOnGrid: {},
  confirmationModalVisible: false,
  feedbackVisible: false,
  feedbackItem: null,
  feedbackClear: 0,
  ddEvent: {},
  meetingNotes: {},
  sortModel: [],
  hideUnpublished: false,
  employeeMeetingNotesAllManagerSwitch: false,
  employeeDDEventsAllManagerSwitch: false,
  ...RequestInitReducer([types.UI_FEEDBACK, types.UI_OPEN_PLAN]),
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.SELECT_PLAN: {
      return {
        ...state,
        selectedPlan: action.plan,
        filterByDocumentType: {},
        filterDocumentOnGrid: {},
        selectedInvestmentFirm: null,
      };
    }
    case types.REDIRECT: {
      return {
        ...state,
        redirect: action.to,
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        logout: action.value,
      };
    }
    case types.LOADING: {
      const loadingCount = state.loadingCount + action.change;
      const isLoading = loadingCount > 0;
      return {
        ...state,
        loadingCount,
        isLoading,
      };
    }
    case types.USER_ACTIVATIONS_FAILED:
    case types.USER_ACTIVATIONS_STARTED: {
      return {
        ...state,
        adminSavedSuccessfully: false,
      };
    }
    case types.USER_ACTIVATIONS_SUCCESS: {
      return {
        ...state,
        adminSavedSuccessfully: true,
      };
    }
    case types.TOGGLE_WHAT_IS: {
      return {
        ...state,
        whatIsVisible: action.whatIsVisible,
      };
    }
    case types.FILTER_DOCUMENT_BY_TYPE: {
      return {
        ...state,
        filterByDocumentType: {
          ...state.filterByDocumentType,
          [action.documentView]: action.filter,
        },
      };
    }
    case types.FILTER_DOCUMENT_ON_GRID: {
      return {
        ...state,
        filterDocumentOnGrid: {
          key: action.filterKey,
          ids: action.filteredIDs,
          hasFilter: action.hasFilter,
        },
      };
    }
    case types.TOGGLE_FEEDBACK_MODAL: {
      return {
        ...state,
        feedbackVisible: action.visible,
        feedbackItem: action.data,
      };
    }
    case types.CLEAR_FEEDBACK_MODAL: {
      return {
        ...state,
        feedbackClear: state.feedbackClear + 1,
      };
    }

    case types.TOGGLE_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModalVisible: action.visible,
      };
    }
    case types.DISPLAY_MEETING_NOTES_SUCCESS: {
      return {
        ...state,
        meetingNotes: action.meetingNotes,
      };
    }
    case types.SHOW_DD_EVENT: {
      return {
        ...state,
        ddEvent: action.ddEvent,
      };
    }
    case types.SORT_CHANGE: {
      return {
        ...state,
        sortModel: action.sortModel,
      };
    }
    case types.HIDE_UNPUBLISHED_PLANS: {
      return {
        ...state,
        hideUnpublished: action.hide,
      };
    }
    case types.PDF_CANVAS_SIZE: {
      return {
        ...state,
        pdfCanvasSize: action.pdfCanvasSize,
      };
    }
    case types.INVESTMENT_FIRM: {
      return {
        ...state,
        selectedInvestmentFirm: action.investmentFirm,
      };
    }
    case types.EMPLOYEE_ACTIVE_TAB: {
      return {
        ...state,
        employeeActiveTab: action.employeeActiveTab,
      };
    }
    case types.EMPLOYEE_MANAGER_MEETING_NOTES_SWITCH: {
      return {
        ...state,
        employeeMeetingNotesAllManagerSwitch: action.allManagers,
      };
    }
    case types.EMPLOYEE_DD_EVENTS_SWITCH: {
      return {
        ...state,
        employeeDDEventsAllManagerSwitch: action.allManagers,
      };
    }
    default: {
      return RequestReducer(
        state,
        [types.UI_FEEDBACK, types.UI_OPEN_PLAN],
        action
      );
    }
  }
};
