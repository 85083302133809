import * as types from './types';
import { RequestReducer, RequestInitReducer } from '../../utils/Request';

export const DEFAULT_STATE = {
  ...RequestInitReducer([
    types.TERMS_LATEST,
    types.TERMS_ALL,
    types.TERMS_SAVE,
  ]),
  accepted: undefined,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.TERMS_ACCEPTED: {
      return { ...state, accepted: true };
    }
    case types.TERMS_MUST_ACCEPT: {
      return { ...state, accepted: false };
    }
    default:
      return RequestReducer(
        state,
        [types.TERMS_LATEST, types.TERMS_ALL, types.TERMS_SAVE],
        action
      );
  }
};
