import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { slug, slugInitialLetter } from '../../../services/SlugService';
import Constants from '../../../services/Constants';

export const OpenManagerStrategy = props => {
  if (!props.value) {
    return null;
  }

  const generateLink = () => {
    const { selectedPlan, selectedinvestmentFirm } = props;
    if (selectedPlan) {
      const clientName = _.get(props, 'selectedPlan.clientName') || '';
      const planName = _.get(props, 'selectedPlan.planName') || '';
      const managerName =
        _.get(props, 'data.firmName') ||
        _.get(props, 'data.investmentFirmName') ||
        '';
      const planUrl = `/portfolio/${slugInitialLetter(clientName)}/${slug(
        planName
      )}`;
      const slugManager = slug(managerName);
      const slugStrategy = slug(props.value);

      const managerUrl = `${planUrl}/manager/${slugManager}`;

      const strategyUrl = `${managerUrl}/strategy/${slugStrategy}`;

      if (managerName === Constants.INTERNALLY_MANAGED) {
        return <span>{props.value}</span>;
      }
      return renderLink(props.value, strategyUrl);
    }
    if (selectedinvestmentFirm) {
      const slugStrategy = slug(props.value);
      const url =
        props.employeeActiveTab === Constants.CR_MEETING_NOTES_VIEW
          ? `/consultingResources/meetingnotes/${selectedinvestmentFirm}/investmentFirm/strategy/${slugStrategy}`
          : `/consultingResources/dds/${selectedinvestmentFirm}/investmentFirm/strategy/${slugStrategy}`;
      return renderLink(props.value, url);
    }
  };

  const renderLink = (value, url) => {
    return (
      <Link
        className="gridLink"
        push="true"
        to={url}
        id={`${props.value}-${props.rowIndex}`}>
        {value}
      </Link>
    );
  };

  return (
    <div>
      <span>{generateLink()}</span>
      <span className="printItem">{props.value}</span>
    </div>
  );
};

OpenManagerStrategy.propTypes = {
  selectedPlan: PropTypes.object,
  selectedinvestmentFirm: PropTypes.string,
  value: PropTypes.string,
  data: PropTypes.object,
  employeeActiveTab: PropTypes.string,
  rowIndex: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.ui.selectedPlan,
    selectedinvestmentFirm: state.ui.selectedInvestmentFirm,
    employeeActiveTab: state.ui.employeeActiveTab,
  };
};

export default connect(mapStateToProps, null)(OpenManagerStrategy);
