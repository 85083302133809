import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadNepcPublished, loadMarketUpdateDocs } from '../../../actions';
import { Constants } from '../../../services/Constants';
import PDFViewer from '../../pdf/PDFViewer';
import {
  compareWithToday,
  columnDateFormatterComparator,
} from '../../../services/DateService';

const SELECTED_TAB = 0;

export class MarketUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: SELECTED_TAB,
    };
  }

  toggle = (index, link) => {
    this.setState({ activeTab: index });
    this.renderPDFContent(link);
  };

  checkMarketUpdatesViewed = docID => {
    const docs = this.props.publishedDocs;
    const docsViewed = this.props.marketUpdateDocsViewed;
    const badge = <div className={'badge notification-flag'}> </div>;
    if (docs && docsViewed) {
      const docsFound = docsViewed.filter(
        dv => parseInt(dv.eventData, 10) === docID
      ).length;
      return docsFound === 0 ? badge : null;
    }
  };

  getFilteredDocuments = () => {
    const docs = this.props.publishedDocs
      .filter(
        doc =>
          doc.publishingLocation === Constants.DOCUMENT_MARKET_UPDATES &&
          compareWithToday(doc.expirationDate)
      )
      .sort((a, b) =>
        columnDateFormatterComparator(a.publishedDate, b.publishedDate)
      );
    return docs.reverse();
  };

  renderPDFTab = () => {
    const documents = this.getFilteredDocuments();

    const selected = this.state.activeTab;
    const docs = documents.map((doc, index) => (
      <NavItem key={index}>
        <NavLink
          className={selected === index ? 'active' : ''}
          href="#"
          onClick={() => {
            this.toggle(index, doc.link);
          }}>
          {doc.title}
          {this.checkMarketUpdatesViewed(doc.objectDocumentId)}
        </NavLink>
      </NavItem>
    ));
    return <Nav tabs>{docs}</Nav>;
  };

  renderPDFContent = () => {
    const selectedTabIndex = this.state.activeTab;
    const doc = this.getFilteredDocuments()[selectedTabIndex];
    if (doc) {
      return <PDFViewer documentFile={doc} page={1} />;
    }
    return (
      <div className="no-documents">
        No Market Updates are available at this time
      </div>
    );
  };

  render() {
    if (this.props.publishedDocs.length === 0) {
      return null;
    }

    return (
      <section className={`tabs pdfs`}>
        {this.renderPDFTab()}
        {this.renderPDFContent()}
      </section>
    );
  }
}

MarketUpdates.propTypes = {
  loadNepcPublished: PropTypes.func,
  publishedDocs: PropTypes.array,
  marketUpdateDocsViewed: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    publishedDocs: state.resources.nepc_published.data || [],
    marketUpdateDocsViewed: state.resources.market_update_docs.data || [],
  };
};

export default connect(mapStateToProps, {
  loadNepcPublished,
  loadMarketUpdateDocs,
})(MarketUpdates);
