export const MESSAGES_LOAD_LATEST = 'MESSAGES/LOAD_LATEST_START';
export const MESSAGES_LOAD_LATEST_SUCCESS = 'MESSAGES/LOAD_LATEST_SUCCESS';
export const MESSAGES_LOAD_LATEST_FAILED = 'MESSAGES/LOAD_LATEST_FAILED';

export const MESSAGES_LOAD_ALL = 'MESSAGES/LOAD_ALL_START';
export const MESSAGES_LOAD_ALL_SUCCESS = 'MESSAGES/LOAD_ALL_SUCCESS';
export const MESSAGES_LOAD_ALL_FAILED = 'MESSAGES/LOAD_ALL_FAILED';

export const MESSAGES_SHOW = 'MESSAGES/SHOW';
export const MESSAGES_SHOW_LATEST = 'MESSAGES/SHOW/LATEST';
export const MESSAGES_MARK_SEEN = 'MESSAGES/MARK_SEEN';

export const MESSAGES_SAVE = 'MESSAGES/SAVE';
export const MESSAGES_SAVE_SUCCESS = 'MESSAGES/SAVE_SUCCESS';
export const MESSAGES_SAVE_FAILED = 'MESSAGES/SAVE_FAILED';

export const MESSAGES_NEW = 'MESSAGES/NEW';

export const UPDATE_SELECTED_MAINTENANCE_FIELD =
  'UPDATE_SELECTED_MAINTENANCE_FIELD';
