import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

const TeamMember = ({ member, picture }) => {
  const { title, email, phone, name } = member;

  return (
    <div className="teamMember">
      <Media>
        <Media left href="#">
          <Media object src={picture} alt={name} className="img-thumbnail" />
        </Media>
        <Media body>
          <Media heading>{name}</Media>
          <div>{title}</div>
          <div>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
          <div>
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </Media>
      </Media>
    </div>
  );
};

TeamMember.propTypes = {
  member: PropTypes.object.isRequired,
  picture: PropTypes.any,
};

export default TeamMember;
