import * as types from './types';

export const DEFAULT_STATE = {
  success: false,
  error: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_RESET:
    case types.CHANGE_PASSWORD_STARTED: {
      return { ...state, success: false, error: null };
    }
    case types.CHANGE_PASSWORD_SUCCEED: {
      return { ...state, success: true, error: null };
    }
    case types.CHANGE_PASSWORD_FAILED: {
      return { ...state, success: false, error: action.reason };
    }
    default: {
      return state;
    }
  }
};
