import React from 'react';

import AppRouter from './router/AppRouter';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-quill/dist/quill.snow.css';
import 'react-notifications/lib/notifications.css';
import 'react-day-picker/lib/style.css';
import '../styles/css/app.css';
import '../polyfills';
import packageJson from '../../package.json';

global.appVersion = packageJson.version;

const App = () => <AppRouter />;

export default App;
