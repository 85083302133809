import PropTypes from 'prop-types';

export default PropTypes.shape({
  documentsView: PropTypes.string,

  plan: PropTypes.object,
  planUrl: PropTypes.string,

  fplList: PropTypes.array,
  fplListUrl: PropTypes.string,

  fplStrategy: PropTypes.object,
  fplStrategyUrl: PropTypes.string,

  managerId: PropTypes.string,
  managerSummary: PropTypes.object,
  managerUrl: PropTypes.string,
});
