import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import _ from 'lodash';

import NotesModal from './NotesModal';
import {
  displayMeetingNotes,
  printMeetingNotes,
  loadEmployeeMeetingNotesPlans,
} from '../../../actions';
import { formatDate } from '../../../services/DateService';
import { slug, slugInitialLetter } from '../../../services/SlugService';

const headerLayout = [
  [
    { field: 'firm', title: 'Investment Firm' },
    {
      field: 'meetingDate',
      title: 'Meeting Date',
      formatter: formatDate,
    },
  ],
  [
    { field: 'author', title: 'NEPC Author' },
    { field: 'location', title: 'Location' },
  ],
  //  TODO: in task documentation have no data about this field, but backend is not retrieving
  [{ field: 'managerRepresentatives', title: 'Manager Representatives' }],
];

const contentLayout = [
  { title: 'Description', field: 'description', type: 'html' },
];

const whenMeetingNotesIsEmpty = (
  <span className="font-italic">
    The content of this note is governed by a non-disclosure agreement. Please
    contact your consultant for details.
  </span>
);

export class MeetingNotesModal extends Component {
  static MAX_AFFECTED_STRATEGIES_LEN = 8;

  constructor(props) {
    super(props);
    this.state = {
      affectedStrategiesExpanded: false,
      showAffectedPlans: true,
      showWarText: true,
    };
  }

  onToggle = () => {
    this.props.displayMeetingNotes(null);
  };

  renderDisclaimer() {
    if (this.state.showWarText) {
      return (
        <p className="text-justify disclaimer">
          This manager meeting note reflects our best effort to summarize a
          meeting between NEPC and representatives from an outside investment
          organization. This meeting note is not a full due diligence profile of
          the investment management firm, strategy or strategies described in
          the note. This note was prepared by and is the property of NEPC, is
          provided for informational purposes only, and may not be
          re-distributed. The opinions expressed in the note should not in any
          way be considered a recommendation. It should not be construed as an
          offer to sell or the solicitation of an offer to buy the securities or
          instruments mentioned. Any information contained herein is subject to
          change at any time. For more information or a full profile of the
          investment manager or strategy (if available), please contact your
          NEPC consultant.
        </p>
      );
    }
    return null;
  }

  calculateNewData(hasContent) {
    const {
      meeting,
      user: { email },
      affectedPlans,
      affectedStrategies,
      showPlans,
      consultant,
    } = this.props;
    if (hasContent && showPlans) {
      this.props.loadEmployeeMeetingNotesPlans(email, meeting.writeUpId);

      const strategieNames = _.map(
        affectedStrategies,
        strategy => ' ' + strategy.name
      );
      if (affectedPlans.length > 0) {
        const links = _.map(affectedPlans, plan =>
          this.openPlanMeetingNotes(plan.clientName, plan.planName)
        );
        return {
          ...meeting,
          injectedPlansField: <span>{links}</span>,
          injectedStrategiesField: (
            <span>{this.renderAffectedStrategies(strategieNames)}</span>
          ),
        };
      }
      if (consultant) {
        return {
          ...meeting,
          injectedPlansField: 'None of your clients are affected',
          injectedStrategiesField: (
            <span>{this.renderAffectedStrategies(strategieNames)}</span>
          ),
        };
      }
      return {
        ...meeting,
        injectedStrategiesField: (
          <span>{this.renderAffectedStrategies(strategieNames)}</span>
        ),
      };
    }
    return meeting;
  }

  renderAffectedStrategiesLink = () => {
    const { affectedStrategiesExpanded } = this.state;
    const title = affectedStrategiesExpanded
      ? '[ show less ]'
      : ' [ show more ]';
    const onToggle = () =>
      this.setState({
        affectedStrategiesExpanded: !affectedStrategiesExpanded,
      });
    return (
      <Button color="link" className="whatIsLink" onClick={onToggle}>
        {title}
      </Button>
    );
  };

  renderAffectedStrategies = affectedStrategies => {
    if (
      affectedStrategies &&
      affectedStrategies.length > MeetingNotesModal.MAX_AFFECTED_STRATEGIES_LEN
    ) {
      const truncated =
        affectedStrategies.slice(
          0,
          MeetingNotesModal.MAX_AFFECTED_STRATEGIES_LEN
        ) + '... ';
      const text = this.state.affectedStrategiesExpanded
        ? affectedStrategies.toString()
        : truncated;
      return (
        <span>
          {text} {this.renderAffectedStrategiesLink()}
        </span>
      );
    }
    return affectedStrategies.toString();
  };

  openPlanMeetingNotes = (clientName, planName) => {
    const slugPlanName = slug(planName);
    const slugLetter = slugInitialLetter(clientName);
    const url = `/portfolio/${slugLetter}/${slugPlanName}/manager/meeting-notes`;
    return (
      <a
        key={planName}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className="employeePlanMeetingNotesLink">
        {planName}
      </a>
    );
  };

  calculateHeaderLayout() {
    const { consultant } = this.props;
    if (this.props.showPlans && this.state.showAffectedPlans) {
      const injectedPlansField = [
        {
          field: 'injectedPlansField',
          title: consultant ? 'My Affected Plans' : 'Affected Plans',
        },
      ];
      const injectedStrategiesField = [
        { field: 'injectedStrategiesField', title: 'Strategies' },
      ];
      if (consultant) {
        return [...headerLayout, injectedStrategiesField, injectedPlansField];
      }
      return [...headerLayout, injectedPlansField, injectedStrategiesField];
    }
    return headerLayout;
  }

  showAffectedPlans = showAffectedPlans => {
    this.setState({ showAffectedPlans });
  };

  showWarText = showWarText => {
    this.setState({ showWarText });
  };

  render() {
    const { meeting, user } = this.props;
    const title = _.get(meeting, 'subject', '');
    const hasContent =
      meeting && _.some(contentLayout, content => !!meeting[content.field]);

    return (
      <NotesModal
        headerLayout={this.calculateHeaderLayout()}
        contentLayout={contentLayout}
        toggle={this.onToggle}
        disclaimer={this.renderDisclaimer()}
        documentType={'Manager Meeting Notes'}
        data={this.calculateNewData(hasContent)}
        title={title}
        printable={hasContent || false}
        printDocument={this.props.printMeetingNotes}
        user={user}
        whenEmpty={whenMeetingNotesIsEmpty}
        showAffectedPlans={this.showAffectedPlans}
        showWarText={this.showWarText}
      />
    );
  }
}

MeetingNotesModal.propTypes = {
  loadEmployeeMeetingNotesPlans: PropTypes.func.isRequired,
  displayMeetingNotes: PropTypes.func.isRequired,
  printMeetingNotes: PropTypes.func.isRequired,
  meeting: PropTypes.object,
  user: PropTypes.object,
  affectedStrategies: PropTypes.array,
  affectedPlans: PropTypes.array,
  showPlans: PropTypes.bool.isRequired,
  consultant: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {
    okta: { user },
  } = state;
  return {
    meeting: state.ui.meetingNotes,
    affectedPlans:
      _.get(
        state,
        'resources.employee_meeting_notes_plans.data.simpleClientPlan'
      ) || [],
    affectedStrategies:
      _.get(state, 'resources.employee_meeting_notes_plans.data.strategies') ||
      [],
    user,
  };
};

export default connect(mapStateToProps, {
  displayMeetingNotes,
  printMeetingNotes,
  loadEmployeeMeetingNotesPlans,
})(MeetingNotesModal);
