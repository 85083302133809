import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { redirectPlan, loadFocusedPlacementList } from '../../actions/index';
import { slug } from '../../services/SlugService';
import Constants from '../../services/Constants';
import viewParams from './ViewParams';
import StrategyRouter from './StrategyRouter';
import MyManagersRouter from './MyManagersRouter';
import { loadFocusedPlacementLists } from '../../actions/Resources';

class FPLRouter extends Component {
  onLists = () => {
    return this.props.onDocuments(
      Constants.PLAN_FOCUSED_PLACEMENT_LISTS,
      this.props.viewParams
    );
  };

  onListStrategies = (_props, viewParams) => {
    return this.props.onDocuments(
      Constants.PLAN_FOCUSED_PLACEMENT_LIST,
      viewParams
    );
  };

  onManager = ({ match }, viewParams) => {
    const selectedPlan = viewParams.plan;
    return (
      <MyManagersRouter
        match={match}
        selectedPlan={selectedPlan}
        onDocuments={this.props.onDocuments}
        viewParams={viewParams}
      />
    );
  };

  onList = ({ match }) => {
    const { focusedPlacementLists, loadFocusedPlacementList } = this.props;
    const { slugFplList } = match.params;
    const fplList = _.find(
      _.values(focusedPlacementLists),
      fplList => slug(fplList.listName) === slugFplList
    );
    if (!fplList) {
      return this.onLists();
    }
    loadFocusedPlacementList(fplList);
    const viewParams = {
      ...this.props.viewParams,
      fplList,
      fplListUrl: match.url,
    };
    return (
      <Switch>
        <Route
          path={`${match.url}/manager`}
          render={props => this.onManager(props, viewParams)}
        />
        <Route
          path={`${match.url}/:slugStrategy`}
          render={props => this.onStrategy(props, viewParams)}
        />
        <Route
          path={`${match.url}`}
          render={props => this.onListStrategies(props, viewParams)}
        />
      </Switch>
    );
  };

  onStrategy = ({ match }, viewParams) => {
    const { focusedPlacementList } = this.props;
    const { slugStrategy } = match.params;
    const fplStrategy = _.find(
      _.values(focusedPlacementList),
      strategy => slug(strategy.investmentStrategyName) === slugStrategy
    );
    if (!fplStrategy) {
      return this.onListStrategies(viewParams);
    }

    return (
      <StrategyRouter
        match={match}
        strategy={fplStrategy}
        onDocuments={this.props.onDocuments}
        viewParams={viewParams}
      />
    );
  };

  loadLists = _.debounce(() => {
    this.props.loadFocusedPlacementLists();
  }, 200);

  render() {
    const {
      match,
      redirectPlan,
      selectedPlan,
      focusedPlacementLists,
    } = this.props;

    if (!selectedPlan.planFplIndicator) {
      redirectPlan('/client');
    }
    if (_.isEmpty(_.values(focusedPlacementLists))) {
      this.loadLists();
      return this.onLists();
    }
    return (
      <Switch>
        <Route path={`${match.url}/:slugFplList`} render={this.onList} />
        <Route path={`${match.url}`} render={this.onLists} />
      </Switch>
    );
  }
}

FPLRouter.propTypes = {
  match: PropTypes.object.isRequired,
  onDocuments: PropTypes.func.isRequired,
  viewParams,

  loadFocusedPlacementLists: PropTypes.func.isRequired,
  redirectPlan: PropTypes.func.isRequired,
  loadFocusedPlacementList: PropTypes.func.isRequired,

  selectedPlan: PropTypes.object,
  focusedPlacementLists: PropTypes.object,
  focusedPlacementList: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.ui.selectedPlan,
    focusedPlacementLists: state.resources.focused_placement_lists.data,
    focusedPlacementList: state.resources.focused_placement_list.data,
  };
};

export default connect(mapStateToProps, {
  redirectPlan,
  loadFocusedPlacementLists,
  loadFocusedPlacementList,
})(FPLRouter);
