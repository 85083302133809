import * as types from './types';

export const DEFAULT_STATE = {
  all: [],
  allLoaded: false,
  latest: [],
  seenMessages: [],
  showing: undefined,
  latestPopup: {
    visible: false,
    left: 0,
    top: 0,
  },
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    // Load Latest
    case types.MESSAGES_LOAD_LATEST:
    case types.MESSAGES_LOAD_LATEST_FAILED: {
      return { ...state, latest: [] };
    }
    case types.MESSAGES_LOAD_LATEST_SUCCESS: {
      return {
        ...state,
        latest: action.latest,
        seenMessages: action.seenMessages,
      };
    }

    case types.MESSAGES_MARK_SEEN: {
      return {
        ...state,
        seenMessages: [...state.seenMessages, ...action.messageIds],
      };
    }

    // Load All
    case types.MESSAGES_LOAD_ALL: {
      return { ...state, all: [], allLoaded: true };
    }
    case types.MESSAGES_LOAD_ALL_FAILED: {
      return { ...state, all: [] };
    }
    case types.MESSAGES_LOAD_ALL_SUCCESS: {
      return { ...state, all: action.all };
    }

    // Show
    case types.MESSAGES_SHOW: {
      return { ...state, showing: action.systemMessage };
    }

    // Edit/Save
    case types.MESSAGES_SAVE_SUCCESS: {
      return {
        ...state,
        all: action.all,
      };
    }
    case types.MESSAGES_SHOW_LATEST: {
      return {
        ...state,
        latestPopup: {
          visible: action.visible,
          left: action.left,
          top: action.top,
        },
      };
    }
    case types.UPDATE_SELECTED_MAINTENANCE_FIELD: {
      return {
        ...state,
        maintenanceSelectedField: action.maintenanceSelectedField,
      };
    }
    default: {
      return state;
    }
  }
};
