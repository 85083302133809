import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

import {
  saveAuthentication,
  logout,
  redirect,
  userHaveSkyanAccess,
} from '../actions';

export class Authenticated extends React.Component {
  componentDidMount() {
    this.scheduleLogin();
  }

  componentWillUnmount() {
    this.clearSchedule();
  }

  clearSchedule = () => {
    clearInterval(this.timerId);
  };

  scheduleLogin = () => {
    this.clearSchedule();
    this.timerId = setTimeout(this.login, 1000);
  };

  scheduleMonitor = () => {
    this.clearSchedule();
    this.timerId = setTimeout(this.monitor, 1000);
  };

  /*
   * When the application initializes to a SecureRoute
   * we wait for Okta to finish it's login.
   */
  login = async () => {
    const { auth } = this.props;
    try {
      const isAuthenticated = await auth.isAuthenticated();
      if (isAuthenticated) {
        this.scheduleMonitor();
      } else {
        this.scheduleLogin();
      }
    } catch (e) {
      this.scheduleLogin();
    }
  };

  /*
   * Monitor the Okta authentication in order to kick when the
   * token expires or on okta errors.
   */
  monitor = async () => {
    const { auth, maintenanceMode, logout } = this.props;
    try {
      const isAuthenticated = await auth.isAuthenticated();
      if (!isAuthenticated) {
        this.sendTologin();
        return false;
      }

      this.scheduleMonitor();

      if (maintenanceMode) {
        if (this.props.isRolesLoaded && !this.props.isEmployee) {
          logout();
          return false;
        }
      }

      const accessToken = await auth.getAccessToken();
      if (accessToken !== this.props.accessToken) {
        const user = await auth.getUser();
        this.props.saveAuthentication(user, accessToken);
        this.props.userHaveSkyanAccess();
      }

      return true;
    } catch (e) {
      logout();
      return false;
    }
  };

  sendTologin = () => {
    // By reloading the Okta's SecureRoutes leading to the current navigation address will automatically kick the user and store the previous address in the ?to= parameter.
    window.location.reload();
  };

  render() {
    if (this.props.loggingOut) {
      try {
        this.props.logout(false);
        this.props.redirect('/');
        this.props.auth.logout().catch(_e => {});
      } catch (e) {
        return null;
      }
    }
    return null;
  }
}

Authenticated.propTypes = {
  auth: PropTypes.object,
  saveAuthentication: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  accessToken: PropTypes.string,
  maintenanceMode: PropTypes.string,
  roles: PropTypes.array,
  isEmployee: PropTypes.bool,
  isRolesLoaded: PropTypes.bool,
  userHaveSkyanAccess: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    maintenanceMode: state.okta.settings.maintenanceMode,
    loggingOut: state.ui.logout,
    accessToken: state.okta.accessToken,
    roles: state.okta.roles,
    isEmployee: state.okta.isEmployee,
    isRolesLoaded: state.okta.isRolesLoaded,
  };
};

export default connect(mapStateToProps, {
  saveAuthentication,
  logout,
  redirect,
  userHaveSkyanAccess,
})(withAuth(Authenticated));
