import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import TilePlan from '../TilePlan';

export class MyPlans extends Component {
  filteredPlans = () => {
    if (this.props.hideUnpublished) {
      if (this.props.plans) {
        return _.map(this.props.plans, plan => {
          if (plan.planAdministratorEmail) {
            return plan;
          }
        });
      }
    }
    return this.props.plans;
  };

  renderPlans = plans => {
    return _.map(plans, plan => {
      return (
        <TilePlan
          key={plan.planId}
          plan={plan}
          isEmployee={true}
          isMyPlans={this.props.isMyPlans}
        />
      );
    });
  };

  renderPlansByClient = () => {
    const clients = _.groupBy(this.filteredPlans(), 'clientName');
    const clientNames = _.keys(clients);
    const ordered = clientNames.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    return _.map(ordered, clientName => {
      if (clientName && clientName !== 'undefined') {
        const plans = clients[clientName];
        return (
          <div key={clientName}>
            <h4>{clientName}</h4>
            <div className="tilesWrapper">{this.renderPlans(plans)}</div>
          </div>
        );
      }
      return null;
    });
  };

  render() {
    return <div>{this.renderPlansByClient()}</div>;
  }
}

MyPlans.propTypes = {
  plans: PropTypes.array,
  hideUnpublished: PropTypes.bool,
  isMyPlans: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    hideUnpublished: state.ui.hideUnpublished,
  };
};

export default connect(mapStateToProps)(MyPlans);
