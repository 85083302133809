import _ from 'lodash';

import EventService from '../services/EventService';

const buildEvent = (state, eventType, eventData = null) => {
  const {
    okta: {
      user: { email: userName },
    },
    ui: { selectedPlan },
  } = state;
  const timeStamp = new Date().toISOString();
  const planGuid = (selectedPlan && selectedPlan.planId) || null;
  return {
    userName,
    timeStamp,
    eventType,
    planGuid,
    eventData,
  };
};

export const addEvent = (eventType, eventData = null) => {
  return (dispatch, getState) => {
    const {
      okta: { accessToken },
    } = getState();
    const datas = _.isArray(eventData) ? eventData : [eventData];
    const requests = _.map(datas, d => buildEvent(getState(), eventType, d));
    return EventService().addEvent(accessToken, requests);
  };
};

export const lastEvent = (getState, eventType) => {
  const {
    okta: { accessToken },
  } = getState();
  const query = buildEvent(getState(), eventType);
  return EventService().lastEvent(accessToken, query);
};
