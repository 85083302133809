import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InputGroup, Input, Button, Col, Row } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

import renderTableGrid from '../plans/documents/TablePrintHtml';
import DocumentsGrid from '../plans/documents/DocumentsGrid';
import {
  loadEmployeeMeetingNotesAllManagers,
  loadEmployeeMeetingNotes,
  openMeetingNote,
  printPortfolio,
} from '../../actions';
import OpenMeetingNotesLink from '../plans/documents/OpenMeetingNotesLink';
import InvestmentFirmLink from '../plans/documents/InvestmentFirmLink';
import { dateColumn } from '../../services/DateService';
import { textColumn, equalsIgnoreCase } from '../../services/StringService';
import MeetingNotesModal from '../plans/documents/MeetingNotesModal';
import InvestmentFirmPanel from './InvestmentFirmPanel';
import { slug } from '../../services/SlugService';
import Constants, { Events } from '../../services/Constants';

export class EmployeeManagerMeetingNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investmentFirm: props.investmentFirm,
      hasFilter: false,
      filteredIDs: [],
      filter: { firm: '' },
      openModal: true,
      allManagerMeetingNotes: false,
      warned: false,
    };
  }

  static columnDefs = [
    {
      field: 'firm',
      headerName: 'Investment Firm',
      cellRenderer: InvestmentFirmLink,
      minWidth: 100,
      ...textColumn,
    },
    {
      field: 'subject',
      headerName: 'Meeting Subject',
      cellRenderer: OpenMeetingNotesLink,
      minWidth: 100,
      ...textColumn,
    },
    {
      field: 'meetingDate',
      headerName: 'Meeting Date',
      sort: 'desc',
      width: 100,
      ...dateColumn,
    },
  ];

  getMeetingNotes = () => {
    const {
      allManagerMeetingNotes,
      employeeMettingsNotesAllManager,
      employeeMettingsNotesMyManager,
    } = this.props;
    return allManagerMeetingNotes
      ? employeeMettingsNotesAllManager
      : employeeMettingsNotesMyManager;
  };

  print = () => {
    const { viewParams, user, printPortfolio } = this.props;
    const { filteredIDs } = this.state;
    const event = Events.PRINT_EMPLOYEE_MANAGER_MEETING_NOTES;
    const filteredManagerMeetingNotes =
      filteredIDs.length > 0
        ? this.getMeetingNotes().data.filter(
            item => filteredIDs.indexOf(item.writeUpId) >= 0
          )
        : this.getMeetingNotes().data;
    const grid = renderTableGrid(
      EmployeeManagerMeetingNotes.columnDefs,
      viewParams,
      filteredManagerMeetingNotes,
      Constants.ROWS_PER_PAGE_MEETING_NOTES,
      'Employee Manager Meeting Notes'
    );

    const planData = {
      planName: 'Employee Manager Meeting Notes',
      user: user.email,
    };

    printPortfolio(event, planData, viewParams.selectedTab, grid);
  };

  openMeetingNotesModal = () => {
    const { data } = this.getMeetingNotes();
    const hasData = data?.length > 0 ? true : false;
    let meetingNotesNotFound = true;
    if (hasData) {
      if (this.state.openModal && this.props.writeUpId) {
        data.forEach(meetingNote => {
          const slugedWriteUpId = slug(meetingNote.writeUpId);
          if (equalsIgnoreCase(slugedWriteUpId, this.props.writeUpId)) {
            this.setState({
              openModal: false,
            });
            meetingNotesNotFound = false;
            return this.props.openMeetingNote(meetingNote);
          }
        });
        if (meetingNotesNotFound && !this.state.warned) {
          this.setState({ warned: true });
          NotificationManager.warning(
            'Meeting notes not found for this user. Please switch to all managers to load it.',
            'Meeting notes'
          );
        }
      }
    }
    return null;
  };

  onCustomFilterRow = data => {
    let filteredIDs = [];
    data.api.forEachNodeAfterFilter((node, index) => {
      filteredIDs.push(node.data['writeUpId']);
    });
    if (this.getMeetingNotes().data) {
      const hasFilter = filteredIDs.length < this.getMeetingNotes().data.length;
      return this.setState({ filteredIDs: filteredIDs, hasFilter: hasFilter });
    }
    this.setState({ filteredIDs: filteredIDs, hasFilter: false });
  };

  meetingNotes = () => {
    const { hasFilter } = this.state;
    return (
      <div>
        <Row className="consultingResourcesInputSearch justify-content-between">
          <Col md={3} lg={3} xl={4}>
            <InputGroup>
              <Input
                type="search"
                className="consultingResourcesInputBar"
                id="inputBar-meetingNotes"
                placeholder="Firm search"
                value={this.state.filter.firm}
                onChange={event => {
                  this.setState({
                    filter: { firm: event.target.value.toLowerCase() },
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={2} lg={2} xl={2} className="text-right downloadCard">
            <Button
              id="downloadPdfButton"
              color="secondary"
              className="text-nowrap mx-auto downloadButtom"
              onClick={this.print}>
              Download PDF
            </Button>
            {Boolean(hasFilter) && (
              <span className="downloadText text-nowrap">
                Download will reflect any active filtering
              </span>
            )}
          </Col>
        </Row>
        <MeetingNotesModal showPlans={true} consultant={true} />
        <div className="messagesGrid mt-3 mb-3 ag-theme-balham">
          <DocumentsGrid
            context={{}}
            onCustomFilterRow={this.onCustomFilterRow}
            columnDefs={EmployeeManagerMeetingNotes.columnDefs}
            rowData={this.getMeetingNotes().data}
            viewParams={{}}
            noRowsMessage="No rows"
            hasLoaded={this.getMeetingNotes()?.isLoaded}
            filterValue={this.state.filter}
            filterProperty={['firm']}
          />
        </div>
        {this.openMeetingNotesModal()}
      </div>
    );
  };

  renderInvestmentFirm = () => {
    const {
      documentView,
      managerUrl,
      investmentFirm,
      strategyName,
    } = this.props;
    if (this.getMeetingNotes().data) {
      const meetingNotes = this.getMeetingNotes().data.find(
        e => slug(e.firm) === this.state.investmentFirm
      );
      if (
        meetingNotes &&
        this.props.activeTab === Constants.CR_MEETING_NOTES_VIEW
      ) {
        return (
          <InvestmentFirmPanel
            firmId={meetingNotes.firmId}
            documentView={documentView}
            managerUrl={managerUrl}
            investmentFirm={investmentFirm}
            strategyName={strategyName}
            baseUrl="/consultingResources/meetingnotes/"
          />
        );
      }
    }
    return null;
  };

  componentDidMount() {
    const { email, allManagerMeetingNotes } = this.props;
    if (allManagerMeetingNotes) {
      this.props.loadEmployeeMeetingNotesAllManagers(email);
    } else {
      this.props.loadEmployeeMeetingNotes(email);
    }
  }

  render() {
    const { investmentFirm, activeTab } = this.props;
    if (
      this.state.investmentFirm &&
      investmentFirm &&
      activeTab === Constants.CR_MEETING_NOTES_VIEW
    ) {
      return this.renderInvestmentFirm();
    }
    if (
      this.props.allManagerMeetingNotes !== this.state.allManagerMeetingNotes
    ) {
      this.componentDidMount();
      this.setState({
        allManagerMeetingNotes: this.props.allManagerMeetingNotes,
      });
    }
    return this.meetingNotes();
  }
}

EmployeeManagerMeetingNotes.propTypes = {
  loadEmployeeMeetingNotesAllManagers: PropTypes.func.isRequired,
  loadEmployeeMeetingNotes: PropTypes.func.isRequired,
  employeeMettingsNotesMyManager: PropTypes.object,
  employeeMettingsNotesAllManager: PropTypes.object,
  openMeetingNote: PropTypes.func,

  activeTab: PropTypes.string,

  investmentFirm: PropTypes.string,
  strategyName: PropTypes.string,
  documentView: PropTypes.string,
  managerUrl: PropTypes.string,
  writeUpId: PropTypes.string,
  allManagerMeetingNotes: PropTypes.bool,

  printPortfolio: PropTypes.func,

  viewParams: PropTypes.object,
  user: PropTypes.object,
  isEmployee: PropTypes.bool,
  email: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    user: state.okta.user,
    email: _.get(state, 'okta.user.email'),
    isEmployee: state.okta.isEmployee,
    employeeMettingsNotesMyManager:
      state.resources.employee_meeting_notes_list || {},
    employeeMettingsNotesAllManager:
      state.resources.employee_meeting_notes_all_managers_list || {},
    activeTab: state.ui.employeeActiveTab,
  };
};

export default connect(mapStateToProps, {
  loadEmployeeMeetingNotesAllManagers,
  loadEmployeeMeetingNotes,
  printPortfolio,
  openMeetingNote,
})(EmployeeManagerMeetingNotes);
