import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import { slug } from '../../../services/SlugService';
import { openMeetingNote, openDDEvent } from '../../../actions';

export const OpenMeetingNotesLink = props => {
  if (props.data.category === 'Meeting') {
    const onClick = () => props.openMeetingNote(props.data);
    return (
      <Button
        color="link"
        className="gridLink"
        onClick={onClick}
        id={`${slug(props.value)}-${props.rowIndex}`}>
        {props.value}
      </Button>
    );
  }
  const onClick = () => props.openDDEvent(props.data);
  return (
    <Button
      color="link"
      className="gridLink"
      onClick={onClick}
      id={`${slug(props.value)}-${props.rowIndex}`}>
      {props.value}
    </Button>
  );
};

OpenMeetingNotesLink.propTypes = {
  openMeetingNote: PropTypes.func.isRequired,
  openDDEvent: PropTypes.func.isRequired,
  value: PropTypes.string,
  data: PropTypes.object,
  rowIndex: PropTypes.number,
};

export default connect(null, {
  openMeetingNote,
  openDDEvent,
})(OpenMeetingNotesLink);
