import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card } from 'reactstrap';

import Constants from '../../../services/Constants';
import RecentDocument from './RecentDocument';
import { refreshDocuments } from '../../../actions';

const SHOW_LAST_X_DOCUMENTS = 5;

export class PlanRecent extends Component {
  renderPlanRecent = () => {
    return _.map(this.props.documents, (doc, index) => (
      <RecentDocument key={index} doc={doc} />
    ));
  };

  render() {
    const refreshClass = this.props.isLoading ? 'fa-spin fa-spinner' : '';
    return (
      <Card className="planRecent">
        <h4 className="recentDocumentsTitle">
          Recent Documents
          <div className="refreshDocumentsContainer">
            <i
              className={`fa fa-sync refreshDocuments ${refreshClass}`}
              title="Refresh documents"
              onClick={() => this.props.refreshDocuments(true)}
            />
          </div>
        </h4>
        {this.renderPlanRecent()}
      </Card>
    );
  }
}

PlanRecent.propTypes = {
  documents: PropTypes.array.isRequired,
  refreshDocuments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const calculateLatest = _.memoize((researchDocuments, planDocuments) => {
  const allDocs = [...researchDocuments, ...planDocuments];
  return _.chain(allDocs)
    .sortBy(doc => moment.utc(doc.publishedDate).valueOf())
    .reverse()
    .slice(0, SHOW_LAST_X_DOCUMENTS)
    .value();
});

const retrieveRecentDocuments = state => {
  const researchDocuments = state.resources.nepc_published.data || [];
  const filteredResearcDocuments = researchDocuments.filter(
    doc => !(doc.publishingLocation === Constants.DOCUMENT_MARKET_UPDATES)
  );

  const planDocuments = state.resources.client_deliverables.data || [];
  return calculateLatest(filteredResearcDocuments, planDocuments);
};

const mapStateToProps = state => {
  return {
    documents: retrieveRecentDocuments(state),
    isLoading:
      state.resources.nepc_published.loading ||
      state.resources.client_deliverables.loading,
  };
};

export default connect(mapStateToProps, { refreshDocuments })(PlanRecent);
