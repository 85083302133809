import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Container, Row, Col, Form } from 'reactstrap';

import OktaConfig from './oktaSignInWidget/OktaConfig';
import LoadingAnimation from '../LoadingAnimation';

export class AuthContainer extends Component {
  renderContent() {
    const { logo } = OktaConfig;
    return (
      <div>
        <div
          id="okta-sign-in"
          className="auth-container main-container no-beacon">
          <div className="okta-sign-in-header auth-header">
            <img src={logo} className="auth-org-logo" alt="NEPC" />
          </div>
          <div className="auth-content">
            <div className="auth-content-inner">
              <Form id="okta-nepc-authContainer-form">
                <h2 className="okta-form-title o-form-head">
                  Please enter your information
                </h2>
                <Container>{this.props.children}</Container>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="authContainer">
        <div className="imgContainer">
          <img className="background" src={'/background.jpg'} alt="building" />
        </div>

        <Container className="widgetContainer" fluid>
          <Row>
            <Col>
              <div className="clientHub">
                <div className="logo" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>{this.renderContent()}</Col>
          </Row>

          <div className="terms-link">
            <NavLink href="/terms">Terms and Conditions</NavLink>
          </div>
        </Container>

        <LoadingAnimation />
      </div>
    );
  }
}

AuthContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.ui.isLoading,
  };
};

export default connect(mapStateToProps, null)(AuthContainer);
